import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';

export const SortByDefault = {
    name: undefined,
    clickStatus: undefined,
    value: undefined
}

export const SortByIcon = (activeSortBy, sortType) => {

    if (!sortType) return null;
    if (activeSortBy && activeSortBy.name === sortType) {
        return activeSortBy.value === 'Asc'
            ? <FontAwesomeIcon icon={faSortUp} style={{float: 'right'}}/>
            : <FontAwesomeIcon icon={faSortDown} style={{float: 'right'}}/>;
    } else {
        return <FontAwesomeIcon style={{ color: '#BBBBBB',float: 'right' }} icon={faSort}/>;
    }

};

export const SortByLogic = (sortType, currentSortBy) => {

    switch (currentSortBy.clickStatus) {
        case 1: return { name: sortType, clickStatus: 2, value: 'Asc' };
        case 2: return { ...SortByDefault };
        default: return { name: sortType, clickStatus: 1, value: 'Desc' };
    }

}

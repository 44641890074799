import React, { Component, Fragment } from 'react';
import { SaveButton, CloseButton, IconIndecatorWithHoverOver, InfoHoverOver, ReturnDollarValueWithCommon } from './../Common/index';
import { faCalendarTimes, faShippingFast, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, Table, Spinner } from 'react-bootstrap';
import { UserDatePicker } from './../Common/UserDatePicker';
import { DisplayUserDateTime, ConvertToApiFriendlyDateTime } from './../../Globals/UserDateTime';

import { GetInvoicesByPurchaseOrderNumber, InsertOrderInvoice, GetPromiseShipDate } from './../../FetchApi/Order';
import { OptionsIcons } from './../DisplayBlocks';

export class AddInvoiceToAcceptedOrder extends Component {
    constructor(props) {
        super(props);

        this.ChangeModelState = this.ChangeModelState.bind(this);
        this.UpdateSelectedInvoices = this.UpdateSelectedInvoices.bind(this);
        this.UpdatePromisedshipDate = this.UpdatePromisedshipDate.bind(this);
        this.onAcceptOrder = this.onAcceptOrder.bind(this);
        this.ManualSetPromisedShipDate = this.ManualSetPromisedShipDate.bind(this);
        this.isSaveDisabled = this.isSaveDisabled.bind(this);

        this.state = {
            isOpen: false,
            isLoading: true,
            promisedShipDate: undefined,
            invoices: [],
            selectedInvoices: [],
            forceDisable: false,
        }
    }

    isSaveDisabled() {
        if(this.state.forceDisable) return true;
        
        return this.state.selectedInvoices.length !== 0 && this.state.promisedShipDate && this.state.promisedShipDate.promisedDate;
    }

    ChangeModelState() {
        if (!this.state.isOpen) this.GetInvoices();

        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    GetInvoices() {
        GetInvoicesByPurchaseOrderNumber(this.props.purchaseOrderNumber).then((result) => this.setState(() => ({ invoices: result, isLoading: false })));
    }

    UpdateSelectedInvoices(inv) {

        let selectedInvoiceList = this.state.selectedInvoices;

        if (this.state.selectedInvoices.length !== 0) {
            const alreadySelected = this.state.selectedInvoices.some(selinv => selinv.samInvoiceId === inv.samInvoiceId);

            if (alreadySelected) {
                selectedInvoiceList = selectedInvoiceList.filter((selectedInvoice) => {
                    if (selectedInvoice.invoiceNumber !== inv.invoiceNumber) return selectedInvoice;
                    return null;
                });
            } else {
                selectedInvoiceList = selectedInvoiceList.concat([{ ...inv }]);
            };

            this.setState({ selectedInvoices: selectedInvoiceList });
        } else {
            this.UpdatePromisedshipDate(inv);
        };

    };

    UpdatePromisedshipDate(inv) {

        GetPromiseShipDate(this.props.tradingPartnerId, this.props.isUrgent, inv.samInvoiceId).then((result) => {
            this.setState({ selectedInvoices: [{ ...inv }], promisedShipDate: result })
        });
    };

    onAcceptOrder() {
        this.setState(() => ({forceDisable: true}));
        let orderInvoice = [];

        for (let i = 0; i < this.state.selectedInvoices.length; i++) {
            const element = {
                OrderId: this.props.orderId,
                SAMInvcKey: this.state.selectedInvoices[i].samInvoiceId,
                InvoiceNumber: this.state.selectedInvoices[i].invoiceNumber
            }
            orderInvoice.push(element);
        }

        InsertOrderInvoice(
            this.props.orderId,
            orderInvoice,
            ConvertToApiFriendlyDateTime(this.state.promisedShipDate.promisedDate))
            .then((result) => {
                this.setState((prevState) => ({
                    isOpen: !prevState.isOpen,
                    forceDisable: false
                }));
                if(result > 0 ) {
                    window.location.reload();
                };
            });
    };

    ManualSetPromisedShipDate(changeDate) {

        this.setState({
            promisedShipDate: {
                isIncludesHoliday: false,
                isUrgent: false,
                promisedDate: changeDate
            },
        });
    }

    render() {
        return (
            <div>
                {OptionsIcons(faPlus,'Add invoice to purchase order', this.ChangeModelState)}
                <Modal
                    show={this.state.isOpen}
                    onHide={this.ChangeModelState}
                    size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>ACCEPT ORDER</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.isLoading ? <div style={{ padding: 30 }}><p style={{ textAlign: 'center' }}>Loading invoices from SAM...</p><Spinner animation="border" /></div> :
                            <AcceptOrderModalBody
                                purchaseOrderNumber={this.props.purchaseOrderNumber}
                                onSelectFuntion={this.UpdateSelectedInvoices}
                                invoices={this.state.invoices}
                                selectedinvoices={this.state.selectedInvoices}
                                purchaseOrderTotalAmount={this.props.totalAmount} 
                                alreadyInvoiced={this.props.invoices}/>
                        }
                    </Modal.Body>
                    <PromisedShipDate hasSelectedInvoice={this.state.selectedInvoices.length > 0} UpdatePromisedshipDate={this.ManualSetPromisedShipDate} promisedShipDate={this.state.promisedShipDate} />
                    <Modal.Footer>
                        <AcceptOrderModalFooter changeModelState={this.ChangeModelState} onAcceptFunction={this.onAcceptOrder} isDisabled={this.isSaveDisabled()} />
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const AcceptOrderModalFooter = ({ onAcceptFunction, changeModelState, isDisabled }) => {
    return (
        <Fragment>
            <SaveButton contents='Accept Order' onClickFunction={onAcceptFunction} isDisabled={!isDisabled} />
            <CloseButton contents='Close' onClickFunction={changeModelState} />
        </Fragment>
    )
}

const PromisedShipDateInfo = () => {
    return (<p>Promised Ship Date will be calulated based on lab location. <br />
        This calulation will also check weather it falls on a public holiday or is an urgent order.</p>)
}

const PromisedShipDate = ({ promisedShipDate, UpdatePromisedshipDate, hasSelectedInvoice }) => {
    const dateTime = promisedShipDate ? promisedShipDate.promisedDate : null;

    return (
        <Fragment>
            <div style={{ padding: 10 }}>

                <UserDatePicker disabled={promisedShipDate && hasSelectedInvoice ? false : true} dateTime={dateTime} onChangeFunction={UpdatePromisedshipDate} minDate={new Date(Date.now())} />
                <label style={{ paddingLeft: 5 }}>Promised Ship Date <InfoHoverOver information={PromisedShipDateInfo()} /></label>
            </div>
            {promisedShipDate ?
                <div style={{ paddingLeft: 10 }}>
                    <IconIndecatorWithHoverOver displayIfTrue={promisedShipDate.isIncludesHoliday} color='#ff9933' icon={faCalendarTimes} size='3x' hoverOverMessage='Date has been adjusted to allow for a public holiday' />
                    <IconIndecatorWithHoverOver displayIfTrue={promisedShipDate.isUrgent} icon={faShippingFast} size='3x' hoverOverMessage='Date has been adjusted to suit an urgent order' />
                </div> : <Fragment />
            }

        </Fragment>
    )
}

const TotalInvoice = (selectedInvoices, alreadyInvoiced, invoices) => {
    var total = 0;

    if(invoices && invoices.length > 0){
        invoices.forEach(inv => {
            if(alreadyInvoiced && alreadyInvoiced.length > 0 && alreadyInvoiced.some(ii => ii.samInvcKey=== inv.samInvoiceId )) total += inv.invoiceAmount;
        })
    }

    if(!selectedInvoices || selectedInvoices.length === 0) return ReturnDollarValueWithCommon(total.toFixed(2));
    selectedInvoices.forEach(inv => {
        total += inv.invoiceAmount;
    });

    return ReturnDollarValueWithCommon(total.toFixed(2));
}


const AcceptOrderModalBody = ({ onSelectFuntion, invoices, selectedinvoices, purchaseOrderNumber, purchaseOrderTotalAmount, alreadyInvoiced }) => {

    const totalSelected = TotalInvoice(selectedinvoices, alreadyInvoiced, invoices);
    const poTotalAmount = ReturnDollarValueWithCommon(purchaseOrderTotalAmount.toFixed(2));

    const ifAlreadyInvoicedDoNothing = () => {

    }

    return (<Fragment>
        <Table responsive>
            <thead>
                <tr>
                    <th>Invoice Number</th>
                    <th>Issued Date</th>
                    <th>Company Name</th>
                    <th>Company Short Code</th>
                    <th>Total</th>
                    <th>#</th>
                </tr>

            </thead>

            <tbody>
                {invoices && invoices.length > 0 ? invoices.map((inv, key) => (
                    alreadyInvoiced.some(ainv => ainv.invoiceNumber === inv.invoiceNumber) ?
                    <tr key={key} style={{backgroundColor: '#bababa', color: '#000'}}>
                        <td>{inv.invoiceNumber}</td>
                        <td>{DisplayUserDateTime(inv.issuedDateUTC)}</td>
                        <td>{inv.companyName}</td>
                        <td>{inv.companyShortCode}</td>
                        <td>{inv.invoiceAmount ? ReturnDollarValueWithCommon(inv.invoiceAmount.toFixed(2)) : '$0.00'}</td>
                        <td>
                            <input
                                name="invoiceIsSelected"
                                type="checkbox"
                                checked={true} 
                                onChange={() => ifAlreadyInvoicedDoNothing()}
                                />
                        </td>
                    </tr> 
                    : 
                    <tr key={key}>
                        <td>{inv.invoiceNumber}</td>
                        <td>{DisplayUserDateTime(inv.issuedDateUTC)}</td>
                        <td>{inv.companyName}</td>
                        <td>{inv.companyShortCode}</td>
                        <td>{inv.invoiceAmount ? ReturnDollarValueWithCommon(inv.invoiceAmount.toFixed(2)) : '$0.00'}</td>
                        <td>
                            <input
                                name="invoiceIsSelected"
                                type="checkbox"
                                checked={selectedinvoices.some(selinv => selinv.samInvoiceId === inv.samInvoiceId)}
                                onChange={() => onSelectFuntion(inv)} />
                        </td>
                    </tr>
                )) :
                    <tr>
                        <td colSpan="6" style={{ fontWeight: 500 }}>No invoice with purchase order number: <span className='purchaseOrderNumberhighlight'>{purchaseOrderNumber}</span> can be found in SAM</td>
                    </tr>
                }
            </tbody>
        </Table>
        <div style={{ paddingTop: 10 }}>

            <div style={{ float: 'left' }} className={totalSelected === poTotalAmount ? 'approvedBox' : ''}>
                <div>Invoice Total: {totalSelected}</div>
                <div>Purchase Order Total: {poTotalAmount}</div>
            </div>
            <div style={{ float: 'left', padding: 10 }}>
              {totalSelected === poTotalAmount && <IconIndecatorWithHoverOver size={'2x'} icon={faCheck} color={'#389466'} hoverOverMessage='Invoice total is equal to the Purchase Order total'/>}
            </div>
        </div>
    </Fragment>);
}

import React from 'react';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import ReportOptionTemplate from '../ReportOptionTemplate';
import { BarChart } from '../../Reports/BarChartReport';

export const reportName = 'TradingPartnerInvoiceSentBar';

export const TradingPartnerInvoiceSentBarOption = (props) => {
    // all values will create boxes based on their type
    const queryOptions = [];

    // ReportOptionTemplate - reportName, title, summary, fetchAPI (Report/{fetchAPI name}),queryOptions (fetchAPI params), icon (FontAwsomeIcon), props (populate new queryOption values from database if any)
    return ReportOptionTemplate(reportName,
        'Invoices Sent Today',
        'The amount of invoices sent today group by Trading Partners',
        'ReportTradingPartnerInvoiceSentBar',
        queryOptions,
        faChartBar,
        props);
};

export const TradingPartnerInvoiceSentBar = (props) => {
    const barColors = ['#68C6E2', '#68E28A', '#E2C468', '#E26E68'];
    return (
        <BarChart {...props}
        title='Invoices Sent Today' subtext='The amount of invoices sent today group by Trading Partners'
            color={barColors}
            nodata="No invoices have been sent today."/>
    );
};
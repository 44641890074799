import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { LogoutAdalUser } from './../UserAuthentication/AdalAuthentication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AdmininstrationImportInvoices, InvoicesPath, AcceptedOrdersPath, RejectedOrdersPath, FailedInvoicesPath, FailedOrdersPath, AuditPath, SettingsUserPath, UnprocessedOrdersPath, DashboardPath, AdministrationTradingPartnersPath, OutstandingOrdersPath } from './../Globals/RoutePaths';

export function NavMenu(props) {
    return (
        <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand>
                <Link style={{ margin: '0 30' }} to={DashboardPath}>EDI</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <LinkContainer to={UnprocessedOrdersPath} className="section-start">
                        <Nav.Link >
                            Unprocessed Orders
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={AcceptedOrdersPath}>
                        <Nav.Link >
                            Accepted Orders
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={RejectedOrdersPath}>
                        <Nav.Link >
                            Rejected Orders
                        </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={OutstandingOrdersPath} >
                        <Nav.Link >
                            Outstanding Orders
                    </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={InvoicesPath} className="section-start">
                        <Nav.Link >
                            Invoices
                        </Nav.Link>
                    </LinkContainer>
                    {props.EDIProfile.roleId >= 10 && <NavDropdown title="Errors">
                        <LinkContainer to={FailedOrdersPath}><NavDropdown.Item href="#" className='SettingsDropDown'>Order Errors</NavDropdown.Item></LinkContainer>
                        <LinkContainer to={FailedInvoicesPath}><NavDropdown.Item href="#" className='SettingsDropDown'>Invoice Errors</NavDropdown.Item></LinkContainer>
                        <LinkContainer to={AdmininstrationImportInvoices}><NavDropdown.Item href="#" className='SettingsDropDown'>Manual Invoice</NavDropdown.Item></LinkContainer>
                    </NavDropdown>}
                </Nav>
            </Navbar.Collapse>
            
   
                <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="ml-auto">
                        <Nav.Link href="https://alsglobal.sharepoint.com/:w:/r/sites/tribologyhome/_layouts/15/doc2.aspx?sourcedoc=%7BD93824A3-60EF-4FD7-A096-6338B73ECB9B%7D&file=Tribol%20EDI%20-%20Basic%20Functions.docx&action=default&mobileredirect=true"><FontAwesomeIcon icon={faQuestionCircle} /></Nav.Link>
                        
                        
                        {
                            props.EDIProfile &&
                        (
                            <NavDropdown className="navdropdown-pushleft" title={`${props.EDIProfile.firstName} ${props.EDIProfile.lastName}`} id="collasible-nav-dropdown">
                                <LinkContainer to={AuditPath}>
                                    <Nav.Link className="SettingsDropDown">Audit</Nav.Link>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                {props.EDIProfile.roleId >= 10 &&
                                    (<Fragment>
                                        <LinkContainer to={AdministrationTradingPartnersPath}>
                                            <Nav.Link className="SettingsDropDown">Administration</Nav.Link>
                                        </LinkContainer>
                                        <NavDropdown.Divider />
                                    </Fragment>)
                                }
                                <LinkContainer to={SettingsUserPath}>
                                    <Nav.Link className="SettingsDropDown">User Preferences</Nav.Link>
                                </LinkContainer>
                                <NavDropdown.Divider />
                                <span href="#" className="SettingsDropDown nav-link menuLogOut" onClick={LogoutAdalUser}>Log Out</span>
                            </NavDropdown>
                        )}
                    </Nav>
                    
                    </Navbar.Collapse>
                
            
        </Navbar>
    );
}

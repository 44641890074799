import { GetInitialUserState } from './../UserAuthentication/ReduxAuthorization';
//import { GetAdalCachedUser } from './../UserAuthentication/AdalAuthentication';
import { CreateNewUser, SetPhoneNumber, SetUserTheme, SetReceiveErrorNotification, SetReceiveUserReport, SetTimeZone } from './../FetchApi/Settings';
import { CreateEDIUserLocal } from './../Globals/LocalStorage';
import { isNotEmpty, isEmpty } from './../Globals/FunctionsTools';
import { SetDateHoverOver } from './../FetchApi/User';

import * as momentTimeZone from 'moment-timezone';
import { SetLogginTheme } from './../Style/Theme'; // setting the theme

const ReceiveUserProfile = 'RECEIVE_USERPROFILES';
const SetPhoneNumberType = 'SetPhoneNumber';
const SetUserThemeType = 'SetUserTheme';
const SetReceiveErrorNotificationType = 'SetReceiveErrorNotification';
const SetReceiveUserReportType = 'SetReceiveUserReport';
const SetTimeZoneType = 'SetTimeZone';
const SetDateHoverOverType = 'SetDateHoverOver';


export const ProfileRequestAction = {
    requestUserProfile: param => async (dispatch, getState) => {

        const initialState = GetInitialUserState();
 
        if (initialState.IsAuthenicated || isEmpty(initialState.ADALProfile)) dispatch({ type: ReceiveUserProfile, ...initialState });

        //ToDo rework this with a new controller 
        if (isNotEmpty(initialState.ADALProfile)) {
            const timeZone = momentTimeZone.tz.guess();
            const offset = momentTimeZone.tz(timeZone).format('Z');
            CreateNewUser(initialState.ADALProfile, timeZone, offset).then(
                (result) => {

                    if (result) {
                        CreateEDIUserLocal(result);
                        dispatch({
                            type: ReceiveUserProfile,
                            ...initialState,
                            EDIProfile: { ...result },
                            IsAuthenicated: true,
                            IsLoading: false

                        });
                        SetLogginTheme(result.isDarkTheme);
                    } else {
                        dispatch({
                            type: ReceiveUserProfile,
                            ...initialState,
                            EDIProfile: {},
                            IsAuthenicated: false,
                            IsLoading: true
                        });
                        
                    }
                })
        }
    },
};



// import { CreateEDIProfile, GetEDIProfile } from '../User/Profile';
// import {  getAdalUser,  GetAdalUser } from './../Security';
// import { GetUser, SetPhoneNumber, SetUserTheme, SetReceiveErrorNotification, SetReceiveUserReport, SetTimeZone } from
//     './../FetchApi/Settings';
// import { SetDateHoverOver } from './../FetchApi/User';
// import * as momenttz from 'moment-timezone';
// import { SetLogginTheme } from '../styles/Theme';
// //import { GetEDIProfile } from './../User/Profile';


// const initialState = {
//     UserProfile: GetEDIProfile() ? GetEDIProfile() : {} ,
//     IsAuthenicated: false
// };

// export const profileRequestAction = {
//     requestProfile: param => async (dispatch, getState) => {

//         dispatch({
//             type: requestUserProfile
//         });
//     },

//     requestUserProfile: param => async (dispatch, getState) => {
//         const CheckAdalUser = GetAdalUser();
//         const CheckEDIUser = GetEDIProfile();

//         if(CheckAdalUser && CheckEDIUser) {
//             dispatch({
//                 type: receiveUserProfile,
//                 UserProfile: { ...CheckEDIUser },
//                 IsAuthenicated: true
//             });
//         } else if (CheckAdalUser && !CheckEDIUser) {
//             const adalProfile = getAdalUser().profile;
//             const timeZone = momenttz.tz.guess();
//             const offset = momenttz.tz(timeZone).format('Z');
//             GetUser(adalProfile, timeZone, offset).then(
//                 (result) => {
//                     CreateEDIProfile(result);
//                     dispatch({
//                         type: receiveUserProfile,
//                         UserProfile: { ...result },
//                         IsAuthenicated: true
//                     });
//                     return result.isDarkTheme;
//                 }).then((darkTheme) => (SetLogginTheme(darkTheme)));
//         } else {
//             dispatch({ 
//                 type: receiveUserProfile,
//                 ...initialState });
//         }
//     }
// };

export const reducer = (state, action) => {
    state = state || GetInitialUserState();

    const userProfile = state.EDIProfile ? state.EDIProfile : null;

    switch (action.type) {
        case ReceiveUserProfile:
            return {
                ...state,
                EDIProfile: action.EDIProfile,
                ADALProfile: action.ADALProfile,
                IsAuthenicated: action.IsAuthenicated,
                IsLoading: action.IsLoading
            };

        case SetPhoneNumberType:

            SetPhoneNumber(action.newValue);
            userProfile.phoneNumber = action.newValue;
            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };

        case SetUserThemeType:
            SetUserTheme(!userProfile.isDarkTheme);
            userProfile.isDarkTheme = !userProfile.isDarkTheme;
            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };

        case SetReceiveErrorNotificationType:
            SetReceiveErrorNotification(!userProfile.isReceiveErrorNotification);
            userProfile.SetReceiveErrorNotification = !userProfile.isReceiveErrorNotification;
            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };

        case SetReceiveUserReportType:
            SetReceiveUserReport(!userProfile.isReceiveUserReport);
            userProfile.isReceiveUserReport = !userProfile.isReceiveUserReport;

            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };

        case SetDateHoverOverType:
            SetDateHoverOver(!userProfile.isDateHoverOver);
            userProfile.isDateHoverOver = !userProfile.isDateHoverOver;

            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };

        case SetTimeZoneType:

            SetTimeZone(action.newValue);
            userProfile.timeZone = action.newValue.TimeZone;

            CreateEDIUserLocal(userProfile);

            return {
                ...state,
                EDIProfile: userProfile
            };
        default:
            break;
    }

    return state;
};

export const profileChangeAction = {
    SetPhoneNumber: param => async (dispatch, getState) => {
        dispatch({
            type: SetPhoneNumberType,
            newValue: param
        });
    },
    SetUserTheme: param => async (dispatch, getState) => {
        dispatch({
            type: SetUserThemeType,
            newValue: param
        });
    },
    SetReceiveErrorNotification: param => async (dispatch, getState) => {
        dispatch({
            type: SetReceiveErrorNotificationType,
            newValue: param
        });
    },
    SetReceiveUserReport: param => async (dispatch, getState) => {
        dispatch({
            type: SetReceiveUserReportType,
            newValue: param
        });
    },
    SetDateHoverOver: param => async (dispatch, getState) => {
        dispatch({
            type: SetDateHoverOverType,
            newValue: param
        });
    },
    SetTimeZone: param => async (dispatch, getState) => {
        dispatch({
            type: SetTimeZoneType,
            newValue: param
        });
    },
};
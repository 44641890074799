import React, { Component } from 'react';
import { Container, Col, Row, Spinner, Form, Button } from 'react-bootstrap';
import { UserDatePicker } from './../components/Common/UserDatePicker'; // use as dateTimeNow
import { GetTradingPartners } from './../FetchApi/Settings';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ManuallyImportInvoice, GetInvoicesToImport } from './../FetchApi/Administration';
import { ErrorToastR, SuccessToastR } from './../Globals/ToastrReponses';
import { BasicTable, renderPagination } from './../components/Table/index';
import { ConvertToApiFriendlyDateTime } from './../Globals/UserDateTime';
import * as moment from 'moment';
import './../Style/manualInvoices.scss';


const defaultSelected = {
    tradingPartnerId: undefined,
    selectedIds: []
}

const defaultSearchRange = () => {
    var myCurrentDate = new Date();
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 14);

    return { start: myPastDate, end: myCurrentDate }
}

export default class ImportInvoices extends Component {
    constructor(props) {
        super(props);
        this.SelectTradingParter = this.SelectTradingParter.bind(this);
        this.ChangeReleaseDate = this.ChangeReleaseDate.bind(this);
        this.SearchRange = this.SearchRange.bind(this);
        this.SearchForInvoice = this.SearchForInvoice.bind(this);
        this.ImportInv = this.ImportInv.bind(this);
        this.ClearSearch = this.ClearSearch.bind(this);
        this.ClearSelected = this.ClearSelected.bind(this);
        this.SelectInvoice = this.SelectInvoice.bind(this);
        this.UpdateInvoicesPageNumber = this.UpdateInvoicesPageNumber.bind(this);
        this.GetInvoicesToImportFunction = this.GetInvoicesToImportFunction.bind(this);


        this.state = {
            isLoading: true,
            modalIsOpen: false,
            tradingPartners: [],
            selected: defaultSelected, //selectedIds
            isSignedOff: true,
            releaseDate: new Date,
            searchRange: defaultSearchRange(),
            invoiceList: [],
            invoicesPageNumber: 1
        }

    }

    componentDidMount() {
        GetTradingPartners().then(res => this.setState({ tradingPartners: res, isLoading: false }))
    }

    SelectTradingParter(tradingPartnerId) {

        if (tradingPartnerId != this.state.selected.tradingPartnerId) {
            this.setState({
                selected: {
                    tradingPartnerId: tradingPartnerId.trim(),
                    selectedIds: []
                },
                invoiceList: []
            })
        }
    }

    ChangeReleaseDate(newDate) {
        this.setState({ releaseDate: newDate })
    }

    SearchRange(dateRange) {

        if(dateRange  === null){
            this.setState({ searchRange: defaultSearchRange() })
        } else {
            this.setState({ searchRange: { start: dateRange[0], end: dateRange[1] } })
        }
        
    }

    SearchForInvoice(e) {
        e.preventDefault();

        if (this.state.selected.tradingPartnerId) {

            this.setState({ isLoading: true });
            const searchValue = e.target.InvoiceSearchId.value.trim();

            GetInvoicesToImport(
                this.state.invoicesPageNumber,
                20,
                this.state.selected.tradingPartnerId.trim(),
                ConvertToApiFriendlyDateTime(this.state.searchRange.start),
                ConvertToApiFriendlyDateTime(this.state.searchRange.end),
                searchValue
            ).then(res => this.setState({ invoiceList: res, isLoading: false }));
        } else{
            ErrorToastR("Please select a Trading Partner")
        }
    }

    GetInvoicesToImportFunction(){

        const searchValue = document.getElementById('InvoiceSearchId').value;
        GetInvoicesToImport(
            this.state.invoicesPageNumber,
            20,
            this.state.selected.tradingPartnerId.trim(),
            ConvertToApiFriendlyDateTime(this.state.searchRange.start),
            ConvertToApiFriendlyDateTime(this.state.searchRange.end),
            searchValue
        ).then(res => this.setState({ invoiceList: res, isLoading: false }));
    }

    ClearSearch() {
        this.setState({ searchRange: defaultSearchRange(), isSignedOff: true });
        document.getElementById('InvoiceSearchId').value = '';
    }



    ImportInv() {

        if (this.state.selected.selectedIds.length > 0) {

            const ids = [];
            this.state.selected.selectedIds.forEach(sids => {
                ids.push(sids.invcKey);
            });
   
            const params = {
                TradingPartnerId: this.state.selected.tradingPartnerId,
                InvoiceIds: ids.join(),
                ReleaseDate: ConvertToApiFriendlyDateTime(this.state.releaseDate)
            }

            this.setState({ isLoading: true });
            ManuallyImportInvoice(params).then(result => {
                if(result.error == false) {
     
                    SuccessToastR("Invoices manual uploaded.");
                    this.setState({isLoading: false})
                } else {
                    ErrorToastR(result.message);
                }
                
            });

        } else {
            ErrorToastR('No invoices selected.');
        }
        // post to server
        // set as loading
        //server to server
        // result 
    }

    ClearSelected() {
        this.setState({ selected: defaultSelected })
    }

    UpdateInvoicesPageNumber(filter, number, pageSize) {

        this.setState({ invoicesPageNumber: number, isLoading: true });
        this.GetInvoicesToImportFunction();
    }

    SelectInvoice(invoiceId) {
        if (this.state.selected.selectedIds.some(sid => sid.invcKey === invoiceId.invcKey)) {
            let selected = { ...this.state.selected };
            const result = selected.selectedIds.filter(s => s.invcKey !== invoiceId.invcKey);
            selected.selectedIds = result;
            this.setState({ selected: selected });

        } else {
            const selected = { ...this.state.selected };
            selected.selectedIds.push(invoiceId);
            this.setState({ selected: selected });
        }
    }

    render() {
        return (
            <Container>
                {
                    this.state.isLoading &&
                    (<Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>)
                }
                <Row>
                    <Col sm={3}>

                        {
                            this.state.tradingPartners.map((tp, key) => {
                                return (<div key={key} onClick={() => this.SelectTradingParter(tp.tpId)}
                                    style={{ textAlign: 'center' }}
                                    className={this.state.selected.tradingPartnerId === tp.tpId ? 'selected filterBarBlock' : 'filterBarBlock'}>
                                    {tp.name}
                                </div>)
                            })
                        }
                    </Col>
                    <Col>

                        <div style={{ border: '1px solid', padding: 5, borderRadius: 10 }} >
                            <h3>Search for Invoices</h3>
                            <Form onSubmit={this.SearchForInvoice}>
                                <Form.Row className="align-items-center">

                                    <Col xs="auto">
                                        <div>Search Date Range: </div>
                                        <div><DateRangePicker value={[this.state.searchRange.start, this.state.searchRange.end]} id="sRange" onChange={this.SearchRange} /></div>

                                    </Col>
                                    <Col xs={6}>
                                        <div>
                                            Invoice Number:
                                        </div>
                                        <div>
                                            <Form.Control
                                                name='InvoiceSearchId'
                                                className="mb-6"
                                                id="InvoiceSearchId"
                                                placeholder="Invoice Id"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <Button type="submit">Search</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                        <hr />
                        <div  style={{margin: '0px 5px'}}>
                            {
                                <BasicTable
                                    headers={[
                                        { headerName: 'Invoice Type' },
                                        { headerName: 'Invoice Number' },
                                        { headerName: 'SAM Trading Name' },
                                        { headerName: 'Sign off date' },
                                        { headerName: 'Total Amount' },
                                        { headerName: 'Action' }]}
                                    data={invoiceListData(this.state.invoiceList, this.SelectInvoice, this.state.selected.selectedIds)} emptyDataMessage={'No invoices are found.'} />
                            }
                            {
                                this.state.invoiceList.length > 0 && this.state.invoiceList[0].totalCount > 20 &&
                                renderPagination(
                                    this.state.invoiceList[0].totalCount, 
                                    20, 
                                    this.state.invoicesPageNumber,
                                    null,
                                    this.UpdateInvoicesPageNumber)
                            }
                        </div>
                        <hr />
                        <div style={{   content: "", clear: 'both', display: 'table'}}></div>
                        <div  style={{margin: '0px 5px'}}>
                            <h3>Selected Invoices</h3>
                            {
                                <BasicTable
                                    headers={[
                                        { headerName: 'Invoice Type' },
                                        { headerName: 'Invoice Number' },
                                        { headerName: 'SAM Trading Name' },
                                        { headerName: 'Sign off date' },
                                        { headerName: 'Total Amount' },
                                        { headerName: 'Action' }]}
                                    data={invoiceListData(this.state.selected.selectedIds, this.SelectInvoice, this.state.selected.selectedIds)} emptyDataMessage={'No invoices are selected.'} />
                                //map selected
                            }

                        </div>
                        {
                            this.state.selected.selectedIds.length > 0 &&
                            (<div style={{margin: '0px 5px'}}>
                                ReleaseDate: <UserDatePicker onChangeFunction={this.ChangeReleaseDate} dateTime={this.state.releaseDate} minDate={minDate()} />
                                <Button variant='danger' onClick={this.ClearSelected}>Clear</Button>
                                <Button variant='success' onClick={this.ImportInv} >Import / Save Button</Button>
                            </div>)
                        }

                    </Col>
                </Row>
            </Container>
        )
    }
}

const invoiceListData = (invoiceState = [], selectedFunction, selected = []) => {
    if (invoiceState.length === 0) return [];

    let newScheme = [];
    invoiceState.forEach(i => {
        const isSelected = selected.length > 0 ? selected.some(s => s.invcKey === i.invcKey) : false;
        const invoiceScheme = {
            InvoiceType: invoiceType(i.docType),
            InvoiceNumber: i.invcNo,
            SAMTradingName: i.samTradingName,
            SignOffDate: moment(i.dateSignOff).format('L'),
            TotalAmount: '$' + i.totlVal,
            action: <Button size="sm" variant={!isSelected ? 'success' : 'danger'} onClick={() => selectedFunction(i)} >{!isSelected ? 'Add' : 'Remove'}</Button>

        };
        newScheme.push(invoiceScheme);
    });
    return newScheme;
};

const invoiceType = (doctype) => {
    switch (doctype) {
        case 'I': return 'Invoice'
        case 'C': return 'Credit'
        default: return doctype
    }

}

const minDate = () => {
    var myCurrentDate = new Date();
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 1);

    return myPastDate
}

import BaseApi from './BaseApi';

// Get Order
export async function GetOrder(orderId) {
    return await BaseApi(`api/Order/GetOrder?orderId=${orderId}`, 'GET');
};

//Filters
export async function GetOrderFilterList(isAccepted, isRejected) {

    return BaseApi(
        `api/Order/OrderFilterList?isAccepted=${isAccepted}&isRejected=${isRejected}`,
        'POST');

};

//Orders
export async function GetOrderList(param) {

    return BaseApi('api/Order/OrderList',
        'POST',
        {
            ...param
        }
    );
};

// Notes
export async function InsertOrderNote(orderId, noteText, noteCode) {

    return BaseApi('api/Order/InsertOrderNote',
        'POST',
        {
            orderId: orderId,
            noteText: noteText,
            noteCode: noteCode
        }
    );
};

export async function DeleteOrderNote(noteId, orderId) {

    return BaseApi(`api/Order/DeleteOrderNote?noteId=${noteId}&orderId=${orderId}`, 'POST');
};

export async function UpdateOrderNote(noteId, noteText, orderId) {

    return BaseApi(
        `api/Order/UpdateOrderNote?noteId=${noteId}&noteText=${noteText}&orderId${orderId}`,
        'POST'
    );
};

//PromiseShipDate

export async function SetOrderPromisedShipDateById(orderId, promisedShipDate) {

    return BaseApi(`api/Order/SetOrderPromisedShipDateById?orderId=${orderId}&promisedShipDate=${promisedShipDate}`,
        'POST');
};

//GetInvoicesByPurchaseOrderId

export async function GetInvoicesByPurchaseOrderNumber(purchaseOrderNumber) {
    return BaseApi(`api/Order/GetInvoicesByPurchaseOrderNumber?purchaseOrderNumber=${purchaseOrderNumber}`, 'GET');
};

//SetOrderIsAccepted
export async function SetOrderIsAcceptedById(orderId, orderInvoices, promisedShipDate) {
    return BaseApi(
        'api/Order/SetOrderIsAcceptedById',
        'POST', {
            OrderId: orderId,
            OrderInvoices: orderInvoices,
            PromisedShipDate: promisedShipDate

        });
};

//SetOrderRejected
export async function SetOrderIsRejectedById(param) {

    return BaseApi(
        'api/Order/SetOrderIsRejectedById',
        'POST', {
            ...param
        });
};

//InsertOrderInvoice
export async function InsertOrderInvoice(orderId, orderInvoices, promisedShipDate) {
    return BaseApi(
        'api/Order/InsertOrderInvoice',
        'POST', {
            OrderId: orderId,
            OrderInvoices: orderInvoices,
            PromisedShipDate: promisedShipDate

        });
};

//GetOrderXMLById
export async function GetOrderXMLById(orderId) {
    return BaseApi(`api/Order/GetOrderXMLById?orderId=${orderId}`, 'GET');
};

//GetOrderAckXMLByOrderId
export async function GetOrderAckXMLByOrderId(orderId) {
    return BaseApi(`api/Order/GetOrderAckXMLByOrderId?orderId=${orderId}`, 'GET');
};

export async function GetInvoiceXMLByOrderId(orderId) {
    return BaseApi(`api/Order/GetInvoiceXMLByOrderId?orderId=${orderId}`, 'GET');
};

export async function GetInvoiceXMLByInvoiceId(invoiceId) {
    return BaseApi(`api/Order/GetInvoiceXMLByInvoiceId?invoiceId=${invoiceId}`, 'GET');
};

export async function GetTradingPartnerLogo(tradingPartnerId) {
    return BaseApi(`api/TradingPartner/GetTradingPartnerLogo?tradingPartnerId=${tradingPartnerId}`, 'GET');
};

export async function SetOrderAcknowledgementForResend(invoiceId) {
    return BaseApi(`api/Order/SetOrderAcknowledgementForResend?invoiceId=${invoiceId}`, 'POST');
};

export async function GetAuditByOrderId(orderId, pageSize, PageNumber) {
    return BaseApi(`api/Audit/GetAuditByOrderId?orderId=${orderId}&pageSize=${pageSize}&pageNumber=${PageNumber}`,
        'GET');
};

export async function GetPromiseShipDate(tradingPartnerId, isUrgent, samInvoiceId) {
    return BaseApi(
        `api/Order/GetPromiseShipDate?tradingPartnerId=${tradingPartnerId}&isUrgent=${isUrgent}&samInvoiceId=${
        samInvoiceId}`,
        'POST');
};

export async function GetOutstandingInvoices(searchInput, pageNumber, pageSize) {
    
    return BaseApi(`api/Order/GetOutstandingInvoices?searchInput=${searchInput}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        'GET');
};


export async function SetOrderInvoiceAsManuallySubmitted(orderId, invoiceOrderId) {
    
    return BaseApi(`api/Order/SetOrderInvoiceAsManuallySubmitted`,
        'POST',{
            orderId,
            invoiceOrderId
        });
};

export async function RemoveInvoiceFromPurchaseOrder(orderId, invoiceOrderId) {
    
    return BaseApi(`api/Order/RemoveInvoiceFromPurchaseOrder`,
        'POST',
        {
            orderId,
            invoiceOrderId
        });
};

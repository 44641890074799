import BaseApi from './BaseApi';
const method = {
    GET: 'GET',
    POST: 'POST'
}

export async function GetUser(adalUser) {

    return BaseApi(
        'api/User/GetUser',
        'POST',
        {
            Oid: adalUser.oid
        });
}

export async function CreateNewUser(adalUser, timeZone, TimeZoneOffSet) {
    return BaseApi(
        'api/User/GetUser', 
        method.POST, 
        {
            Oid: adalUser.oid,
            Email: adalUser.upn,
            FirstName: adalUser.given_name,
            LastName: adalUser.family_name,
            TimeZone: timeZone,
            TimeZoneOffSet: TimeZoneOffSet
        }
    )
}

export async function SetPhoneNumber(phoneNumber) {
    return BaseApi(
        `api/User/SetPhoneNumber?phoneNumber=${phoneNumber}`,
        'POST');
}

export async function SetUserTheme(isDark) {
    return BaseApi(
        `api/User/SetUserTheme?isDark=${isDark}`,
        'POST');
}

export async function SetReceiveErrorNotification(isReceiveError) {
    return BaseApi(
        `api/User/SetReceiveErrorNotification?isReceiveError=${isReceiveError}`,
        'POST');
}

export async function SetReceiveUserReport(isReceiveUserReport) {

    return BaseApi(
        `api/User/SetReceiveUserReport?isReceiveUserReport=${isReceiveUserReport}`,
        'POST');
}

export async function SetRoleId(setUserId, setRoleId) {
    return BaseApi(
        `api/User/SetRoleId?setUserId=${setUserId}&setRoleId=${setRoleId}`,
        'POST');
}

export async function SetTimeZone(timeZone) {
    return BaseApi(
        `api/User/SetTimeZone?timeZone=${timeZone.TimeZone}&timeZoneOffSet=${timeZone.TimeZoneOffSet}`,
        'POST');
}

export async function SetUserIsActive(roleId, setUserId, isActive) {
    return BaseApi(
        `api/User/SetUserIsActive?roleId=${roleId}&setUserId=${setUserId}&isActive=${isActive}`,
        'POST');
}

export async function GetTradingPartnerList() {
    return BaseApi(
        `api/User/GetUserReportSetting`,
        'GET');
}

export async function SetUserReport(tradingPartner, isActive) {

    return BaseApi(
        `api/User/SetUserReport?tradingPartnerId=${tradingPartner}&isActive=${isActive}`,
        'POST'
    );
}

export async function GetUsers() {

    return BaseApi(
        `api/User/GetUsers`,
        'GET'
    );
}

export async function GetRoles() {

    return BaseApi(
        `api/User/GetRoles`,
        'GET'
    );
}

export async function SetUserRole(setUserId, setRoleId) {

    return BaseApi(
        `api/User/SetUserRole?setUserId=${setUserId}&setRoleId=${setRoleId}`,
        'POST'
    );
}

export async function TestUserFunction() {

    return BaseApi('api/User/TestUserFunction', 'GET');
}

export async function GetTradingPartners() {
    return BaseApi('api/TradingPartner/GetTradingPartners', 'GET');
}

export async function GetTradingPartnerTemplate() {
    return BaseApi('api/TradingPartner/GetTemplate', 'GET');
}

export async function UpdateTradingPartner(tradingPartner) {

    return BaseApi('api/TradingPartner/UpdateTradingPartner', 'POST', tradingPartner);
}

export async function GetReleaseScheduleType() {
    return BaseApi('api/TradingPartner/GetReleaseScheduleType', 'GET');
}

export async function GetReleaseScheduleConfigForTradingPartner(tradingPartnerId) {
    return BaseApi(`api/TradingPartner/GetReleaseScheduleConfigForTradingPartner?tradingPartnerId=${tradingPartnerId}`,
        'GET');
}

export async function UpdateReleaseScheduleConfigForTradingPartner(props) {
    return BaseApi(
        `api/TradingPartner/UpdateReleaseScheduleConfigForTradingPartner?tradingPartnerId=${props.tradingPartnerId
        }&releaseScheduleTypeId=${props.releaseScheduleTypeId}&xValue=${props.xValue}&timeZoneId=${props.timeZoneId}`,
        'POST');
}

export async function InsertCustomFTPS(customFTPS){
    return BaseApi('api/TradingPartner/InsertFTPDetails', 'POST', customFTPS );
}

export async function GetFTPDetails(tradingPartnerId){
    return BaseApi(`api/TradingPartner/GetFTPDetails?tradingPartnerId=${tradingPartnerId}`, 'GET')
}
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UnauthorisedPath } from '../../Globals/RoutePaths';

const PrivateRoleRoute = ({ component: Component, componentValues,  isAuthorised, restrictedToRoleId, EDIProfile , ...rest }) => {

    return (
        <Route {...rest} key={componentValues ? componentValues.localStorageReference : null} render={props => (isAuthorised && restrictedToRoleId <= EDIProfile.roleId ? <Component {...props} {...componentValues} ediProfile={EDIProfile}/> : <Redirect to={UnauthorisedPath} />)} />
    );
};

export default PrivateRoleRoute;
import BaseApi from './BaseApi';

export async function GetInvoice(invoiceId) {
    return BaseApi(
        `api/Invoice/GetInvoice?invoiceId=${invoiceId}`,
        'GET');
}

export async function GetInvoiceFilterList() {

    return BaseApi(
        'api/Invoice/InvoiceFilterList',
        'GET');
}

export async function GetInvoiceList(param) {
    return BaseApi('api/Invoice/InvoiceList',
        'POST',
        {
            ...param
        }
    );
}

export async function UpdateInvoiceReleaseTime(param) {
    return BaseApi('api/Invoice/UpdateInvoiceReleaseTime',
        'POST',
        {
            ...param
        });
}

export async function SetInvoiceForResend(invoiceId) {
    return BaseApi(`api/Invoice/SetInvoiceForResend?invoiceId=${invoiceId}`, 'POST');
}

export async function GetAuditByInvoiceId(invoiceId, pageSize, pageNumber) {
    return BaseApi(`api/Audit/GetAuditByInvoiceId?invoiceId=${invoiceId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
        'GET');
}
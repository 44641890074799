import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Loading = (props) => {
    //iscallback... 
    //reloadpage
    return (
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    )
}
import React, { useState, Fragment } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { InviteNewUser } from './../../FetchApi/User';
import { SuccessToastR, ErrorToastR } from './../../Globals/ToastrReponses';

export const InviteUser = ({userName }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onInvite = (e) => {

        e.preventDefault();

        const emailAddress = e.target[0].value;
        if (emailAddress.toLowerCase().includes('@alsglobal.com')) {
            InviteNewUser(emailAddress, userName).then((result) => {
                if (result) {
                    SuccessToastR(`Invitation was sent to ${emailAddress}`);
                }
            });
        } else {
            ErrorToastR('You can only invite members from als global');
        }

        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <Button variant="success" onClick={() => setIsOpen(true)}>Invite User</Button>

            <Modal size="lg" show={isOpen} onHide={() => setIsOpen(false)}>

                <Modal.Header closeButton>
                    <Modal.Title>Invite ALS User</Modal.Title>
                </Modal.Header>
                <Form onSubmit={onInvite}>
                    <Modal.Body>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"/>
                            <Form.Text className="text-muted">
                                You can only invite ALS users.
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setIsOpen(false)}>Close</Button>
                        <Button variant="success" type="submit">Invite</Button>
                    </Modal.Footer>
                </Form>

            </Modal>
        </Fragment>);
};
import React, { Component } from 'react';
import { GetInvoice } from '../FetchApi/Invoice';
import { Col, Container, Row } from 'react-bootstrap';
import { Loading } from '../components/Loading';
import { InformationBlock } from './../components/DisplayBlocks';

import { CopyToClipboard, ReturnDollarValueWithCommon } from '../components/Common/index';
import { GeneralSection, ContactSection, AddressSection, ReferencesSection, NotesSection, FobSection, CarrierSection, LineItemsData, LineItemsHeaderList } from '../components/Invoice/InvoiceContents';
import { PurchasesTable } from '../components/Table/index';
import { InvoiceOptions } from '../components/Invoice/InvoiceOptions';


export default class Invoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: undefined
        };
    };

    componentDidMount() {
        GetInvoice(this.props.match.params.invoiceId).then((results) => this.setState({ invoice: results }));
    };

    render() {

        return (this.state.invoice ?
            <Container fluid className='purchaseContainer'>
                <InvoiceHeader {...this.state.invoice} />
                <InvoiceBody {...this.state.invoice} />
                <InvoiceLineItems chargesAllowances={this.state.invoice.chargesAllowances} lineItems={this.state.invoice.lineItems} isLoading={this.state.invoice ? false : true} totalAmount={this.state.invoice.totalAmount.toFixed(2)} />
            </Container>
            :
            <Loading />
        );
    };
};

const InvoiceHeader = (props) => {
    return (
        <Row>
            <Col lg={6} className='ColumnPadding'>
                <div style={{ display: 'inline-block' }}>
                    <h2 className='headerTitle'>Invoice Number: {props.invoiceNumber}</h2><CopyToClipboard textToCopy={props.invoiceNumber} />
                </div>
                <div>
                    <p className='headerSubTitle'>SAM Account: {props.accountNumber}</p>
                    <CopyToClipboard textToCopy={props.accountNumber} />
                </div>
                <div>
                    <p className='headerSubTitle'>Company Name: {props.companyName}</p>
                    <CopyToClipboard textToCopy={props.companyName} />
                </div>
            </Col>
            <Col lg={6} className='ColumnPadding'>
                <div className='toolBlock'>
                    {
                        <InvoiceOptions {...props} />
                    }
                </div>
            </Col>
        </Row>);
};

const InvoiceBody = (props) => {
    return (
        <Row>
            <InformationBlock {...GeneralSection(props)} />
            <InformationBlock {...ContactSection(props.contacts)} />
            <InformationBlock {...AddressSection(props.addresses, 'ST', 'Ship To')} />
            <InformationBlock {...AddressSection(props.addresses, 'BY', 'Bill To')} />
            <InformationBlock {...ReferencesSection(props.references)} />
            <InformationBlock {...NotesSection(props.notes)} />
            <InformationBlock {...FobSection(props.fobRelatedInstructions)} />
            <InformationBlock {...CarrierSection(props.carrierInfos)} />
        </Row>
    );
};

const InvoiceLineItems = (props) => {
    return (<Row>
        <Col lg={12} className='ColumnPadding'>

            <div className='detailBlock'>
                <Row>
                    <h5>Line Items</h5>
                    <Col md={12}>
                        <div style={{ padding: 10 }}>
                            <PurchasesTable
                                data={LineItemsData(props.lineItems)}
                                headers={LineItemsHeaderList}
                                emptyDataMessage={'There are no lineitems in this invoice'}
                                isLoading={props.isLoading}
                            />
                        </div>
                    </Col>
                </Row>
                {props.chargesAllowances.length > 0 &&
                    <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                            <h5>Freight Charges</h5>
                            {props.chargesAllowances.map((ca, key) => (
                                <Row key={key} style={{paddingRight: 10}}>
                                    <Col md={{ span: 4, offset: 4 }} className='freightChargeAllowance'>
                                        Code: {ca.allowChrgCode}
                                    </Col>
                                    <Col md={4} className='freightChargeAllowance'>
                                        Cost: {ReturnDollarValueWithCommon(ca.allowChrgAmt)}
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                }
                <Row>
                    <Col md={12}>
                        <h4 className='InvoiceTotal'>Total: {ReturnDollarValueWithCommon(props.totalAmount)}</h4>
                    </Col>
                </Row>
            </div>
        </Col>



    </Row>)
};
import BaseApi from './BaseApi';

export async function GetFailedTransactionList(param) {
    return BaseApi('api/FailedTransaction/FailedTransactionList',
        'POST',
        {
            transactionType: param.transactionType,
            pageNumber: param.pageNumber,
            pageSize: param.pageSize
        }
    );
}
import * as moment from 'moment';
import { DisplayUserDateTime } from './../../Globals/UserDateTime';

export const TimeAgoString = (startingTime) => {
    const userStartingTime = DisplayUserDateTime(startingTime, true, true);
    const myNowDateTime = DisplayUserDateTime(new Date(Date.now()), true, true);
    
    const a = moment(userStartingTime);
    const b = moment(myNowDateTime);
    const timeAgo = b.to(a);
    return timeAgo;
}
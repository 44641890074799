import React, { useState } from 'react';
import Glyphicon from '@strongdm/glyphicon';

const SettingsOption = (props) => {
    const [isOpen, setBlockState] = useState(false);
    const handleBlock = () => {

        if (props.onOpen) {
            if (!isOpen) {
                props.onOpen();
            }
        }

        setBlockState(!isOpen);
    };

    return (
        <div className="settingsOptionBlock">
            <h5 onClick={handleBlock}>
                {!isOpen && <Glyphicon glyph="chevron-up"/>}
                {isOpen && <Glyphicon glyph="chevron-down"/>}
                {props.title}
                <br/>
                <span className="settingsSubtitle">{props.subtitle}</span>
            </h5>
            {isOpen && <hr/> }
            {isOpen && props.children}
        </div>
    );
};

export default SettingsOption;
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import * as Orders from './Orders';
// import * as Order from './Order';
// import * as Invoices from './Invoices';
// import * as Invoice from './Invoice';
// import * as FailedTransactions from './FailedTransactions';
import * as UserProfile from './UserProfile';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    // purchaseOrders: Orders.reducer,
    // currOrder: Order.reducer,
    // invoices: Invoices.reducer,
    // currInvoice: Invoice.reducer,
    // failedOrders: FailedTransactions.reducer,
    // failedInvoices: FailedTransactions.reducer,
    Profile: UserProfile.reducer
});
export default createRootReducer;
/* global localStorage */
import React from 'react';
import ActiveFilterOption from './ActiveFilters';
import { Spinner } from 'react-bootstrap';

export default class FilterBar extends React.Component {
    constructor(props) {
        super(props);
        this.AddToFilter = this.AddToFilter.bind(this);
        this.RemoveFromFilter = this.RemoveFromFilter.bind(this);
        this.RemoveAllFilters = this.RemoveAllFilters.bind(this);
        this.SortBy = this.SortBy.bind(this);

        const json = localStorage.getItem(props.localStorageReference + '-selectedFilters');
        const loadFilters = JSON.parse(json);

        const selectedFilters = loadFilters ? loadFilters : [];
        this.props.UpdateTableData(selectedFilters);

        this.state = {
            selectedFilters: selectedFilters,
        };
    }

    SortBy(sortByValues) {
        this.props.UpdateTableData(this.state.selectedFilters, sortByValues);
    }

    AddToFilter(addFilter, idFilter, datetype) {

        if (!idFilter) {
            const getOldFilter = this.state.selectedFilters.filter((filters) => {
                if (filters.type === datetype) {
                    filters.name = addFilter.name;
                    filters.value = addFilter.value;
                    return filters;
                }
                return null;
            });

            if (getOldFilter.length === 0) {
                this.setState((prevState) => ({
                    selectedFilters: prevState.selectedFilters.concat(addFilter)
                }));
            } else {
                this.setState((prevState) => ({
                    selectedFilters: prevState.selectedFilters.filter((filters) => {
                        if (filters.type === datetype) {
                            filters = getOldFilter;
                        }
                        return filters;
                    })
                }));
            }
        } else {

            this.setState((prevState) => ({
                selectedFilters: prevState.selectedFilters.concat(addFilter)
            }));
        }
    }

    RemoveAllFilters() {
        this.setState(() => ({ selectedFilters: [] }));
    }

    RemoveFromFilter(RemovedItem) {
        this.setState((prevState) => ({
            selectedFilters: prevState.selectedFilters.filter((itemGettingRemoved) => {
                return itemGettingRemoved !== RemovedItem;
            })
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.localStorageReference) {
            if (prevState.selectedFilters.length !== this.state.selectedFilters.length) {
                const json = JSON.stringify(this.state.selectedFilters);
                localStorage.setItem(this.props.localStorageReference + '-selectedFilters', json);
                this.props.UpdateTableData(this.state.selectedFilters, this.props.sortBy);
            }
        }
    }

    render() {
        //cloning the functions within this class to the children functions

        const fn = (child) => {
            return React.cloneElement(child,
                {
                    AddToFilter: this.AddToFilter,
                    SelectedFilters: this.state.selectedFilters,
                });
        };

        const child = React.Children.map(this.props.children, fn);

        //let urgent = Urgent();
        if (this.props.isLoaded) {
            return (
                <div className="filterContainer">
                    {this.state.selectedFilters.length > 0 &&
                        <ActiveFilterOption selectedFilters={this.state.selectedFilters} RemoveFromFilter={
this.RemoveFromFilter} RemoveAllFilters={this.RemoveAllFilters}/>}
                    {child}
                </div>
            );
        } else {
            return (
                <div>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Filter Bar is Loading...</span>
                    </Spinner>
                </div>);
        }
    }
}
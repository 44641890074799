import React from 'react';
import { OrderPath } from './../../Globals/RoutePaths';
import { LinkToText, ReturnDollarValueWithCommon } from './../Common/index';
import { DisplayUserDateTime } from './../../Globals/UserDateTime';
 
export const GeneralSection = (props) => {

return {
        heading: 'General',
        detailRow: [
            { label: 'EDI Trading Partner', value: props.tradingPartnerName },
            { label: 'Customer Account', value: props.customerAccountNumber },
            { label: 'Batch No', value: props.internalOrderNumber },
            { label: 'Currency', value: props.buyersCurrency ? props.buyersCurrency : props.sellersCurrency },
            { label: 'Total Price $', value: ReturnDollarValueWithCommon(props.totalAmount.toFixed(2)) },

            { label: 'Purchase Order', value: props.orderId ? <LinkToText linkTo={OrderPath(props.orderId)} contents={props.purchaseOrderNumber} /> : props.purchaseOrderNumber},

            { label: 'Goods Shipped Date', value: DisplayUserDateTime(props.shipDate) }, // date
            { label: 'Invoice Date', value: DisplayUserDateTime(props.invoiceDate) }, // date
            { label: 'Invoice Release Date', value: DisplayUserDateTime(props.releaseDate), hideIfEmpty: true }, // date
            { label: 'Invoice Sent Date', value: DisplayUserDateTime(props.sentDate), hideIfEmpty: true }, // date
        ]
    };
};

export const ContactSection = (contacts) => {

    let Section = [];
    for (let i = 0; i < contacts.length; i++) {
        Section.push({ label: 'Contact Name', value: contacts[i].contactName, hideIfEmpty: true });
        Section.push({ label: 'Phone', value: contacts[i].primaryPhone, hideIfEmpty: true });
        Section.push({ label: 'Fax', value: contacts[i].primaryFax, hideIfEmpty: true });
        Section.push({ label: 'Email', value: contacts[i].email, hideIfEmpty: true });
        Section.push({ value: <br />});
    };

    return {
        heading: 'Contact Information',
        detailRow: Section
    };
};

export const AddressSection = (addresses = [] ,filterBy, heading ) => {
    const filteredAddresses = addresses.filter((address) => {
        if(address.addressTypeCode === filterBy) return address;
        return null;
    });

    let Section = [];
    const style = {marginBottom: 5}
    for (let i = 0; i < filteredAddresses.length; i++) {
        Section.push({ value: filteredAddresses[i].addressName ? <div style={style}>{filteredAddresses[i].addressName}</div> : null, hideIfEmpty: true });
        Section.push({ value: filteredAddresses[i].address1 ? <div style={style}>{filteredAddresses[i].address1}</div> : null, hideIfEmpty: true });
        Section.push({ value: filteredAddresses[i].address2 ? <div style={style}>{filteredAddresses[i].address2}</div> : null, hideIfEmpty: true });
        Section.push({ value: filteredAddresses[i].address3 ? <div style={style}>{filteredAddresses[i].address3}</div> : null, hideIfEmpty: true });
        Section.push({ value: filteredAddresses[i].address4 ? <div style={style}>{filteredAddresses[i].address4}</div> : null, hideIfEmpty: true });
        Section.push({ value: {...filteredAddresses[i].city, ...filteredAddresses[i].state, ...filteredAddresses[i].postalCode, ...filteredAddresses[i].country} ? <div style={style}>{filteredAddresses[i].city}, {filteredAddresses[i].state} {filteredAddresses[i].postalCode}, {filteredAddresses[i].country}</div>  : null, hideIfEmpty: true });
    };

    return {
        heading: heading, 
        detailRow: Section
    };
};

export const ReferencesSection = (references = []) => {
    let Section = [];

    for (let i = 0; i < references.length; i++) {
        Section.push({ label: references[i].description !== null  ? references[i].description : references[i].referenceQual, value: references[i].referenceId, hideIfEmpty: true });
    };

    return {
        heading: 'References', 
        detailRow: Section
    };
};

export const NotesSection = (notes = []) => {

    let Section = [];

    for (let i = 0; i < notes.length; i++) {
        Section.push({ label: notes[i].noteCode, value: notes[i].noteText, hideIfEmpty: true });
    };

    return {
        heading: 'Notes', 
        detailRow: Section
    };
};

export const FobSection = (fobRelatedInstructions = []) => {

    let Section = [];

    for (let i = 0; i < fobRelatedInstructions.length; i++) {
        Section.push({ label: 'Location', value: fobRelatedInstructions[i].fobLocationDescription, hideIfEmpty: true });
        Section.push({ label: 'Pay Code', value: fobRelatedInstructions[i].fobPayCode, hideIfEmpty: true });
        Section.push({ label: 'Location Qualifier', value: fobRelatedInstructions[i].fobLocationQualifier, hideIfEmpty: true });
        Section.push({ value: <br />});
    };

    return {
        heading: 'F.O.B Related Instructions', 
        detailRow: Section
    };
};

export const CarrierSection = (carrierInfos = []) => {

    let Section = [];

    for (let i = 0; i < carrierInfos.length; i++) {
        Section.push({ label: 'Carrier Routing', value: carrierInfos[i].carrierRouting, hideIfEmpty: true });
        Section.push({ value: <br />});
    };

    return {
        heading: 'Carrier Details', 
        detailRow: Section
    };
};

export const LineItemsData = (lineItems = []) => {
    if (lineItems.length === 0) return [];

    let newScheme = [];
    lineItems.forEach(li => {
        const lineItemScheme = {
            lineSequenceNumber: li.lineSequenceNumber,
            buyerPartNumber: li.buyerPartNumber,
            vendorPartNumber: li.vendorPartNumber,
            shipQty: li.shipQty,
            shipQtyUOM: li.shipQtyUOM,
            purchasePrice: li.purchasePrice ? ReturnDollarValueWithCommon(parseFloat(li.purchasePrice).toFixed(2)) : li.purchasePrice,
            totalAmount: ReturnDollarValueWithCommon(parseFloat(li.purchasePrice * li.shipQty).toFixed(2))
        };

        newScheme.push(lineItemScheme);
    });
    return newScheme;
};


export const LineItemsHeaderList = [
    {
        headerName: 'Line'
    },{
        headerName: 'Buyers Part Number',
        style: { width: '13%' }
    },{
        headerName: 'Vendor Part Number',
        style: { width: '13%' }
    },{
        headerName: 'Quantity'
    },{
        headerName: 'UOM'
    },{
        headerName: 'Price($)'
    },{
        headerName: 'Subtotal'
    }
];

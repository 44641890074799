import React, { useState, useEffect, useRef } from 'react';
import { PostReportAPI } from './../../../../FetchApi/Reports';
import { Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ExportTimeZone, DisplayUserDateTime } from './../../../../Globals/UserDateTime';

export const HealthStatusReport = (props) => {

    const [exportData, setExportData] = useState(undefined);
    const callfetchAPi = () => PostReportAPI(props.fetchAPI, props.queryOptions ? props.queryOptions : [])
        .then((result) => { setExportData(result); });

    const didMountRef = useRef(false);

    useEffect(() => {

        if (!didMountRef.current) {
            didMountRef.current = true;
            callfetchAPi();
        };

    });

    const statusFilter = (status) => {
        if (!status) return false;

        const result = false;
        switch (status.name) {
        case 'LastEDIOrderCheck':
            return true;
        case 'LastOrderAcknowledgmentCheck':
            return true;
        case 'LastSAMInvoiceCheck':
            return true;
        case 'LastInvoiceReleaseCheck':
            return true;
        case 'LastRefreshSAMCompanyEDITradingPartnerMappings':
            return true;
        default:
            break;
        }

        return result;
    };

    const statusNextUpdateFilter = (status) => {
        if (!status) return false;

        const result = false;
        switch (status.name) {
        case 'NextEDIOrderCheck':
            return true;
        case 'NextOrderAcknowledgmentCheck':
            return true;
        case 'NextSAMInvoiceCheck':
            return true;
        case 'NextInvoiceReleaseCheck':
            return true;
        case 'NextRefreshSAMCompanyEDITradingPartnerMappings':
            return true;
        default:
            break;
        }

        return result;
    };

    const statusHealth = (status) => {

        let iconColor = undefined;

        switch (status.status) {
        case 'Good':
            iconColor = 'green';
            break;

        case 'Warning':
            iconColor = 'yellow';
            break;
        default:
            iconColor = 'red';
            break;
        }

        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={'tooltip-health'}>{ExportTimeZone(status.dateValue)}.</Tooltip>}>
                <FontAwesomeIcon style={{ color: iconColor }} icon={faCircle}/>
            </OverlayTrigger>
        );
    };

    return (
        <Row>
            <Col lg={6}>
                <h2>Status</h2>

                {
                    exportData &&
                        exportData.map((data, key) => (
                            statusFilter(data) &&
                            (<Row key={key} id="container">
                                 <Col sm={6}>{data.area}{data.name === 'LastSAMInvoiceCheck' ? ' from SAM' : ''}</Col>
                                 <Col sm={6}>{statusHealth(data)}</Col>
                             </Row>)
                        ))
                }

            </Col>
            {
                props.queryOptions.length > 0 &&
                    props.queryOptions[0].queryValue &&
                    (
                        <Col lg={6}>
                            <h2>Next Health Check</h2>
                            {
                                exportData &&
                                    exportData.map((data, key) => (
                                        statusNextUpdateFilter(data) &&
                                        (<Row key={key} id="container">
                                             <Col sm={6}>{data.area}{data.name === 'NextSAMInvoiceCheck' ? ' from SAM' : ''
                                    }</Col>
                                             <Col sm={6}>{DisplayUserDateTime(data.dateValue, true)}</Col>
                                         </Row>)
                                    ))
                            }

                        </Col>
                    )
            }

        </Row>);
};
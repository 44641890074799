import BaseApi from './BaseApi';

export async function HardResetAutoGeneratedTransactionalRecordsForTradingPartner(tradingPartnerId) {
    return BaseApi(
        `api/TradingPartners/HardResetAutoGeneratedTransactionalRecordsForTradingPartner?tradingPartnerId=${
        tradingPartnerId}`,
        'POST'
    );
}

export async function GetAudits(parms) {
    return BaseApi('api/Audit/GetAuditData',
        'POST',
        { ...parms }
    );
}

export async function GetAuditsList() {
    return BaseApi('api/Audit/GetAuditFilterList',
        'GET'
    );
}

export async function CreatePOSpawner(parms) {
    return BaseApi('api/Admin/CreatePOSpawner', 'POST', { ...parms });
}

export async function ManuallyImportInvoice(parms) {
    return BaseApi('api/Admin/ManuallyImportInvoice', 'POST', { ...parms });
}

export async function GetInvoicesToImport(pageNumber, pageSize,tradingPartner, start, end , invoiceNumber) {
    return BaseApi('api/Admin/GetInvoicesToImport'+ 
    '?tradingPartner= ' + tradingPartner + 
    '&startDate=' + start + 
    '&endDate=' + end + 
    '&invoiceNumber=' + invoiceNumber + 
    '&pageNumber=' + pageNumber  + 
    '&pageSize=' + pageSize 
    , 'GET');
}

export async function TestTime(parms)  {
    return BaseApi('api/Admin/TestTime', 'GET', { ...parms });
}

export async function GetServerTime() {
    return BaseApi('api/Admin/GetServerTime', 'GET');
}
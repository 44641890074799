
import rg4js from 'raygun4js';
import { RaygunConfig } from '../Globals/Config';


export const Raygun = () => {

    //Global setting
    if(!RaygunConfig()) return null;

    rg4js('enableCrashReporting', true);
    rg4js('apiKey', process.env.REACT_APP_RaygunKey);
    rg4js('options',
        {
            allowInsecureSubmissions: true,
            //debugMode: true,
            excludedHostnames: ['local'],
            //captureMissingRequests: false
        });
    rg4js('logContentsOfXhrCalls', true); 
    rg4js('withTags', [`Environment: ${process.env.REACT_APP_CURRENTENV}`]);
    rg4js('enablePulse', true);

};

export const RaygunUser = (isAuthenicated, user) => {


    //Global setting
    if(!RaygunConfig()) return null;

    // if no profile return nothing
    if(!isAuthenicated) return null;

    rg4js('setUser',
        {
            identifier: user.unique_name,
            isAnonymous: false,
            email: user.unique_name,
            firstName: user.given_name,
            fullName: user.name
        });
};
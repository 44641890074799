import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Glyphicon from '@strongdm/glyphicon';
import {
    faSuitcase,
    faFile,
    faHandshake,
    faGlobe,
    faCalendarAlt,
    faFastForward,
    faCalendarPlus,
    faUser,
    faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';

import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ActiveFilterOption = (props) => {

    return (
        <Fragment>
            <div className="activeFilter-header">
                <span>Active Filters</span>
            </div>
            <div className="activefilter-clearall">
                <span onClick={props.RemoveAllFilters}>Clear All</span>
            </div>
            {
                props.selectedFilters.map((selectedFilter, key) => (
                    <ActiveFilter
                        key={key}
                        selectedFilter={selectedFilter}
                        RemoveFromFilter={props.RemoveFromFilter} />
                ))
            }
        </Fragment>
    );
};

const ActiveFilter = (props) => {

    return (
        <div className="filterTextBox">
            <Row>
                <Col style={{ paddingLeft: 0 }
                } sm="10">
                    <ActiveFilterIcon {...props.selectedFilter} /><span className="activeselectedfilterBox"> {
                        props.selectedFilter.groupBy && props.selectedFilter.groupBy !== '1'
                            ? `${props.selectedFilter.groupBy}: `
                            : ''}{
                            props.selectedFilter.name}</span>
                </Col>
                <Col sm="2">
                    <Glyphicon onClick={(e) => {
                        props.RemoveFromFilter(props.selectedFilter);
                    }} className="removeIcon" glyph="remove-sign" />
                </Col>
            </Row>
        </div>
    );
};

const ActiveFilterIcon = (props) => {

    let iconType = undefined;
    switch (props.type) {
        case 'tradingPartners':
            iconType = faHandshake;
            break;
        case 'PODate':
            iconType = faFile;
            break;
        case 'poSearch':
            iconType = faFile;
            break;
        case 'country':
            iconType = faGlobe;
            break;
        case 'state':
            iconType = faGlobe;
            break;
        case 'acknowledgementSentDate':
            iconType = faCalendarPlus;
            break;
        case 'receivedDate':
            iconType = faCalendarAlt;
            break;
        case 'isUrgent':
            iconType = faFastForward;
            break;
        case 'accountNumbers':
            iconType = faUser;
            break;
        case 'invoiceDate':
            iconType = faCalendarAlt;
            break;
        case 'invoiceNumber':
            iconType = faFile;
            break;
        case 'invoiceTypeCode':
            iconType = faFileInvoiceDollar;
            break;
        default:
            iconType = faSuitcase;
            break;
    };

    return (
        <Fragment>
            <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip id={'tooltip-top'}>{props.typeName}</Tooltip>}>
                <FontAwesomeIcon style={{ paddingRight: 5 }} icon={iconType} size="lg" />
            </OverlayTrigger>

        </Fragment>
    );
};

export default ActiveFilterOption;
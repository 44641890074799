import React, { Fragment } from 'react';
import { AddGrindItem, ReportGrid } from './index';
import { InsertReportConfig, GetReportConfig, DeleteFromReportConfig, UpdateReportConfig } from
    './../../../FetchApi/Reports';
    
export default class GridBase extends React.Component {
    constructor(props) {
        super(props);
        this.updateGrid = this.updateGrid.bind(this);
        this.removeFromGridOption = this.removeFromGridOption.bind(this);

        this.state = {
            grid: undefined,
            tradingPartners: undefined,
            removeFromGridOption: false
        };
    }

    componentDidMount() {
        // fetchAPI getgridSettings
        GetReportConfig().then((result) => this.setState(() => ({
            grid: result ? result.grid : undefined,
            tradingPartners: result ? result.tradingPartners : undefined
        })));

    }

    removeFromGridOption() {
        this.setState((prevState) => ({ removeFromGridOption: !prevState.removeFromGridOption }));
    }

    updateGrid(action, gridOption) {
        switch (action) {
        case 'add':
            //add to database then get id as result to add to grid
            InsertReportConfig(gridOption).then(
                (result) => this.setState((prevState) => ({
                    grid: prevState.grid
                        ? prevState.grid.concat({ ...gridOption, id: result })
                        : [{ ...gridOption, id: result }]
                })));
            break;
        case 'delete':
            DeleteFromReportConfig(gridOption).then(
                (result) => this.setState((prevState) => ({
                    grid: prevState.grid.filter((prgrind) => { return prgrind.id !== gridOption; })
                })));
            break;
        case 'edit':
            this.setState((prevState) => ({
                grid: prevState.grid.filter((item) => {
                    if (item.reportName === gridOption.reportName) {
                        item.queryOptions = gridOption.queryOptions;
                    }
                    return item;
                })
            }));

            UpdateReportConfig(gridOption);
            break;

        default:
            break;
        }

    }

    render() {
        const results = {
            ...this.state,
            updateGrid: (action, gridOption) => this.updateGrid(action, gridOption)
        };

        return (
            <Fragment>
                <AddGrindItem grid={this.state.grid} updateGrid={(action, gridOption) =>
                    this.updateGrid(action, gridOption)} removeFromGridOption={this.removeFromGridOption}/>
                {this.state.grid && <ReportGrid {...results}/>}
            </Fragment>
        );
    }
}
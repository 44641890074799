import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const OptionsBase = (queryOptions, props) => {

    if (queryOptions === []) return [];

    if (props === undefined || props === [] || props.length === 0) {
        return queryOptions;
    } else {
        queryOptions.filter(option => {
            props.forEach(prop => {
                if (option.queryName === prop.queryName) {
                    return option.queryValue = prop.queryValue;
                }
            });
            return null;
        });

        return queryOptions;
    }
};

const ReportOptionTemplate = (reportName, title = 'title', summary = 'summary', fetchAPI = 'fetchAPI', queryOptions =
    [], icon = faQuestionCircle, props, iconColor) => {

        // get filter item

        const reportProps = props
            ? props.filter((item) => {
                if (item.reportName === reportName) {
                    return item;
                }
                return null;
            })
            : [];

        return {
            reportName,
            title,
            summary,
            fetchAPI,
            queryOptions: OptionsBase(queryOptions, reportProps.length > 0 ? reportProps[0].queryOptions : undefined),
            icon: icon,
            iconColor: iconColor
        };
    };

export default ReportOptionTemplate;
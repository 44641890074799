import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

export const CloseButton = ({contents = 'Button Name', onClickFunction, isDisabled = false }) => {
    return <Button disabled={isDisabled}  variant='danger' onClick={onClickFunction}>{contents}</Button>
}

export const SaveButton = ({contents = 'Button Name', onClickFunction, isDisabled = false }) => {
    return <Button disabled={isDisabled} variant='success' onClick={onClickFunction}>{contents}</Button>
}

export const LinkToButton = ({contents = 'Button Name', linkTo = '/' }) => {
    return (<LinkContainer to={linkTo}><Button variant='primary' >{contents}</Button></LinkContainer>)
}

export const LinkToText = ({contents = 'text', linkTo='/'}) => {
    return (<LinkContainer to={linkTo}><a>{contents}</a></LinkContainer>)
}
import React, { useState, useEffect, Component } from 'react';
import { InsertCustomFTPS, GetFTPDetails } from '../../FetchApi/Settings';
import { Form, Button } from 'react-bootstrap';
import { SuccessToastR, ErrorToastR } from './../../Globals/ToastrReponses';

const defaultState = {
    dir: null,
    ftpPassword: null,
    hostName: null,
    portNumber: null,
    username: null,
    isPgpEncrypt: false,
    tradingPartnerId: null
};


export class CustomFTPSettings extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            ...defaultState, tradingPartnerId: props.tradingPartner.id
        }
    }

    componentDidMount() {
        GetFTPDetails(this.props.tradingPartner.id).then((result) => {
            if (result != null) this.setState(() => ({ ...result, tradingPartnerId: this.props.tradingPartner.id }));
        });
    }

    componentDidUpdate() {
        if (this.state.tradingPartnerId != this.props.tradingPartner.id) {
            GetFTPDetails(this.props.tradingPartner.id).then((result) => {
            if (result != null)  { this.setState(() => ({ ...result, tradingPartnerId: this.props.tradingPartner.id }));
            } 
                else 
            { 
                this.setState(() => ({...defaultState, tradingPartner: this.props.tradingPartner.id}))
            }
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        InsertCustomFTPS({
            TradingPartnerId: this.state.tradingPartnerId,
            HostName: e.target.hostName.value,
            PortNumber: e.target.portNumber.value,
            Username: e.target.username.value,
            FTPPassword: e.target.ftpPassword.value,
            Dir: e.target.dir.value,
            IsPgpEncrypt: e.target.isPgpEncrypt.value
        }).then((result) => {
            if (result) {
                SuccessToastR('Success, your FTP details have been updated.');
            } else {
                ErrorToastR('Something went wrong, With the FTP details.');
            }
        });

    }

    onChange(e) {

        const input = {
            [e.target.name]: e.target.value
        }

        this.setState(() => ({ ...input }))

    }

    render() {
        return (<div className="tradingPartnerEditorContainer">
            <h2>Custom FTP</h2>
            <Form onSubmit={this.onSubmit}>
                <Form.Group className="mb-3" controlId="hostName">
                    <Form.Label>Host Name</Form.Label>
                    <Form.Control onChange={this.onChange} name="hostName" className="textinput" type="text" placeholder="Host Name" value={this.state.hostName ? this.state.hostName : ""} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="portNumber">
                    <Form.Label>Port Number</Form.Label>
                    <Form.Control onChange={this.onChange} className="textinput" name="portNumber" type="number" value={this.state.portNumber ? this.state.portNumber : ""} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control onChange={this.onChange} className="textinput" name="username" type="text" placeholder="Username" value={this.state.username ? this.state.username : ""} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="ftpPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={this.onChange} className="textinput" name="ftpPassword" type="password" />
                    <Form.Text className="text-muted">
                        Password will not be displayed, Please enter again up you are updating the content.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="dir">
                    <Form.Label>Direction</Form.Label>
                    <Form.Control onChange={this.onChange} className="textinput" name="dir" type="text" placeholder="Direction" value={this.state.dir ? this.state.dir : ""} />
                    <Form.Text className="text-muted">
                        Location of upload
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="isPgpEncrypt">
                    <Form.Label>Pgp Encrypted</Form.Label>
                    <Form.Control onChange={this.onChange} type="checkbox" name="isPgpEncrypt" value={this.state.isPgpEncrypt} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>)
    }
}

// export const CustomFTPSettings = ({tradingPartner}) => {

//     const [customFTPS, setcustomFTPS] = useState(defaultState);

//     useEffect(() => {
//         GetFTPDetails(tradingPartner.id).then((result) =>{
//             if(result != null) setcustomFTPS(result);
//         });
//     })

//     const onSubmit = (e) => {
//         e.preventDefault();
//         InsertCustomFTPS({
//             TradingPartnerId: tradingPartner.id,
//             HostName: e.target.hostName.value,
//             PortNumber: e.target.portNumber.value,
//             Username: e.target.username.value,
//             FTPPassword: e.target.password.value,
//             Dir: e.target.direction.value,
//             IsPgpEncrypt: e.target.isPgpEncrypt.value
//         }).then((result) => {
//             if (result) {
//                 SuccessToastR('Success, your FTP details have been updated.');
//             } else {
//                 ErrorToastR('Something went wrong, With the FTP details.');
//             }
//         }).then(
//             setcustomFTPS({
//                 dir: e.target.direction.value,
//                 ftpPassword: e.target.password.value,
//                 hostName: e.target.hostName.value,
//                 portNumber: e.target.portNumber.value,
//                 username: e.target.username.value,
//                 isPgpEncrypt: e.target.isPgpEncrypt.value,
//             }));
//     }

//     return (
//         <div className="tradingPartnerEditorContainer">
//             <h2>Custom FTP</h2>
//             <Form onSubmit={onSubmit}>
//                 <Form.Group className="mb-3" controlId="hostName">
//                     <Form.Label>Host Name</Form.Label>
//                     <Form.Control className="textinput" type="text" placeholder="Host Name" value={customFTPS.hostName ? customFTPS.hostName : ""}/>
//                 </Form.Group>

//                 <Form.Group className="mb-3" controlId="portNumber">
//                     <Form.Label>Port Number</Form.Label>
//                     <Form.Control className="textinput" type="number" value={customFTPS.portNumber ? customFTPS.portNumber : ""} />
//                 </Form.Group>

//                 <Form.Group className="mb-3" controlId="username">
//                     <Form.Label>Username</Form.Label>
//                     <Form.Control className="textinput" type="text" placeholder="Username" value={customFTPS.username ? customFTPS.username : ""}  />
//                 </Form.Group>

//                 <Form.Group className="mb-3" controlId="password">
//                     <Form.Label>Password</Form.Label>
//                     <Form.Control className="textinput" type="password"/>
//                     <Form.Text className="text-muted">
//                         Password will not be displayed
//                     </Form.Text>
//                 </Form.Group>

//                 <Form.Group className="mb-3" controlId="direction">
//                 <Form.Label>Direction</Form.Label>
//                 <Form.Control className="textinput" type="text" placeholder="Direction" value={customFTPS.dir ? customFTPS.dir : ""} />
//                 <Form.Text className="text-muted">
//                     Location of upload
//                 </Form.Text>
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="isPgpEncrypt">
//                 <Form.Label>Pgp Encrypted</Form.Label>
//                 <Form.Control  type="checkbox" value={customFTPS.isPgpEncrypt} />
//                 </Form.Group>



//                 <Button variant="primary" type="submit">
//                     Submit
//                 </Button>
//             </Form>
//         </div>)
// }
import React from 'react';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import ReportOptionTemplate from '../ReportOptionTemplate';
import { BarChart } from '../../Reports/BarChartReport';

export const reportName = 'TradingPartnerOrderBar';

export const TradingPartnerOrderBarOption = (props) => {
    // all values will create boxes based on their type
    const queryOptions = [];

    // ReportOptionTemplate - reportName, title, summary, fetchAPI (Report/{fetchAPI name}),queryOptions (fetchAPI params), icon (FontAwsomeIcon), props (populate new queryOption values from database if any)
    return ReportOptionTemplate(reportName,
        'Orders Received Today',
        'Orders received today group by Trading Partners',
        'GetTradingPartnerOrderBar',
        queryOptions,
        faChartBar,
        props);
};

export const TradingPartnerOrderBar = (props) => {
    const barColors = ['#A568E2', '#7468E2', '#68E2B9', '#D9E268'];
    return (<BarChart {...props} color={barColors} 
        title="Orders Received Today" 
        subtext="Orders Received grouped by Trading Partner" 
        nodata="No orders have been received today."/>
    );
};
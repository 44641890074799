import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconIndecatorWithHoverOver = ({ displayIfTrue = true, icon, hoverOverMessage, hoverPlacement = 'bottom', size = 'lg', className='' }) => {

    return displayIfTrue ?
        <OverlayTrigger
            placement={hoverPlacement}
            overlay={<Tooltip id={'iconIndecator'}>
                {hoverOverMessage}
            </Tooltip>
            }>
            <FontAwesomeIcon className={className} style={{ float: 'left', padding: 5 }} size={size} icon={icon} />
        </OverlayTrigger>
        : <Fragment />
}
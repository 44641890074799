import React from 'react';
import { Button } from 'react-bootstrap';
import { LoginAdalUser } from './../../UserAuthentication/AdalAuthentication'
import logo from './../../Images/logo.png';

const Login = () => {

    return (
        <div style={{margin: 20}}>
            <div style={{margin: 15}} className='centerColumnContent'>
                <img alt="logo" src={logo} />
            </div>
            <div style={{margin: 15}} className='centerColumnContent'>
                <Button className="loginButton " onClick={() => (LoginAdalUser())}>Login</Button>
            </div> 
            <div style={{margin: 15}} className='centerColumnContent'>
                <p>Please login to get started</p>
            </div>
        </div>

    );
};

export default Login;
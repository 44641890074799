import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';

const Unauthorised = (props) => {
    return (
        <Container>
        <Row style={{ textAlign: 'center', margin: 20 }}>
            <Col>
                <FontAwesomeIcon icon={faFrown} size="5x" style={{marginBottom: 10}}/>
                <h3>You do not have permission to access ALS EDI. </h3>
                <h3>Please contact a billing administrator for access.</h3>
            </Col>
        </Row>
    </Container>
    );
};

export default Unauthorised;
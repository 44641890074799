import React, { useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { HardResetAutoGeneratedTransactionalRecordsForTradingPartner, CreatePOSpawner } from
    './../../FetchApi/Administration';
import { SuccessToastR, ErrorToastR } from './../../Globals/ToastrReponses';
import DateTimePicker from 'react-datetime-picker';
import { ConvertToApiFriendlyDateTime } from './../../Globals/UserDateTime'; 

export const POSpawner = (props) => {

    const defaultState = {
        TradingPartnerId: props.tradingPartnerId,
        PosToCreate: 10,
        SpawnSessionIntervalSeconds: 120,
        SpawnSessionPosPerInterval: 1,
        SpawnSessionStartTime: new Date(),
        MaxPoLineItems: 1,
        IsUseDefaultExamplePoLineItems: false
    };

    const [dateTime, setDateTime] = useState(defaultState.SpawnSessionStartTime);

    const [poSpawnSettingsValidation, setpoSpawnSettingsValidation] = useState({
        PosToCreate: true,
        SpawnSessionIntervalSeconds: true,
        SpawnSessionPosPerInterval: true,
        SpawnSessionStartTime: true,
        MaxPoLineItems: true,
    });

    const validateNumber = (input, min, max) => {
        const value = parseInt(input, 10);
        if (value >= min && value <= max) {
            return true;
        }
        return false;
    };

    const validateDateTime = (input) => {
        var dateInput = new Date(input);
        if (defaultState.SpawnSessionStartTime >= dateInput &&
            defaultState.SpawnSessionStartTime <= dateInput.setDate(dateInput.getDate() + 1)) {
            return true;
        }
        return false;
    };

    const submitPOSpawner = (e) => {
        e.preventDefault();

        const isValid = {
            PosToCreate: validateNumber(e.target.PosToCreate.value, 1, 100),
            SpawnSessionIntervalSeconds: validateNumber(e.target.SpawnSessionIntervalSeconds.value, 1, 1200),
            SpawnSessionPosPerInterval: validateNumber(e.target.SpawnSessionPosPerInterval.value, 1, 100),
            SpawnSessionStartTime: validateDateTime(e.target.SpawnSessionStartTime.value),
            MaxPoLineItems: validateNumber(e.target.MaxPoLineItems.value, 1, 10)
        };

        var hasAnyFalse = Object.keys(isValid).some(k => !isValid[k]);
        if (hasAnyFalse) {
            setpoSpawnSettingsValidation({
                ...isValid
            });
        } else {

            CreatePOSpawner({
                TradingPartnerId: props.id,
                PosToCreate: parseInt(e.target.PosToCreate.value, 10),
                SpawnSessionIntervalSeconds: parseInt(e.target.SpawnSessionIntervalSeconds.value, 10),
                SpawnSessionPosPerInterval: parseInt(e.target.SpawnSessionPosPerInterval.value, 10),
                SpawnSessionStartTime: ConvertToApiFriendlyDateTime(e.target.SpawnSessionStartTime.value),
                MaxPoLineItems: parseInt(e.target.MaxPoLineItems.value, 10),
                IsUseDefaultExamplePoLineItems: false
            }).then((result) => {
                if (result) {
                    SuccessToastR('Success, your purchase orders have been scheduled.');
                } else {
                    ErrorToastR('Something went wrong, The purchase orders were not scheduled');
                }
            });
        };
    };

    return (
        <div className="tradingPartnerEditorContainer">
            <h1>{props.name}</h1>
            <Button variant="danger" onClick={() => HardResetAutoGeneratedTransactionalRecordsForTradingPartner(props.id).then((result) => {

                    if (result) {
                        SuccessToastR(`Deleting all Spawned test PO for ${props.name}`);
                    } else {
                        ErrorToastR('Nope, Try again');
                    }
                })}>Delete All Spawned Purchase Orders</Button>

            <div style={{ paddingTop: 10 }}>
                <h2>Create Test Purchase Orders</h2>
                <Form onSubmit={submitPOSpawner} validated={false}>
                    <Form.Row>
                    <Form.Group as={Col} controlId="ControlInput1">
                    <Form.Label>Amount Of Purchase Orders</Form.Label>
                    <Form.Control className="textinput" type="text" pattern="[0-9]*" defaultValue={
defaultState.PosToCreate} name="PosToCreate" isInvalid={!poSpawnSettingsValidation.PosToCreate}/>
                    <Form.Text className="text-muted">How many purchase Orders will be spawned in total, once it reaches this number it will stop spawning.
                </Form
                .Text>
                <Form.Text className="text-muted">Minimum is 1, Max 100</Form.Text>
                <Form.Control.Feedback type="invalid">Please select a number between 1 - 100</Form.Control
                .Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="ControlInput2">
                    <Form.Label>Spawn Session Interval Seconds</Form.Label>
                    <Form.Control className="textinput" type="text" pattern="[0-9]*" defaultValue={
defaultState.SpawnSessionIntervalSeconds} name="SpawnSessionIntervalSeconds" isInvalid={!poSpawnSettingsValidation
    .SpawnSessionIntervalSeconds}/>
                    <Form.Text className="text-muted">This determines the frequency of the orders in Seconds.</Form
                    .Text>
                    <Form.Text className="text-muted">Minimum is 1, Max 1200</Form.Text>
                    <Form.Control.Feedback type="invalid">Please select a number between 1 - 1200</Form.Control
                    .Feedback>
                </Form.Group>
                </Form.Row>
                <Form.Row style={{ paddingTop: 10 }}>
                    <Form.Group as={Col} controlId="ControlInput3">
                        <Form.Label>Spawn Session Pos Per Interval </Form.Label>
                        <Form.Control className="textinput" type="text" pattern="[0-9]*" defaultValue={
defaultState.SpawnSessionPosPerInterval} name="SpawnSessionPosPerInterval" isInvalid={!poSpawnSettingsValidation
    .SpawnSessionPosPerInterval}/>
                        <Form.Text className="text-muted">This determines how many purchase orders are created each interval.</Form
                        .Text>
                        <Form.Text className="text-muted">Minimum is 1, Max 100</Form.Text>
                        <Form.Control.Feedback type="invalid">Please select a number between 1 - 100</Form.Control
                        .Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="ControlInput4">
                        <Form.Label>Max PoLine Items</Form.Label>
                        <Form.Control className="textinput" type="text" pattern="[0-9]*" defaultValue={
defaultState.MaxPoLineItems} name="MaxPoLineItems" isInvalid={!poSpawnSettingsValidation.MaxPoLineItems}/>
                        <Form.Text className="text-muted">Sets the maximum number of line items that will be imported from the example or by random. Use less if you want less work to import the order.</Form
                        .Text>
                        <Form.Text className="text-muted">Minimum is 1, Max 10</Form.Text>
                        <Form.Control.Feedback type="invalid">Please select a number between 1 - 10</Form.Control
                        .Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row style={{ paddingTop: 10 }}>
                    <Form.Group as={Col} controlId="ControlInput5">
                        <Form.Label>Spawn Session Start Time </Form.Label>
                        <br/>
                        <DateTimePicker disableClock={true} name="SpawnSessionStartTime" value={dateTime} onChange={(value) => setDateTime(new Date(value))}/>
                        <Form.Text className="text-muted">Between now and no more than 24 hours from now.</Form
                        .Text>
                        {!poSpawnSettingsValidation.SpawnSessionStartTime &&
                            <div className="invalid">Please select a date and time between now and 24hours from now.</div>
                        }
                    </Form.Group>
                </Form.Row>
                <Button variant="success" type="submit">Create</Button>
                </Form>
            </div>
        </div>
    );
};
import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { profileChangeAction } from '../ReduxStore/UserProfile';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { SetTheme } from './../Style/Theme';
import { SetUserIsActive, GetTradingPartnerList, SetUserReport, GetUsers, GetRoles, SetUserRole } from
    '../FetchApi/Settings';
import { TimeZoneModal, ActiveUser, TradingPartnerOptions, ToggleSwitch, SettingsOption } from './../components/UserSettings/index';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InviteUser } from './../components/UserSettings/InviteUser';
import { SuccessToastR } from './../Globals/ToastrReponses';

import { DisplayUserDateTime } from './../Globals/UserDateTime';
import { TestUserFunction } from './../FetchApi/User';

class UserSettings extends React.Component {
    constructor(props) {
        super(props);
        this.SetReceiveUserReport = this.SetReceiveUserReport.bind(this);
        this.ReceiveErrorNotification = this.ReceiveErrorNotification.bind(this);
        this.EnableEditMobileNumber = this.EnableEditMobileNumber.bind(this);
        this.SetUserTheme = this.SetUserTheme.bind(this);
        this.SavePhoneNumber = this.SavePhoneNumber.bind(this);
        this.SummaryReportEmails = this.SummaryReportEmails.bind(this);
        this.SaveUserReports = this.SaveUserReports.bind(this);
        this.ChangeUserIsActive = this.ChangeUserIsActive.bind(this);
        this.GetActiveUsers = this.GetActiveUsers.bind(this);
        this.GetSetRoles = this.GetSetRoles.bind(this);
        this.SetRole = this.SetRole.bind(this);
        this.TestFunction = this.TestFunction.bind(this);
        this.SetDateHoverOver = this.SetDateHoverOver.bind(this);
        this.ChangePreferredDateTime = this.ChangePreferredDateTime.bind(this);

        this.state = {
            MobileEditButton: true,
            ReportSummary: undefined,
            ActiveUsers: undefined,
            Roles: undefined,
        };
    }

    SetReceiveUserReport() {
        this.props.SetPhoneNumber('SetReceiveUserReport');

    }

    TestFunction() {
        TestUserFunction().then((result) => alert(DisplayUserDateTime(result, true)));

    }

    ReceiveErrorNotification() {
        if (this.props.EDIProfile.roleId > 0) {
            this.props.SetReceiveErrorNotification('SetReceiveErrorNotification');
        }
    }

    ChangeUserIsActive(setUserId, isActive) {

        SetUserIsActive(setUserId, isActive).then(() => {
            let userName = '';
            this.setState((prevState) => ({
                ActiveUsers: prevState.ActiveUsers.filter((user) => {
                    if (user.id === setUserId) {
                        user.isActive = isActive;
                        userName = user.firstName + ' ' + user.lastName;
                    }
                    return user;
                })
            }));
            SuccessToastR(`${userName} has been updated.`)
        }).catch(function() {
            // failed - server will throw error
        });
        
    }

    SummaryReportEmails() {
        if (!this.state.ReportSummary) {
            GetTradingPartnerList().then(result => {
                this.setState(({ ReportSummary: result }));
            });
        }
    }

    EnableEditMobileNumber() {
        this.setState((prevState) => {
            return { MobileEditButton: !prevState.MobileEditButton };
        });
    }

    SetUserTheme() {
        this.props.SetUserTheme('SetUserTheme');
        SetTheme(true);

    }

    GetSetRoles() {

        if (!this.state.Roles) {
            GetRoles().then((result) =>
                this.setState((prevState) => {
                    return { Roles: result };
                }));
        }
    }

    SetRole(userid, roleId) {
        SetUserRole(userid, roleId)
            .then((results) => {
 
                if (results === 1) {
                    const users = [...this.state.ActiveUsers];

                    let userName = '';
                    users.filter((user) => {
                        if(user.id === userid) {
                            user.roleId = roleId;
                            userName = user.firstName + ' ' + user.lastName;
                            const newRole = this.state.Roles.filter(role => {
                                return role.id === roleId
                              })
             
                              user.roleName = newRole[0].roleName

                        };
                        return user;
                    })


                    this.setState(() => ({
                        ActiveUsers: users
                    }));
                    SuccessToastR(`${userName} has been updated.`)

                }
                
            });

    }

    SetDateHoverOver() {
        this.props.SetDateHoverOver('SetDateHoverOver');
    }

    SavePhoneNumber(e) {
        e.preventDefault();

        const numberInput = e.target.elements.phoneNumber.value.trim();

        if (numberInput || numberInput !== 0) {
            this.props.SetPhoneNumber(numberInput).then(() => SuccessToastR('Your mobile has been updated.'));
            this.setState((prevState) => ({ MobileEditButton: !prevState.MobileEditButton }));
        }
    }

    GetActiveUsers() {

        if (!this.state.ActiveUsers) {
            GetUsers().then(result => {
                this.setState(({ ActiveUsers: result }));
            });
        }
    }

    ChangePreferredDateTime() {
        // updatedatabase on preferred datetime layout
    }


    SaveUserReports(tradingPartnerId, isActive) {

        SetUserReport(tradingPartnerId, isActive);

        this.setState((prevState) => ({
            ReportSummary: prevState.ReportSummary.filter((filters) => {
                if (filters.tradingPartnerId === tradingPartnerId) {
                    filters.isActive = isActive;
                }
                return filters;
            })
        }));
    }

    render() {

        if (this.props.IsAuthenicated) {
            return (
                <Container>
                    <Row>
                        <Col className="settingsContainer" md={{ span: 10, offset: 1 }}>
                            <h1 className="settingTitle">User Preferences</h1>

                            <div>
                                <h3>Overview</h3>
                                <Row>
                                    {
                                        // this.props.EDIProfile.email === 'jason.browne@alsglobal.com' &&
                                        // <Col md={12}>
                                        //     <Button onClick={this.TestFunction}>Test Function</Button>
                                        // </Col>
                                    }

                                    <Col md={6}>
                                        <ToggleSwitch
                                            label="Receive system error notifications"
                                            defaultChecked={this.props.EDIProfile.isReceiveErrorNotification}
                                            onChange={this.ReceiveErrorNotification}
                                            disabled={this.props.EDIProfile.roleId <= 5} />
                                    </Col>
                                    <Col md={6}>
                                        <p>Receive server error notification by SMS</p>
                                        <Form onSubmit={this.SavePhoneNumber}>
                                            Mobile Number:
                                            <input
                                                type="number"
                                                name="phoneNumber"
                                                placeholder=" No Mobile added"
                                                disabled={this.state.MobileEditButton}
                                                defaultValue={this.props.EDIProfile.phoneNumber}
                                                className="textinput"
                                                style={{ borderRadius: 5 }} />
                                            <span className="PSDButton" onClick={this.EnableEditMobileNumber
                                            }>
                                                <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faEdit} />
                                            </span>
                                            {!this.state.MobileEditButton && <button style={{ marginLeft: 5, float: 'none' }} className="noteAddNoteButton" type="submit" value="Submit">Save</button>}
                                            {!this.state.MobileEditButton &&  <span style={{ fontSize: 10 }}><br />Please include your areacode eg 61411111111</span>}

                                        </Form>
                                    </Col>
                                    <Col md={6}>
                                        <h5>Theme</h5>
                                        <p>You are able to choose from 2 types of themes, Light and Dark</p>
                                        <ToggleSwitch
                                            defaultChecked={this.props.EDIProfile.isDarkTheme}
                                            onChange={this.SetUserTheme}
                                            checkedName="DARK"
                                            uncheckedName="LIGHT" />
                                    </Col>

                                    <Col md={6}>
                                        <h5>Your timezone is set as:</h5>

                                        <p style={{ float: 'left' }}>{this.props.EDIProfile.timeZone}</p>
                                        <TimeZoneModal buttonName={<FontAwesomeIcon icon={faEdit} />
                                        } ProfileChange={this.props.SetTimeZone} customeSize="setting70Modal" />

                                    </Col>
                                    <Col md={6}>
                                        <h5>Show UTC Date</h5>
                                        <p>Provides a hover over to show the UTC date</p>
                                        <ToggleSwitch
                                            defaultChecked={this.props.EDIProfile.isDateHoverOver}
                                            onChange={this.SetDateHoverOver} />
                                    </Col>
                                    {
                                        // <Col md={6}>
                                        //     <h5>Date Time Display Preferences</h5>
                                        //     <ButtonGroup aria-label="timezone">
                                        //         <Button variant="primary">DD/MM/YYYY</Button>
                                        //         <Button variant="primary">MM/DD/YYYY</Button>
                                        //         <Button variant="primary">MMM DD YYYY</Button>
                                        //     </ButtonGroup>

                                        // </Col>
                                    }

                                </Row>
                            </div>
                            <SettingsOption
                                title="Email Error Notifications"
                                subtitle="Customize which trading partner you will receive errors from"
                                onOpen={this.SummaryReportEmails}>

                                <Row>
                                    <Col md={12}>
                                        <ToggleSwitch
                                            label="Receive error emails"
                                            defaultChecked={this.props.EDIProfile.isReceiveUserReport}
                                            onChange={this.SetReceiveUserReport} />
                                    </Col>

                                    {
                                        this.state.ReportSummary &&
                                        this.state.ReportSummary.length > 0 &&
                                        (this.state.ReportSummary.map((partner, key) =>
                                            <TradingPartnerOptions key={key} {...partner} SaveUserReports={this
                                                .SaveUserReports} />))
                                    }
                                </Row>

                            </SettingsOption>
                            {this.props.EDIProfile.roleId >= 5 &&
                                <Fragment>
                                    <hr />
                                    <SettingsOption
                                        title="Manage Users"
                                        subtitle="Manage the users access roles or deactivate their log in."
                                        onOpen={this.GetActiveUsers}>
                                        {this.state.ActiveUsers &&
                                            this.state.ActiveUsers.length > 0 &&
                                            <Row>
                                                <Col sm={12}>
                                                    <InviteUser userName={`${this.props.EDIProfile.firstName} ${this.props.EDIProfile.lastName}`} />
                                                </Col>

                                                {this.state.ActiveUsers.map((user) => (
                                                    <Col sm={6} key={user.id}>
                                                        <ActiveUser SetRole={this.SetRole} GetSetRoles={this.GetSetRoles} Roles={this.state.Roles} {...user} ChangeUserIsActive={this.ChangeUserIsActive} />
                                                    </Col>
                                                ))}

                                            </Row>

                                        }
                                    </SettingsOption>
                                </Fragment>}
                        </Col>
                    </Row>

                </Container>
            );
        } else {
            return (
                <Container>
                    <Row>
                        <Col style={{ margin: 'auto', height: '100%' }} className="settingsContainer" md={{
                            span: 10,
                            offset: 1
                        }}>
                            <Spinner animation="border">
                                <span className="sr-only">... Your profile is loading</span>
                            </Spinner>

                        </Col>
                    </Row>
                </Container>
            );
        }
    }
}

export default connect(
    state => state.Profile,
    dispatch => bindActionCreators(profileChangeAction, dispatch)
)(UserSettings);
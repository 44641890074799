import React, { Fragment } from 'react';
import Glyphicon from '@strongdm/glyphicon';
import { Form, InputGroup, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class FilterByTextInput extends React.Component {
    constructor(props) {
        super(props);
        this.ChangeDropDown = this.ChangeDropDown.bind(this);
        this.AddTextSearch = this.AddTextSearch.bind(this);

        this.state = {
            filterByTextInputDropDown: false,
            textValue: undefined
        };
    }

    ChangeDropDown() {

        this.setState(() => ({ filterByTextInputDropDown: !this.state.filterByTextInputDropDown }));
    }

    AddTextSearch(e) {
        e.preventDefault();

        const textInput = e.target.elements[0].value.trim();
        const type = this.props.Type;
        const typeName = this.props.TypeName;

        if (textInput) {
            const newTextInput = {
                type: type,
                typeName: typeName,
                id: -1,
                name: textInput,
                value: textInput
            };

            this.setState(() => ({ textValue: textInput }));
            this.props.AddToFilter(newTextInput, false, type);
            e.target.elements[0].value = '';

        }
    }

    render() {

        return (
            <Fragment>
                <div onClick={this.ChangeDropDown} className={this.state.filterByTextInputDropDown
                    ? 'filterDropdownActive'
                    : 'filterBarBlock'}>
                    <span>{this.props.iconType &&
                        <FontAwesomeIcon className="filterIcon" size="lg" icon={this.props.iconType}/>} {
                            this.props.Title}</span>{this.state.filterByTextInputDropDown
                                ? <Glyphicon style={{ float: 'right' }} glyph="chevron-up"/>
                                : <Glyphicon style={{ float: 'right' }} glyph="chevron-down"/>}
                </div>
                {
                    this.state.filterByTextInputDropDown &&
                    (
                        <div className="filterDropDownBlock">
                            <Form onSubmit={this.AddTextSearch}>
                                <Form.Row>

                                    <Form.Group as={Col} controlId="validationCustomUsername">
                                        <InputGroup>

                                            <Form.Control
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                className="textinput"/>
                                            <input type="submit" value="Add" className="inputCSS"/>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>)
                }
            </Fragment>
        );
    }
}
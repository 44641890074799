import { RouteToPathWithParams } from './Helper/RouteToPathWithParams';

export const HomePath = '/';
export const AuditPath = '/audit';
export const DashboardPath = '/dashboard';
export const UnauthorisedPath = '/unauthorised';
export const UnprocessedOrdersPath = '/unprocessedorders';
export const AcceptedOrdersPath = '/acceptedorders';
export const RejectedOrdersPath = '/rejectedorders';
export const InvoicesPath = '/invoices';
export const FailedOrdersPath = '/failedorders';
export const FailedInvoicesPath = '/failedinvoices';

export const OutstandingOrdersPath = '/outstandingorders';

export const SettingsPath = '/settings';
export const SettingsUserPath = '/settings/user';

export const AdministrationPath = '/administration';
export const AdministrationPOSpawnerPath = '/administration/pospawner';
export const AdministrationTradingPartnersPath = '/administration/tradingpartners';
export const AdministrationTimeTesterPath = '/administration/timetester';
export const AdmininstrationImportInvoices = '/administration/importinvoices'

export const InvoiceRoutePath = '/invoice/:invoiceId?';
export const OrderRoutePath = '/order/:orderId?';

export const InvoicePath = (invoiceId) => { return RouteToPathWithParams(InvoiceRoutePath, [invoiceId]) };
export const OrderPath = (orderId) => { return RouteToPathWithParams(OrderRoutePath, [orderId]) };

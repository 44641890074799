export const IsDevelopment = () => {
    return process.env.REACT_APP_CURRENTENV === 'Dev';
}

export const IsProduction = () => {
    return process.env.REACT_APP_CURRENTENV === 'Prod';
}

export const IsUat = () => {
    return process.env.REACT_APP_CURRENTENV === 'Uat';
}

export const IsNotProduction = () => {
    return process.env.REACT_APP_CURRENTENV !== 'Prod';
}

export const GetEnvironment = () => {
    return process.env.REACT_APP_CURRENTENV;
}
/* global navigator, Blob,document, URL */
import { ExportTimeZone } from './../../Globals/UserDateTime';

// how to use this
// result = json from a fetch  [{name: bob}, {name: jim}]
// filename = string - 'filename'
// header = string - 'header1,header2,header3...'

export const ExportCSV = (result, fileName, headers) => {

    let csv = convertToCSV(result);

    if (headers) {
        csv = headers + '\r\n' + csv;
    }

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName + '.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

function convertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    var str = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ',';
            if (index.toLowerCase().includes('date')) {
                line += ExportTimeZone(array[i][index]);
            } else {
                line += array[i][index];
            }
        }

        str += line + '\r\n';
    }

    return str;
}
import React from 'react';
import { PieChartOrderSummary, reportName as PieChartOrderSummaryName } from './Reports/PieChartOrderSummaryOption';
import { LiveCSVExport, reportName as LiveCSVExportName } from './Reports/LiveExport';
import { TradingPartnerOrderBar, reportName as TradingPartnerOrderBarName } from './Reports/TradingPartnersOrdersBar';
import { TradingPartnerInvoiceSentBar, reportName as TradingPartnerInvoiceSentBarName } from
    './Reports/TradingPartnersInvoiceSentBar';
import { HealthStatusSummary, reportName as HealthStatusName } from './Reports/HealthStatus';

export const ReportGridList = (props) => {

    if (props && props.grid) {
        const reports = [];

        props.grid.forEach(grid => {
            switch (grid.reportName) {
            case PieChartOrderSummaryName:
                return reports.push(PieChartOrderSummary(grid));
            case LiveCSVExportName:
                return reports.push(LiveCSVExport({ ...grid, tradingPartners: props.tradingPartners }));
            case TradingPartnerOrderBarName:
                return reports.push(TradingPartnerOrderBar(grid));
            case TradingPartnerInvoiceSentBarName:
                return reports.push(TradingPartnerInvoiceSentBar(grid));
            case HealthStatusName:
                return reports.push(HealthStatusSummary(grid));

            // add more reports here
            default:
                return (<div/>);
            }
        });

        return reports;
    }

    return undefined;
};
import React from 'react';
import { Image } from 'react-bootstrap';
import crnLogo from './cnr-logo.png';
import johnDeereLogo from './john-deere-logo.png';
import penskeLogo from './penske-logo.png';
import bnsfLogo from './bnsf-logo.png';

export const GetTradingPartnerLogo = (tpid, alt = 'logo', maxheight, maxwidth) => {
    switch (tpid) {
    case '01030566103':
        return <Image style={{ maxHeight: maxheight, maxWidth: maxwidth }} src={johnDeereLogo} alt={alt}/>;
    case '02CN':
        return <Image style={{ maxHeight: maxheight, maxWidth: maxwidth }} src={crnLogo} alt={alt}/>;
    case 'ZZPENSKE250':
        return <Image style={{ maxHeight: maxheight, maxWidth: maxwidth }} src={penskeLogo} alt={alt}/>;
    case '02BNSF':
        return <Image style={{ maxHeight: maxheight, maxWidth: maxwidth }} src={bnsfLogo} alt={alt}/>;

    default:
        break;
    }
};
import React, { Fragment, useState } from 'react';
import Glyphicon from '@strongdm/glyphicon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const filterOptions = (props) => { // this is to display visible options in the menu

    const idFilters = [];
    for (let i = 0; i < props.filterByIds.length; i++) { // for each filterbyid from database
        if (props.filterByIds[i].type === props.Type) { // if filter type is this block then

            const subfilterIds = props.filterByIds.filter((subfilteredId) => { // find subfilterids from database json

                if (props.filterByIds[i].name === subfilteredId.groupBy
                ) { // if filterbyid name from database is equal to subfilter groupby - this is grouping them together

                    if (props.SelectedFilters.length === 0) {
                        return subfilteredId;
                    } // if selected is empty skip next process

                    let isSelected = false;
                    for (let sf = 0; sf < props.SelectedFilters.length; sf++) { // foreach selected filter 

                        if (props.SelectedFilters[sf].name === `${props.filterByIds[i].name} - All States`) {
                            return [];
                        }; // if selectfilter name = filtername grouping then return no options

                        if (props.SelectedFilters[sf].groupBy === subfilteredId.groupBy
                        ) { // subfilteredId is in the same group as selected filter then check if subfilter is used
                            if (props.SelectedFilters[sf].value === subfilteredId.value &&
                                props.SelectedFilters[sf].name === subfilteredId.name) { // check if used if add to list
                                isSelected = true;
                            }
                        }
                    }

                    // else if not selected then add  
                    if (!isSelected) return subfilteredId;
                }
                return null;
            });

            if (subfilterIds.length !== 0) {

                if (subfilterIds.length > 1) {
                    const selectAllStates = {
                        ...props.filterByIds[i],
                        name: `${props.filterByIds[i].name}`,
                    };
                    subfilterIds.unshift(selectAllStates);
                }

                idFilters.push({ ...props.filterByIds[i], subfilterIds: subfilterIds });
            }

        }
    }
    return idFilters;
};

export default class FilterByIdWithSubIds extends React.Component {
    constructor(props) {
        super(props);
        this.ChangeDropDown = this.ChangeDropDown.bind(this);
        this.AddNewFilter = this.AddNewFilter.bind(this);

        const idFilters = filterOptions(this.props);

        this.state = {
            filterByIdDropDown: false,
            visibleIdOptions: idFilters,
            numberOfFilters: this.props.SelectedFilters.length,
        };
    }

    componentDidUpdate() {

        const idFilters = filterOptions(this.props);

        if (this.props.SelectedFilters.length !== this.state.numberOfFilters) {
            this.setState(() => ({ visibleIdOptions: idFilters, numberOfFilters: this.props.SelectedFilters.length }));
        }
    }

    ChangeDropDown() {
        this.setState(() => ({ filterByIdDropDown: !this.state.filterByIdDropDown }));
    }

    AddNewFilter(filter) {

        this.setState((prevState) => ({
            visibleIdOptions: prevState.visibleIdOptions.filter((Option) => {
                return filter !== Option;
            })
        }));

        this.props.AddToFilter(filter, true);

    }

    render() {
        return (
            <Fragment>
                <div onClick={this.ChangeDropDown} className="filterBarBlock">
                    <span>{this.props.Icon &&
                        <FontAwesomeIcon className="filterIcon" size="lg" icon={this.props.iconType}/>} {
                            this.props.Title}</span>{this.state.filterByIdDropDown
                                ? <Glyphicon style={{ float: 'right' }} glyph="chevron-up"/>
                                : <Glyphicon style={{ float: 'right' }} glyph="chevron-down"/>}
                </div>
                {
                    this.state.filterByIdDropDown &&
                        this.state.visibleIdOptions &&
                        (
                            <div className="filterDropDownBlock">
                                {
                                    this.state.visibleIdOptions.map((visibleOption, key) => (
                                        <FilterByIdOptions
                                            key={key}
                                            filterById={visibleOption}
                                            AddToFilter={this.AddNewFilter}
                                            amountOfFilters={this.state.visibleIdOptions.length - 1}
                                            count={key}/>
                                    ))
                                }
                            </div>
                        )
                }
            </Fragment>
        );
    }
}

const FilterByIdOptions = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const ChangeDropDown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <div className={props.amountOfFilters === props.count
                ? 'subFilterDropDown'
                : 'subFilterDropDown subFilterDropDownSpacer'} onClick={ChangeDropDown}>
                <span style={{ paddingLeft: 10 }}>{props.filterById.name} {isOpen
                    ? <Glyphicon style={{ float: 'right' }} glyph="chevron-up"/>
                    : <Glyphicon style={{ float: 'right' }} glyph="chevron-down"/>}</span>
            </div>

            {isOpen &&
                props.filterById.subfilterIds &&
                props.filterById.subfilterIds.map(
                    (subfilter, key) => <FilterBySubIdOptions key={key} subfilterId={subfilter} AddToFilter={props
                        .AddToFilter}/>)}
        </Fragment>
    );
};

const FilterBySubIdOptions = (props) => {

    return (
        <div className="onClickHoverOver" onClick={() => {
            props.AddToFilter(props.subfilterId, true);
        }}>
            <span style={{ fontSize: 13, padding: '5px 15px 5px 25px', paddingRight: 10 }}>{props.subfilterId.name
            }</span>
        </div>
    );
};
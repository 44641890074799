import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UnauthorisedPath } from '../../Globals/RoutePaths';

const PrivateRoute = ({ component: Component, isAuthorised ,...rest }) => {

    return (      
        <Route {...rest} render={props => (isAuthorised ? <Component {...props} /> : <Redirect to={UnauthorisedPath} />)} />
    );
};

export default PrivateRoute;
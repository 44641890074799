import React from 'react';
import { OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const InfoHoverOver = ({ placement = 'top', size = 'xs', information = 'Add helpful information' }) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip>{information}</Tooltip>}>
            <FontAwesomeIcon className='informationIcon' icon={faInfoCircle} size={size}/>
        </OverlayTrigger>);
};
import BaseApi from './BaseApi';

export async function GetUser(adalUser, timeZone, timeZoneOffSet) {

    return BaseApi(
        'api/User/GetUser',
        'POST',
        {
            Oid: adalUser.oid,
            Email: adalUser.upn,
            FirstName: adalUser.given_name,
            LastName: adalUser.family_name,
            TimeZone: timeZone,
            TimeZoneOffSet: timeZoneOffSet
        });
}

export async function SetPhoneNumber(phoneNumber) {
    return BaseApi(
        `api/User/SetPhoneNumber?phoneNumber=${phoneNumber}`,
        'POST');
}

export async function SetUserTheme(isDark) {
    return BaseApi(
        `api/User/SetUserTheme?isDark=${isDark}`,
        'POST');
}

export async function SetReceiveErrorNotification(isReceiveError) {
    return BaseApi(
        `api/User/SetReceiveErrorNotification?isReceiveError=${isReceiveError}`,
        'POST');
}

export async function SetReceiveUserReport(isReceiveUserReport) {

    return BaseApi(
        `api/User/SetReceiveUserReport?isReceiveUserReport=${isReceiveUserReport}`,
        'POST');
}

export async function SetRoleId(setUserId, setRoleId) {
    return BaseApi(
        `api/User/SetRoleId?setUserId=${setUserId}&setRoleId=${setRoleId}`,
        'POST');
}

export async function SetTimeZone(timeZone) {
    return BaseApi(
        `api/User/SetTimeZone?timeZone=${timeZone.TimeZone}&timeZoneOffSet=${timeZone.TimeZoneOffSet}`,
        'POST');
}

export async function SetUserIsActive(setUserId, isActive) {
    return BaseApi(
        `api/User/SetUserIsActive?setUserId=${setUserId}&isActive=${isActive}`,
        'POST');
}

export async function GetTradingPartnerList() {
    return BaseApi(
        `api/User/GetUserReportSetting`,
        'GET');
}

export async function SetUserReport(tradingPartner, isActive) {

    return BaseApi(
        `api/User/SetUserReport?tradingPartnerId=${tradingPartner}&isActive=${isActive}`,
        'POST'
    );
}

export async function GetUsers() {

    return BaseApi(
        `api/User/GetUsers`,
        'GET'
    );
}

export async function GetRoles() {

    return BaseApi(
        `api/User/GetRoles`,
        'GET'
    );
}

export async function SetUserRole(setUserId, setRoleId) {

    return BaseApi(
        `api/User/SetUserRole?setUserId=${setUserId}&setRoleId=${setRoleId}`,
        'POST'
    );
}

export async function TestUserFunction() {

    return BaseApi('api/User/TestUserFunction', 'GET');
}

export async function GetUserNotifications() {
    return BaseApi('api/Notifications/GetUserNotifications', 'GET');
}

export async function InviteNewUser(email, userName, dateTime) {
    return BaseApi(
        `api/User/InviteUser?email=${email}&userName=${userName}&dateSent=${dateTime
        }`,
        'POST');
}

export async function SetDateHoverOver(isDateHoverOver) {
    return BaseApi(`api/User/SetDateHoverOver?isDateHoverOver=${isDateHoverOver}`, 'POST');
}
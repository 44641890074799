import React from 'react';
import { Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { ProfileRequestAction } from './ReduxStore/UserProfile';
import { PrivateRoute, PublicRoute, AuthenticationValidationRoute, Raygun, RaygunUser, PrivateRoleRoute } from './AppComponents/index';
import { SetTheme } from './Style/Theme';

import {ImportInvoices, Login, Dashboard, Unauthorised, OrdersTable, Order, InvoicesTable, Invoice, Audit, TradingPartnerSettings, FailedTransaction, OutstandingOrders } from './Pages/index';
import UserSettings from './Pages/UserSettings';
import * as PageURLs from './Globals/RoutePaths';
import Layout from './AppComponents/Layout';
import { ProccessCallBack } from './UserAuthentication/AdalAuthentication';



class App extends React.Component {
    constructor(props) {
        super(props);

        Raygun();
        RaygunUser(props.IsAuthenicated, this.props.ADALUser);
        //ClearNotificaitons();
    }

    componentDidMount() {
        this.props.requestUserProfile();
        if (this.props.IsCallback) {
            ProccessCallBack()
        }
    }

    render() {

        return (
            <Layout isAuthorised={this.props.IsAuthenicated} EDIProfile={this.props.EDIProfile} >
                <ToastContainer />
                <SetTheme />
                <Switch>
                    <AuthenticationValidationRoute exact isAuthorised={this.props.IsAuthenicated} isLoading={this.props.IsLoading || this.props.IsCallback} path={PageURLs.HomePath} component={Login} />
                    <PrivateRoute isAuthorised={this.props.IsAuthenicated} EDIProfile={this.props.EDIProfile} path={PageURLs.DashboardPath} component={Dashboard} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.OrderRoutePath} component={Order} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.InvoiceRoutePath} component={Invoice} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.AuditPath} component={Audit} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.OutstandingOrdersPath} component={OutstandingOrders} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{failedTransactions: 'invoice', localStorageReference: 'failedInvoices', emptyDataMessage: 'There are no failed invoices to display'}} restrictedToRoleId={10} EDIProfile={this.props.EDIProfile} path={PageURLs.FailedInvoicesPath} component={FailedTransaction} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{failedTransactions: 'order', localStorageReference: 'failedOrders', emptyDataMessage: 'There are no failed orders to display'}} restrictedToRoleId={10} EDIProfile={this.props.EDIProfile} path={PageURLs.FailedOrdersPath} component={FailedTransaction} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={10} EDIProfile={this.props.EDIProfile} path={PageURLs.AdmininstrationImportInvoices} component={ImportInvoices} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={10} EDIProfile={this.props.EDIProfile} path={PageURLs.AdministrationTradingPartnersPath} component={TradingPartnerSettings} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.SettingsUserPath} component={UserSettings} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{emptyDataMessage: 'There are no unprocessed orders to display', isRejected: false, isAccepted: false, localStorageReference: 'UnprocessedOrders'}} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.UnprocessedOrdersPath} component={OrdersTable} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{emptyDataMessage: 'There are no rejected orders to display', isRejected: true, isAccepted: false, localStorageReference: 'RejectedOrders'}} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.RejectedOrdersPath} component={OrdersTable} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{emptyDataMessage: 'There are no accepted orders to display', isRejected: false, isAccepted: true, localStorageReference: 'AcceptedOrders'}} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.AcceptedOrdersPath} component={OrdersTable} />
                    <PrivateRoleRoute isAuthorised={this.props.IsAuthenicated} componentValues={{emptyDataMessage: 'There are no invoices to display', localStorageReference: 'Invoices'}} restrictedToRoleId={5} EDIProfile={this.props.EDIProfile} path={PageURLs.InvoicesPath} component={InvoicesTable} />
                    <PublicRoute isAuthorised={false} path={PageURLs.UnauthorisedPath} component={Unauthorised} />
                </Switch>
            </Layout>
        )
    }
}

export default connect(
    state => state.Profile,
    dispatch => bindActionCreators(ProfileRequestAction, dispatch)
)(App);
export const RouteToPathWithParams = (URLRoute, params = []) => {
    let urlRouteSplit = URLRoute.split(':')
    let URL = urlRouteSplit[0];


    for (let i = 0; i < params.length; i++) {
        URL = URL + params[i];

        if(i !== urlRouteSplit.length){
            URL = URL + '/';
        } 
    }

    return URL;
}
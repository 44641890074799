import { Modal, Button, Alert, Form } from 'react-bootstrap';
import React, { Component } from 'react';
import { SetOrderIsRejectedById } from './../../FetchApi/Order';

import { CloseButton } from './../Common/index';

//import NoteButton from './AddNote';

export class RejectOrder extends Component {
    constructor(props) {
        super(props);
        this.RejectModal = this.RejectModal.bind(this);
        this.SubmitRejection = this.SubmitRejection.bind(this);
        this.RejectOrder = this.RejectOrder.bind(this);
        this.AllowSave = this.AllowSave.bind(this);

        this.state = {
            isOpen: false,
            internalReference: false,
            allowSave: false
        };

    }

    RejectOrder(comment, internalNoteText) {
        //let noteCode = 'RJT'
        //if (!this.props.IsReceivesRejects) 
        const noteCode = 'RJT-INT';

        const param = {
            orderId: this.props.orderId,
            noteText: comment,
            noteCode: noteCode,
            internalNoteText: internalNoteText,
            userId: this.props.UserId
        };

        if (this.state.allowSave) {
            SetOrderIsRejectedById(param).then((result) => {
                if(result) this.props.history.push('/unprocessedorders');
            });

        };
    };

    RejectModal() {
        this.setState(() => ({ isOpen: !this.state.isOpen }));
    }

    SubmitRejection(e) {
        e.preventDefault();

        const requestComment = e.target.RejectComment.value.trim();
        let addNote = null;

        if (e.target.addNote && e.target.addNote.value) {
            addNote = e.target.addNote.value;
        }

        this.RejectModal();

        this.RejectOrder(requestComment, addNote);

    }

    AddInternalReference() {
        this.setState(() => ({ internalReference: !this.state.internalReference }));
    }

    AllowSave(e) {
        e.preventDefault();
        const requestComment = e.target.value.trim();

        if (requestComment) {
            if (!this.state.allowSave) {
                this.setState(() => ({ allowSave: true }));
            }
        } else {
            if (this.state.allowSave) {
                this.setState(() => ({ allowSave: false }));
            }
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'center', paddingTop: 10 }}>
                <CloseButton contents='Reject Order' onClickFunction={this.RejectModal} />

                <Modal show={this.state.isOpen} onHide={this.RejectModal} animation={false} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>REJECT ORDER</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.SubmitRejection}>
                            {
                                !this.props.IsReceivesRejects &&
                                <Alert variant="danger">{this.props.tradingPartnerName
                                } does not have the ability to receive rejections. <br />The rejection comment will be emailed to the trading partners designated email address. <br />This comment will be used as an internal reference.</Alert>
                            }
                            {
                                this.props.IsReceivesRejects &&
                                <Alert variant="success">{this.props.tradingPartnerName
                                } will receive this the rejection.<br />This comment will be used as an internal reference.</Alert>
                            }
                            <h5>Rejection Comment</h5>
                            <Form.Group controlId="formRejectComment">
                                <Form.Control onChange={this.AllowSave} as="textarea" rows="4" name="RejectComment" />
                                <Form.Text className="text-muted">Please leave a comment, as a reference for the course of action conducted.</Form.Text>
                            </Form.Group>
                            {
                                //this.props.IsReceivesRejects && <NoteButton title='Internal Reference' DeleteText='Delete Internal Reference' AddText='Add Internal Reference' />
                            }
                            <Modal.Footer>

                                <Button variant="success" disabled={!this.state.allowSave} type="submit" value="Submit">Reject</Button>
                                <Button variant='danger' onClick={this.RejectModal}>Close</Button>
                            </Modal.Footer>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    };
};
import React, { useState, useEffect,Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export const CopyToClipboard = ({ textToCopy, size = 'sm' }) => {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        if(isCopied){
            setTimeout(function(){ setIsCopied(false); }, 5000);
        };
    });

    const onCopy = () => {
        CopyToClipboardFunction(textToCopy);
        setIsCopied(true);
    }

    return (document.queryCommandSupported('copy') ? 
        <div className='copiedContainer'>
            <FontAwesomeIcon icon={faCopy} size={size} onClick={onCopy} className='copyIcon' />
            {isCopied ? <span className='copied copyFadeOut'>Copied!</span> : <span className='copied'></span>}
        </div> 
        : <Fragment />)
}

const CopyToClipboardFunction = (text = 'Copy Me') => {
    var copyText = document.createElement("textarea");
    document.body.appendChild(copyText);
    copyText.value = text;
    copyText.select();
    document.execCommand("copy");
    document.body.removeChild(copyText);
}

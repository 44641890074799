/* global  window, process */

import { adalInstance } from './AdalInstance';
import { CreateUserHash, GetUserHash, DeleteEDIUserLocal } from '../Globals/LocalStorage';

export const ProcessCallbackAuthentication = () => {
    if (adalInstance.isCallback(window.location.hash)) {
        CreateUserHash();
        adalInstance.handleWindowCallback();
    }
};

export const GetAdalCachedUser = () => {
    return adalInstance.getCachedUser();
};

export const GetCachedToken = () => {
    //Todo
    //add if bearer is num revalidate token here
    return adalInstance.getCachedToken(process.env.REACT_APP_AdalClientId);
};

export const LoginAdalUser = () => {
    adalInstance.login();
};

export const LogoutAdalUser = () => {
    DeleteEDIUserLocal();
    adalInstance.logOut();
};

const RevalidateBearer = () => {
    adalInstance.handleWindowCallback(GetUserHash());
};

export const GetAdalBearerToken = () => {
    let bearer = adalInstance.getCachedToken(process.env.REACT_APP_AdalClientId);
    if (bearer) return bearer;

    RevalidateBearer();
    /// ToDo test the revalidate
    return adalInstance.getCachedToken(process.env.REACT_APP_AdalClientId);
};

export function IsAuthCalledBack() {
    const hash = window.location.hash;
    if(hash === '') return false;

    return adalInstance.isCallback(hash);
};

export function ProccessCallBack(){

    if(IsAuthCalledBack()) {
        adalInstance.handleWindowCallback(window.location.hash);
    }

}


//remove in the future

export function newToken() {

    //adalInstance.handleWindowCallback();

    if (adalInstance.isCallback(window.location.hash)) {
        // This happens after the AD login screen,
        // handleWindowCallback will use the hash to
        // complete the login
        adalInstance.handleWindowCallback();

        return true;
    }

}

// export function authenticateToken() {
//     const user = adalInstance.getCachedUser();
//     const bearer = adalInstance.getCachedToken(process.env.REACT_APP_AdalClientId);

//     if (user && bearer) {
//         return true;
//     }

//     if (adalInstance.isCallback(window.location.hash)) {
//         // This happens after the AD login screen,
//         // handleWindowCallback will use the hash to
//         // complete the login
//         adalInstance.handleWindowCallback();

//         return true;
//     }

//     if (user && !bearer) newToken();

//     // Not logged in
//     return false;
// }

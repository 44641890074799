import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { NavMenu } from '../components/NavMenu';

export default function Layout(props) {

    return (
        <Container fluid>
            {props.isAuthorised ? <NavMenu EDIProfile={props.EDIProfile} /> : <Fragment />}
            <div>
                {props.children}
            </div>
        </Container>
    );
}
import React, { Component, useState } from 'react';
import { GetOrder } from '../FetchApi/Order';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Loading } from './../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShippingFast, faEye, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { TimeAgoString, IconIndecatorWithHoverOver, CopyToClipboard, ReturnDollarValueWithCommon } from './../components/Common/index';
import { GeneralSection, ContactSection, AddressSection, ReferencesSection, NotesSection, FobSection, CarrierSection, LineItemsData, LineItemsHeaderList, IsPurchaseOrderNumberExistInSAMHoverOverMessage } from './../components/Order/OrderContents';
import { PurchasesTable } from './../components/Table/index';
import { InformationBlock } from './../components/DisplayBlocks';

import { AcceptOrder } from './../components/Order/AcceptOrders';
import { RejectOrder } from './../components/Order/RejectOrders';
import { OrderOptions } from './../components/Order/OrderOptions';


export default class Order extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: undefined
        };
    };

    componentDidMount() {
        GetOrder(this.props.match.params.orderId).then((results) => this.setState({ order: results }));
    };

    render() {
        return (this.state.order ?
            <Container fluid className='purchaseContainer'>
                <OrderHeader {...this.state.order} userId={this.props.ediProfile.id}  history={this.props.history}/>
                <OrderBody {...this.state.order} />
                <OrderLineItems lineItems={this.state.order.lineItems} isLoading={this.state.order ? false : true} totalAmount={this.state.order.totalAmount.toFixed(2)}/>
            </Container>
            :
            <Loading />
        );
    };
};

const OrderHeader = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Row>
            <Col lg={6} className='ColumnPadding'>
                <div style={{ display: 'inline-block' }}>
                    <h2 className='headerTitle'>Purchase Order: {props.purchaseOrderNumber}</h2><CopyToClipboard textToCopy={props.purchaseOrderNumber} />
                </div>
                <div>
                    <p className='headerSubTitle'>SAM Account: {props.accountNumber}</p>
                    <CopyToClipboard textToCopy={props.accountNumber} />
                </div>
                <div>
                    <p className='headerSubTitle'>Company Name: {props.companyName}</p>
                    <CopyToClipboard textToCopy={props.companyName} />
                </div>
                <div>
                    <IconIndecatorWithHoverOver
                        displayIfTrue={props.isPurchaseOrderNumberExistInSAM}
                        icon={faExclamationTriangle}
                        className='informationIcon'
                        hoverOverMessage={IsPurchaseOrderNumberExistInSAMHoverOverMessage()} />

                    <IconIndecatorWithHoverOver
                        displayIfTrue={props.isUrgent}
                        icon={faShippingFast}
                        className='informationIcon'
                        hoverOverMessage={'Urgent Order'} />

                    <IconIndecatorWithHoverOver
                        displayIfTrue={props.lastAccessedByUser}
                        icon={faEye}
                        className='informationIcon'
                        hoverOverMessage={`${props.lastAccessedByUser} visited this purchase order ${TimeAgoString(props.lastAccessedDateTime)}`} />
                </div>
            </Col>
            <Col lg={6} className='ColumnPadding'>
                <div className='toolBlock'>
                    <Button style={{ width: '98.5%', paddingTop: 7, paddingBottom: 7 }} onClick={() => setIsOpen(!isOpen)} ><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size='lg' /></Button>
                    {isOpen ?
                        <OrderOptions {...props}/>
                        :
                        <AcceptOrRejectOptions
                            tradingPartnerId={props.tradingPartnerId}
                            tradingPartnerName={props.tradingPartnerName}
                            isUrgent={props.isUrgent}
                            isAccepted={props.isAccepted}
                            isRejected={props.isRejected}
                            purchaseOrderNumber={props.purchaseOrderNumber}
                            totalAmount={props.totalAmount}
                            orderId={props.id}
                            isReceivesRejects={props.isReceivesRejects}
                            history={props.history}
                        />
                    }
                </div>
            </Col>
        </Row>);
};

const OrderBody = (props) => {
    return (
        <Row>
            <InformationBlock {...GeneralSection(props)} />
            <InformationBlock {...ContactSection(props.contacts)} />
            <InformationBlock {...AddressSection(props.addresses, 'ST', 'Ship To')} />
            <InformationBlock {...AddressSection(props.addresses, 'BY', 'Bill To', 'BT')} />
            <InformationBlock {...ReferencesSection(props.references)} />
            <InformationBlock {...NotesSection(props.notes)} />
            <InformationBlock {...FobSection(props.fobRelatedInstructions)} />
            <InformationBlock {...CarrierSection(props.carrierInfos)} />
        </Row>
    );
};

const OrderLineItems = (props) => {
    return (<Row>
        <Col lg={12} className='ColumnPadding'>
            <div className='detailBlock'>
                <h5>Line Items</h5>
                <div style={{ padding: 10 }}>
                    <PurchasesTable
                        data={LineItemsData(props.lineItems)}
                        headers={LineItemsHeaderList}
                        emptyDataMessage={'There are no lineitems in this purchase order'}
                        isLoading={props.isLoading}
                    />
                </div>
                <div style={{ float: 'right', padding: 15 }}>
                    <h4>Total: {ReturnDollarValueWithCommon(props.totalAmount)}</h4>
                </div>
            </div>
        </Col>
    </Row>)
}

const AcceptOrRejectOptions = ({ isAccepted, isRejected, purchaseOrderNumber, tradingPartnerId, isUrgent, totalAmount, orderId, isReceivesRejects, history, tradingPartnerName }) => {
    if (isAccepted) return <div style={{ paddingTop: 15 }}><h1 className="AcceptedOrderBanner">Accepted Order</h1></div>;
    if (isRejected) return <div style={{ paddingTop: 15 }}><h1 className="RejectedOrderBanner">Rejected Order</h1></div>;

    return (
        <Row>
            <Col sm={6}><AcceptOrder history={history} orderId={orderId} purchaseOrderNumber={purchaseOrderNumber} tradingPartnerId={tradingPartnerId} isUrgent={isUrgent} totalAmount={totalAmount}/></Col>
            <Col sm={6}><RejectOrder tradingPartnerName={tradingPartnerName} history={history} orderId={orderId} isReceivesRejects={isReceivesRejects} tradingPartnerId={tradingPartnerId} /></Col>
        </Row>);
};

import React, { Component, Fragment } from 'react';
import { GetFailedTransactionList } from './../FetchApi/FailedTransaction';
import { Table, Spinner } from 'react-bootstrap';
import { DefaultModalIconWrapper, ContentBlocks } from './../components/Common/DefaultModal';
import BaseApi from '../FetchApi/BaseApi';
import { faEye, faSync, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayUserDateTime } from '../Globals/UserDateTime';
import { CreatePagination } from './../components/Table/Pagination';
import { SuccessToastR, ErrorToastR } from './../Globals/ToastrReponses';

export default class FailedTransaction extends Component {
    //transactionType: order, invoice

    constructor(props) {
        super(props);
        this.reProcess = this.reProcess.bind(this);
        this.UpdateTableData = this.UpdateTableData.bind(this);
        this.UpdatePageNumber = this.UpdatePageNumber.bind(this);

        this.state = {
            failedTransactions: undefined,
            pageNumber: 1,
            pageSize: 20,
            detailsIsOpen: false,
            isLoading: false
        };

    }

    componentDidMount() {
        const param = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            transactionType: this.props.failedTransactions
        };

        GetFailedTransactionList(param).then((result) => this.setState(() => ({ failedTransactions: result })));
    }

    UpdateTableData(filters, pageNumber, pageSize) {

        const param = {
            pageNumber: pageNumber ? pageNumber : this.state.pageNumber,
            pageSize: pageSize ? pageSize : this.state.pageSize,
            transactionType: this.props.transactionType
        };

        GetFailedTransactionList(param).then((result) => this.setState(() => ({ failedTransactions: result, isLoading: false })));
    }

    UpdatePageNumber(filters, pageNumber, pageSize) {
        this.setState(() => ({ pageNumber: pageNumber }));
        this.UpdateTableData(null, pageNumber, pageSize);

    }



    reProcess(transaction) {
        this.setState({ isLoading: true });

        const reprocessed = (r) => {
            if (r.result) {
                this.UpdateTableData();
                SuccessToastR('Successfully reprocessed');
            } else {
                this.setState({ isLoading: false })
                ErrorToastR('Check server logs for reason of fail - Id' + transaction);
            }
        }

        BaseApi(
            `api/FailedTransaction/Reprocess?failedTransactionId=${transaction.id}`,
            'POST'
        ).then(r => reprocessed(r));
    }

    render() {
        if (this.state.failedTransactions) {
            return (
                <Fragment>

                    {
                        this.state.failedTransactions.length === 0 &&
                        <div>{this.props.emptyDataMessage}</div>
                    }
                    {
                        this.state.isLoading &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading Details...</span>
                        </Spinner>
                    }
                    {
                        this.state.failedTransactions.length > 0 &&
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Failed Date</th>
                                    <th>File Name</th>
                                    <th>Exception Message</th>
                                    <th>Details</th>
                                    <th>Download File</th>
                                    {this.props.ediProfile.roleId > 9 && <th>Re-process</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.failedTransactions.map((transaction, i) => {
                                    return (
                                        [
                                            <tr key={transaction.id}>
                                                <td>{DisplayUserDateTime(transaction.failedDate)}</td>
                                                <td>{transaction.blobFileName}</td>
                                                <td>{transaction.exceptionMessage === 'Unspecified error - exception was null' ? transaction.exceptionInnerException : transaction.exceptionMessage}</td>
                                                <td>
                                                    <DefaultModalIconWrapper
                                                        icon={faEye}
                                                        size="xl"
                                                        modalTitle="Exception Details"
                                                        modalBody={<Fragment>
                                                            <ContentBlocks title="Message" content={transaction.exceptionMessage} />
                                                            <hr />
                                                            <ContentBlocks title="Inner Exception" content={transaction.exceptionInnerException} />
                                                            <hr />
                                                            <ContentBlocks title="Stack Trace" content={transaction.exceptionStackTrace} />
                                                        </Fragment>}
                                                    />
                                                </td>

                                                <td>
                                                    <a href={transaction.downloadURL}>
                                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faFileDownload} />
                                                    </a>
                                                </td>
                                                {this.props.ediProfile.roleId > 9 &&
                                                    <td>
                                                        <span onClick={() => (this.reProcess(transaction))
                                                        }>
                                                            <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faSync} />
                                                        </span>
                                                    </td>}
                                            </tr>
                                        ]
                                    );
                                }
                                )}
                            </tbody>
                        </Table>
                    }
                    {
                        this.state.failedTransactions &&
                        this.state.failedTransactions.length > 0 &&
                        CreatePagination(this.state.failedTransactions[0].totalCount,
                            this.state.pageSize,
                            this.state.pageNumber,
                            null,
                            this.UpdatePageNumber)
                    }
                </Fragment>
            );
        } else {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading Details...</span>
                </Spinner>
            )
        }
    }
}

// export default connect(
//     state => ({...state.failedOrders, ...state.Profile}),
//     dispatch => bindActionCreators(actionCreators, dispatch)
// )(FailedOrders);
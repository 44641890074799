import React, { Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as moment from 'moment-timezone';
import { GetEDIUserLocal } from './../Globals/LocalStorage';

const DisplayDateTimeWithHover = (dateTime, userDateTime) => {

    return (
        <Fragment>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="tooltip-UserDateTime">{dateTime}</Tooltip>
                }>
                <span>{userDateTime}</span>
            </OverlayTrigger>
        </Fragment>
    );
};

export const ConvertToJSDate = (dateTime) => {

    if(!dateTime) return null;

    //const userDateTime = GetUserDateTime(datetime, true, true)
    //let dateFormatWithoutUTC = moment(userDateTime).format("YYYY-MM-DD HH:mm:ss");
    let dateFormatWithoutUTC = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    return new Date(dateFormatWithoutUTC);
};

export const ConvertToApiFriendlyDateTime = (dateTime) => {
    if(!dateTime) return null;

    //const EDIProfile = GetEDIUserLocal();
    let dateFormatWithoutUTC = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    //let createDateWithUserUTC = moment.tz(dateFormatWithoutUTC, EDIProfile.timeZone);
    //let createDateWithUserUTC = moment.tz(dateFormatWithoutUTC, EDIProfile.timeZone);
    //let dateTimeUTC = createDateWithUserUTC.utc().format();

    return dateFormatWithoutUTC;
};

export const ExportTimeZone = (dateTime) => {
    const EDIProfile = GetEDIUserLocal();
    const momentDate = moment(dateTime);
    const momenttzConvert = momentDate.tz(EDIProfile.timeZone);
    return ShowTime(momenttzConvert, true);
};

const ShowTime = (dateTime, showTime, isHoverOver) => {

    if (isHoverOver) return dateTime.format('L LT Z');

    if (showTime) {
        return dateTime.format('L LT');
    } else {
        return dateTime.format('L');
    };
};

export const DisplayUserDateTime = (dateTime, showTime, forceNoHoverOver) => {

    if (!dateTime || dateTime === '0001-01-01T00:00:00') return null;

    const EDIProfile = GetEDIUserLocal();

    let momentConvert = undefined;
    const momentDate = moment(dateTime);

    if(typeof dateTime === 'string' && dateTime.includes('Z')){
        momentConvert = moment.tz(dateTime, EDIProfile.timeZone);
    } else {
        momentConvert = moment.utc(dateTime).tz(EDIProfile.timeZone);
    };

    if (forceNoHoverOver) return ShowTime(momentConvert, showTime);

    if (EDIProfile.isDateHoverOver) {
        return DisplayDateTimeWithHover(ShowTime(moment(dateTime), true, true), ShowTime(momentDate, showTime));
    } else {
        return ShowTime(momentConvert, showTime);
    };

};
/* global process, window */
import { AuthenticationContext } from 'react-adal';
//import { CallbackPath } from './../Globals/RoutePaths';

export const adalInstance = new AuthenticationContext({
    tenant: 'alsglobal.onmicrosoft.com',
    clientId: process.env.REACT_APP_AdalClientId,
    endpoints: {
        api: `https://login.microsoftonline.com/${process.env.REACT_APP_AdalClientId}`
    },
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'sessionStorage'
});

const CreateOrderSqlParams = (filters, isAccepted, isRejected, pageNumber, pageSize) => {

    let sqlmodel = {
        IsAccepted: isAccepted,
        IsRejected: isRejected,
        PageNumber: parseInt(pageNumber),
        PageSize: pageSize,
        PoNumber: null,
        TradingPartnerIds: null,
        CountryIds: null,
        StateIds: null,
        ReceivedStartDate: null,
        ReceivedEndDate: null,
        PoStartDate: null,
        PoEndDate: null,
        AcknowledgementSentStartDate: null,
        AcknowledgementSentEndDate: null,
        IsUrgent: null,
        AccountNumbers: null
    };

    if (filters.length > 0) {
        for (let i = 0; i < filters.length; i++) {
            sqlmodel = {
                IsAccepted: isAccepted,
                IsRejected: isRejected,
                PageNumber: parseInt(pageNumber),
                PageSize: pageSize,
                PoNumber: filters[i].type === 'poSearch' ? filters[i].value : sqlmodel.PoNumber,
                TradingPartnerIds: filters[i].type === 'tradingPartners'
                    ? sqlmodel.TradingPartnerIds
                        ? `${sqlmodel.TradingPartnerIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.TradingPartnerIds,
                CountryIds: filters[i].type === 'country'
                    ? sqlmodel.CountryIds
                        ? `${sqlmodel.CountryIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.CountryIds,
                StateIds: filters[i].type === 'state'
                    ? sqlmodel.StateIds
                        ? `${sqlmodel.StateIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.StateIds,
                ReceivedStartDate: filters[i].type === 'receivedDate'
                    ? filters[i].value[0]
                    : sqlmodel.ReceivedStartDate,
                ReceivedEndDate: filters[i].type === 'receivedDate' ? filters[i].value[1] : sqlmodel.ReceivedEndDate,
                PoStartDate: filters[i].type === 'poDate' ? filters[i].value[0] : sqlmodel.PoStartDate,
                PoEndDate: filters[i].type === 'poDate' ? filters[i].value[1] : sqlmodel.PoEndDate,
                AcknowledgementSentStartDate: filters[i].type === 'acknowledgementSentDate'
                    ? filters[i].value[0]
                    : sqlmodel.AcknowledgementSentStartDate,
                AcknowledgementSentEndDate: filters[i].type === 'acknowledgementSentDate'
                    ? filters[i].value[1]
                    : sqlmodel.AcknowledgementSentEndDate,
                IsUrgent: filters[i].type === 'isUrgent' ? filters[i].value : sqlmodel.IsUrgent,
                AccountNumbers: filters[i].type === 'accountNumbers'
                    ? sqlmodel.AccountNumbers
                        ? `${sqlmodel.AccountNumbers},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.AccountNumbers,
            };

        }
    }

    return sqlmodel;
};

const CreateInvoiceSqlParams = (filters, pageNumber, pageSize) => {

    let sqlmodel = {
        PageNumber: parseInt(pageNumber),
        PageSize: pageSize,
        PoNumber: null,
        InvoiceTypes: null,
        TradingPartnerIds: null,
        InvoiceNumber: null,
        InvoiceStartDate: null,
        InvoiceEndDate: null,
        AcknowledgementSentStartDate: null,
        AcknowledgementSentEndDate: null,
        IsUrgent: null,
        AccountNumbers: null,
        StateIds: null,
        CountryIds: null
    };

    if (filters.length >= 1) {
        for (let i = 0; i < filters.length; i++) {
            sqlmodel = {
                PageNumber: parseInt(pageNumber),
                PageSize: pageSize,
                PoNumber: filters[i].type === 'poNumber' ? filters[i].value : sqlmodel.PoNumber,
                InvoiceTypes: filters[i].type === 'invoiceTypeCode' ? filters[i].value : sqlmodel.InvoiceTypes,
                TradingPartnerIds: filters[i].type === 'tradingPartners'
                    ? sqlmodel.TradingPartnerIds
                        ? `${sqlmodel.TradingPartnerIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.TradingPartnerIds,
                InvoiceNumber: filters[i].type === 'invoiceNumber' ? filters[i].value : sqlmodel.InvoiceNumber,
                InvoiceStartDate: filters[i].type === 'invoiceDate'
                    ? filters[i].value[0]
                    : sqlmodel.InvoiceStartDate,
                InvoiceEndDate: filters[i].type === 'invoiceDate'
                    ? filters[i].value[1]
                    : sqlmodel.InvoiceEndDate,
                AcknowledgementSentStartDate: filters[i].type === 'acknowledgementSentDate'
                    ? filters[i].value[0]
                    : sqlmodel.AcknowledgementSentStartDate,
                AcknowledgementSentEndDate: filters[i].type === 'acknowledgementSentDate'
                    ? filters[i].value[1]
                    : sqlmodel.AcknowledgementSentEndDate,
                IsUrgent: filters[i].type === 'isUrgent' ? filters[i].value : sqlmodel.IsUrgent,
                AccountNumbers: filters[i].type === 'accountNumbers'
                    ? sqlmodel.AccountNumbers
                        ? `${sqlmodel.AccountNumbers},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.AccountNumbers,
                StateIds: filters[i].type === 'state'
                    ? sqlmodel.StateIds
                        ? `${sqlmodel.StateIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.StateIds,
                CountryIds: filters[i].type === 'country'
                    ? sqlmodel.CountryIds
                        ? `${sqlmodel.CountryIds},${filters[i].value}`
                        : filters[i].value
                    : sqlmodel.CountryIds,
            };
        }
    }

    return sqlmodel;
};

const CreateAuditSqlParms = (filters, pageNumber, pageSize) => {

    let sqlmodel = {
        PageNumber: parseInt(pageNumber),
        PageSize: pageSize,
        EventStartDate: null,
        EventEndDate: null,
        UserIds: null,
        OrderIds: null,
        OrderAckIds: null,
        InvoiceIds: null,
        IncludeSystemUserEvents: null,
        EventSource: null,
        EventType: null,
    };

    if (filters.length >= 1) {
        for (let i = 0; i < filters.length; i++) {
            sqlmodel = {
                PageNumber: parseInt(pageNumber),
                PageSize: pageSize,
                EventSource: filters[i].type === 'eventSource' ? filters[i].value : sqlmodel.EventSource,
                EventType: filters[i].type === 'eventType' ? filters[i].value : sqlmodel.EventType,
                IncludeSystemUserEvents: filters[i].type === 'includeSystemUserEvents'  ? filters[i].value   : sqlmodel.IncludeSystemUserEvents,
                UserIds: filters[i].type === 'username' ? sqlmodel.UserIds ? `${sqlmodel.UserIds},${filters[i].value}` : filters[i].value : sqlmodel.UserIds,
                OrderIds: filters[i].type === 'purchaseOrderNumbers' ? filters[i].value : sqlmodel.OrderIds,
                EventStartDate: filters[i].type === 'Event' ? filters[i].value[0] : sqlmodel.EventStartDate,
                EventEndDate: filters[i].type === 'Event' ? filters[i].value[1] : sqlmodel.EventEndDate,
                InvoiceIds: filters[i].type === 'invoicenumber' ? filters[i].value : sqlmodel.InvoiceIds,
            };
        }
    }

    return sqlmodel;
};

export { CreateInvoiceSqlParams, CreateOrderSqlParams, CreateAuditSqlParms };
import React from 'react';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import ReportOptionTemplate from '../ReportOptionTemplate';
import { LiveExport } from '../../Reports/LiveExportReport';

export const reportName = 'LiveExport';

export const LiveExportOption = (props) => {
    // all values will create boxes based on their type
    const queryOptions = [];

    // ReportOptionTemplate - reportName, title, summary, fetchAPI (Report/{fetchAPI name}),queryOptions (fetchAPI params), icon (FontAwsomeIcon), props (populate new queryOption values from database if any)
    return ReportOptionTemplate(reportName,
        'CSV Summary Data Export',
        'Export data a summary into a csv with filters',
        'GetLiveExport',
        queryOptions,
        faFileCsv,
        props);
};

export const LiveCSVExport = (props) => {
    return (
        <LiveExport {...props} title="Inbound Orders" subtext="Amount of processed and unprocessed orders" titlePosition="left"/>
    );
};
import React, { Fragment } from 'react';
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isEmpty } from './../Globals/FunctionsTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InformationBlock = ({ heading = 'heading', detailRow = [], size = 3, functionOptions }) => {
    return (
        <Col lg={size} className='ColumnPadding'>
            <div className='detailBlock'>
                <h5>{heading}</h5>
                {functionOptions}
                <Container className='detailContainer' fluid>
                    {detailRow.length > 0 && detailRow.map((detail, key) => <DetailRow key={key} {...detail} />)}
                </Container>
            </div>
        </Col>
    );
};

export const DetailRow = (props) => {
    if (props.hideIfEmpty && isEmpty(props.value)) return <Fragment />;
    const labelIsEmpty = isEmpty(props.label);
    const size = labelIsEmpty ? 12 : 6;

    return (
        <Row key={props.label} className={'detailRow detailContent'} >
            {!labelIsEmpty &&
                <Col sm={size}>
                    {props.label}
                </Col>}
            <Col sm={size}>
                {props.value}
            </Col>
        </Row>
    );
};


export const OptionsIcons = (icon, tooltip, onClickFunction, extraClassName) => {
    return (
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
            <div className='OptionIcon' onClick={onClickFunction}>
                <FontAwesomeIcon className={extraClassName} icon={icon} size="3x" style={{width: '100%'}}/>
            </div>
        </OverlayTrigger>
    );
};

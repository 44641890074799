import React from 'react';

const ToggleSwitch = (props) => {
    const disabled = props.disabled ? props.disabled : false;

    const defaultChecked = props.defaultChecked ? props.defaultChecked : false;

    const id = props.id ? props.id.replace(/\s/g, '') : 'defaultToggle';
    const className = props.className ? props.className : 'defaultToggleSwitch';

    const checkedName = props.checkedName ? props.checkedName : 'ON';
    const checkedClassName = props.checkedClassName ? props.checkedClassName : 'on';

    const uncheckedName = props.uncheckedName ? props.uncheckedName : 'OFF';
    const uncheckedClassName = props.uncheckedClassName ? props.uncheckedClassName : 'off';

    return (
        <div className={className}>
            { props.label && <p>{props.label}</p>}
            <label className="switch">
                <input
                    type="checkbox"
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    onChange={props.onChange}
                    id={id}/>
                <div className={disabled ? "disabledslider slider round" : "slider round" }>
                    <span className={checkedClassName}>{checkedName}</span>
                    <span className={uncheckedClassName}>{uncheckedName}</span>
                </div>
            </label>
        </div>
    );
};

export default ToggleSwitch;
import React, { Fragment } from 'react';
import Glyphicon from '@strongdm/glyphicon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const filterOptions = (props) => {

    let idFilters = props.filterByIds.filter((filteredId) => {
        if (filteredId.type === props.Type) {
            return filteredId;
        }
        return null;
    });

    if (props.filterByIds) {
        if (props.SelectedFilters.length > 0) {
            for (let i = 0; i < props.SelectedFilters.length; i++) {

                idFilters = idFilters.filter((filter) => {
                    if (props.SelectedFilters[i].value !== filter.value &&
                        props.SelectedFilters[i].name !== filter.name) {
                        return filter;
                    }
                    return null;
                });
            }
        }
    }

    if (props.filterByIds) {
        for (let i = 0; i < props.SelectedFilters.length; i++) {

            idFilters = idFilters.filter((id) => {
                return props.SelectedFilters[i] !== id;
            });
        }
    }
    return idFilters;
};

export default class FilterById extends React.Component {
    constructor(props) {
        super(props);
        this.ChangeDropDown = this.ChangeDropDown.bind(this);
        this.AddNewFilter = this.AddNewFilter.bind(this);
        let idFilters = [];
        if (this.props.selectOne) {

            let hasSelectedFilter = false;
            for (let i = 0; i < this.props.SelectedFilters.length; i++) {
                if (this.props.SelectedFilters[i].typeName === this.props.TypeName) {
                    hasSelectedFilter = true;
                }
            }

            if (!hasSelectedFilter) {
                idFilters = this.props.filterByIds;
            }

        } else {
            idFilters = filterOptions(this.props);
        }

        this.state = {
            filterByIdDropDown: false,
            visibleIdOptions: idFilters,
            numberOfFilters: this.props.SelectedFilters.length
        };

    }

    componentDidUpdate() {
        let idFilters = [];

        if (this.props.selectOne) {

            let hasSelectedFilter = false;
            for (let i = 0; i < this.props.SelectedFilters.length; i++) {
                if (this.props.SelectedFilters[i].typeName === this.props.TypeName) {
                    hasSelectedFilter = true;
                }
            }

            if (!hasSelectedFilter) {
                idFilters = this.props.filterByIds;
            }

        } else {
            idFilters = filterOptions(this.props);
        }

        if (this.props.SelectedFilters.length !== this.state.numberOfFilters) {
            this.setState(() => ({ visibleIdOptions: idFilters, numberOfFilters: this.props.SelectedFilters.length }));
        }
    }

    ChangeDropDown() {
        this.setState(() => ({ filterByIdDropDown: !this.state.filterByIdDropDown }));
    }

    AddNewFilter(filter) {
        this.setState((prevState) => ({
            visibleIdOptions: prevState.visibleIdOptions.filter((Option) => {
                return filter !== Option;
            })
        }));
        this.props.AddToFilter(filter, true);
    }

    render() {
        return (
            <Fragment>
                <div onClick={this.ChangeDropDown} className={this.props.isLastBlock
                    ? 'filterBaLastBlock'
                    : 'filterBarBlock'}>
                    <span>{this.props.iconType &&
                        <FontAwesomeIcon className="filterIcon" size="lg" icon={this.props.iconType}/>} {
                            this.props.Title}</span>{this.state.filterByIdDropDown
                                ? <Glyphicon style={{ float: 'right' }} glyph="chevron-up"/>
                                : <Glyphicon style={{ float: 'right' }} glyph="chevron-down"/>}
                </div>
                {
                    this.state.filterByIdDropDown &&
                        this.state.visibleIdOptions &&
                        (
                            <div className="filterDropDownBlock">
                                {
                                    this.state.visibleIdOptions.map((visibleOption, key) => (
                                        <FilterByIdOptions
                                            key={key}
                                            filterById={visibleOption}
                                            AddToFilter={this.AddNewFilter}/>
                                    ))
                                }
                            </div>
                        )
                }
            </Fragment>
        );
    }
}

const FilterByIdOptions = (props) => {
    return (
        <div className="onClickHoverOver" onClick={(e) => {
            props.AddToFilter(props.filterById, true);
        }}>
            <span className="filterItem">{props.filterById.name}</span>
        </div>
    );
};

export { FilterByIdOptions };
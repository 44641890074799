import React from 'react';
import DatePicker from 'react-date-picker';
import { ConvertToJSDate } from './../../Globals/UserDateTime';

export const UserDatePicker = ({dateTime = null, onChangeFunction, disabled = false, minDate = null}) => {

    if(typeof dateTime === 'string') dateTime = ConvertToJSDate(dateTime);

    const ConvertToUserDateTime = (newDateTime) => {

        const newDate = ConvertToJSDate(newDateTime);
        onChangeFunction(newDate);
    }

    return (<DatePicker
        showLeadingZeros={true}
        onChange={ConvertToUserDateTime}
        value={dateTime}
        disabled={disabled}
        minDate={minDate} />)
}

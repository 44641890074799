import React, { useRef } from 'react';
import { Form, InputGroup, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InfoHoverOver } from '../Common/InfoHoverOver';

export const QuickSearch = ({ updateFunction, refreshFiltersAndSortOrder, searchValues = [] ,isLoading = false, label = 'label the input', placeholder='placeholder' }) => {
    const inputValue = useRef(null);
    if(isLoading) return <div />;

    const onSubmitAction = (e) => {
        e.preventDefault();
        let searchValue = inputValue.current.value.trim();

        if (searchValue !== '') {
            searchValues[0]['name'] = searchValue;
            searchValues[0]['value'] = searchValue;

            updateFunction(searchValues, null, 1, 20)
        } else {
            refreshFiltersAndSortOrder();
        };
    };

    const clearsearch = () => {
        inputValue.current.value = '';
        refreshFiltersAndSortOrder();
    }


    return <Form onSubmit={onSubmitAction} style={{marginTop: 5}} className='quicksearch'>
        <div className='mb-3'>
            <Form.Group as={Col} md='12' controlId='validationFormikUsername' style={{paddingRight: 0, paddingLeft: 0}}>
                <Form.Label>{label} <InfoHoverOver information={'This search ignores filters and sortby.'}/></Form.Label>
                <InputGroup className='inputField'>
                    <Form.Control
                        type='text'
                        placeholder={placeholder}
                        aria-describedby='inputGroupPrepend'
                        name={searchValues[0]['type']}
                        ref={inputValue}
                        
                    />
                    <InputGroup.Prepend type='submit' value='submit' onClick={onSubmitAction}>
                        <InputGroup.Text id='inputGroupPrepend'><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    </InputGroup.Prepend>


                </InputGroup>
                <Form.Text className='text-muted textOnClickFunction' onClick={clearsearch}>
                    clear search
                </Form.Text>
            </Form.Group>
        </div>

    </Form>

}
import {
    PieChartOrderSummaryOption,
    LiveExportOption,
    TradingPartnerOrderBarOption,
    TradingPartnerInvoiceSentBarOption,
    HealthStatusOption
} from './Reports/index';

const ReportGridOptionsList = (props) => {

    return [
        {
            ...PieChartOrderSummaryOption(props)
        }, {
            ...LiveExportOption(props)
        }, {
            ...TradingPartnerOrderBarOption(props)
        }, {
            ...TradingPartnerInvoiceSentBarOption(props)
        }, {
            ...HealthStatusOption(props)
        }
    ];
};

export default ReportGridOptionsList;
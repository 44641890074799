import { toast } from 'react-toastify';

export const SuccessToastR = (message) => {
    return (toast.success(message,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        }));
};

export const ErrorToastR = (message) => {
    return (toast.error(message,
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        }));
};
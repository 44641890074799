import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/chart/bar';
import { GetEChartTheme } from './../../../../Style/ECharts/GetTheme';
import { PostReportAPI } from './../../../../FetchApi/Reports';
import { Spinner } from 'react-bootstrap';

const BarOptions = (barData, title, subtext, color) => {

    return {
        color: color ? color : ['#3398DB'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow' // 'line' | 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {
                    title: 'Download'
                }
            }
        },
        xAxis: [
            {
                type: 'category',
                data: barData.map(data => data.label), //['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                type: 'bar',
                barWidth: '60%',
                data: barData.map(data => data.value), //[10, 52, 200, 334, 390, 330, 220]
            }
        ]
    };
};

export const BarChart = (props) => {
    const [barData, setBarData] = useState(undefined);

    const queryOptions = [
        {
            queryName: 'currentDate',
            queryValue: new Date().toJSON()
        }
    ];
    const callfetchAPi = () => PostReportAPI(props.fetchAPI, queryOptions).then((result) => {
        if (result !== '') {
            setBarData(result);
        } else {
            setBarData([]);
        }
    });

    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            callfetchAPi();
        }
    });

    if (barData) {

        if (barData.length === 0)
            return (<div>

                        <p style={{
                            textAlign: 'center',
                            paddingTop: 20,
                            fontWeight: 500
                        }}>{props.nodata}</p>
                    </div>);

        const options = BarOptions(barData, props.title, props.subtext, props.color);

        return (
            <div>
                <ReactEcharts
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={GetEChartTheme()}
                    //onChartReady={callback}



                    //onEvents={EventsDict}



                    //opts={} 
/>
            </div>);
    } else {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }
};
import './dark';
import './shine';

export const GetEChartTheme = () => {

    const isDark = localStorage.getItem('isDark');
    let eChartTheme = 'shine';
    if (isDark === 'true') {
        eChartTheme = 'dark';

    }
    return eChartTheme;
};
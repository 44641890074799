import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DashboardPath } from '../../Globals/RoutePaths';

const PublicRoute = ({ component: Component, isAuthorised, ...rest }) => {

    return (        
        <Route {...rest} render={props => (isAuthorised ? <Redirect to={DashboardPath} /> : <Component {...props} />)} />
    );
};

export default PublicRoute;
import React, { useState, Fragment } from 'react';
import { Modal, Table, Spinner, Button } from 'react-bootstrap';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { CreatePagination } from './../Table/Pagination';
import { DisplayUserDateTime } from './../../Globals/UserDateTime'; //upddate me
import { OptionsIcons } from './../DisplayBlocks';
import { Component } from 'react';

// export const AuditById = (props) => {
//     const [audits, setAudit] = useState(undefined);
//     const [pageSettings, setPageSettings] = useState({ PageNumber: 1, PageSize: 20, IncludeSystemUserEvents: true });

//     const [isAuditOpen, setIsAuditOpen] = useState(false);

//     const handleClose = () => setIsAuditOpen(false);
//     const handleShow = () => {
//         if (!audits) {
//             setIsAuditOpen(true);

//             props.updateFetch(props.orderId, pageSettings.PageSize, pageSettings.PageNumber)
//                 .then((result) => setAudit(result));

//         } else {
//             setIsAuditOpen(true);
//         }
//     };

//     const changePageNumber = (filters = null, number) => {
//         props.updateFetch(props.orderId, pageSettings.PageSize, pageSettings.PageNumber).then((result) => setAudit(result)).then(() => setPageSettings({ ...pageSettings, PageNumber: number }));
//     }

//     const GetEventSource = (number) => {
//         switch (number) {
//             case 1:
//                 return 'Function App';
//             case 2:
//                 return 'Website';
//             default:
//                 return 'None';
//         }
//     };

//     const GetEventType = (number) => {
//         switch (number) {
//             case 1:
//                 return 'Create';
//             case 2:
//                 return 'Read/View';
//             case 3:
//                 return 'Update';
//             case 4:
//                 return 'Delete';
//             default:
//                 return 'None';
//         }
//     };

//     return (
//         <Fragment>
//             {OptionsIcons(faHistory, props.tooltip, handleShow, props.extraClassName)}
//             <Modal
//                 show={isAuditOpen}
//                 onHide={handleClose}
//                 animation={false}
//                 size="lg"
//                 scrollable>

//                 <Modal.Header closeButton>
//                     <Modal.Title>{props.modalTitle}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {
//                         (!audits && <Spinner animation="border" />)
//                     }
//                     {
//                         (audits &&
//                             audits.length === 0 &&
//                             <div style={{ margin: 'auto', textAlign: 'center', paddingTop: 20, paddingBottom: 10 }}>
//                                 <p>No audit history was found for this order.</p>
//                             </div>
//                         )

//                     }
//                     {
//                         audits &&
//                         audits.length > 0 &&
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th>Date</th>
//                                     <th>Event</th>
//                                     <th>Type</th>
//                                     <th>User</th>
//                                     <th>Message</th>
//                                 </tr>
//                             </thead>
//                             <tbody>

//                                 {
//                                     (
//                                         audits &&
//                                         audits.length > 0 &&
//                                         audits.map((audit, key) => (
//                                             <tr key={key}>
//                                                 <td>{DisplayUserDateTime(audit.eventDateTime, true)}</td>
//                                                 <td>{GetEventSource(audit.eventSource)}</td>
//                                                 <td>{GetEventType(audit.eventType)}</td>
//                                                 <td>{audit.userName}</td>
//                                                 <td>{audit.message}</td>
//                                             </tr>))
//                                     )
//                                 }
//                             </tbody>
//                         </Table>
//                     }
//                 </Modal.Body>
//                 <Modal.Footer>

//                     {audits &&
//                         audits.length > 0 &&
//                         CreatePagination(audits[0].totalCount,
//                             pageSettings.PageSize,
//                             pageSettings.PageNumber,
//                             null,
//                             changePageNumber)}
//                     <br />
//                     <Button variant='danger' onClick={handleClose}>Close</Button>
//                 </Modal.Footer>
//             </Modal>
//         </Fragment>
//     );
// };


// import React, { useState, Fragment } from 'react';
// import { Modal, Table, Spinner, Button } from 'react-bootstrap';
// import { faHistory } from '@fortawesome/free-solid-svg-icons';
// import { CreatePagination } from './../Table/Pagination';
// import { DisplayUserDateTime } from './../../Globals/UserDateTime'; //upddate me
// import { OptionsIcons } from './../DisplayBlocks';

export class AuditById extends Component {
    constructor(props) {
        super(props);
        this.UpdateAudit = this.UpdateAudit.bind(this);
        this.ModalState = this.ModalState.bind(this);
        this.GetEventSource = this.GetEventSource.bind(this);
        this.GetEventType = this.GetEventType.bind(this);


        this.state = {
            audits: undefined,
            pageNumber: 1,
            pageSize: 20,
            includeSystemUserEvents: true,
            isAuditOpen: false,
            isLoading: false
        }
    };

    UpdateAudit(filters = null, pageNumber) {
        this.setState({ isLoading: true });
        this.props.updateFetch(this.props.orderId, this.state.pageSize, pageNumber ? pageNumber : this.state.pageNumber).then((result) => {
            const newState = {
                audits: result,
                isLoading: false
            }
            if (pageNumber) newState.pageNumber = pageNumber
            this.setState({ ...newState });
        });
    }

    ModalState() {
        !this.state.isAuditOpen && !this.state.audits && this.UpdateAudit(null, this.state.pageNumber)

        this.setState({ isAuditOpen: !this.state.isAuditOpen })
    }


    GetEventSource(number) {
        switch (number) {
            case 1:
                return 'Function App';
            case 2:
                return 'Website';
            default:
                return 'None';
        }
    }

    GetEventType(number) {
        switch (number) {
            case 1:
                return 'Create';
            case 2:
                return 'Read/View';
            case 3:
                return 'Update';
            case 4:
                return 'Delete';
            default:
                return 'None';
        }
    }
    render() {
        return (
            <Fragment>
                {OptionsIcons(faHistory, this.props.tooltip, this.ModalState, this.props.extraClassName)}
                <Modal
                    show={this.state.isAuditOpen}
                    onHide={this.ModalState}
                    animation={false}
                    size="lg"
                    scrollable>

                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (this.state.isLoading || !this.state.audits) &&
                            (<Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>)
                        }
                        {
                            (this.state.audits &&
                                this.state.audits.length === 0 &&
                                <div style={{ margin: 'auto', textAlign: 'center', paddingTop: 20, paddingBottom: 10 }}>
                                    <p>No audit history was found for this order.</p>
                                </div>
                            )

                        }
                        {
                            this.state.audits &&
                            this.state.audits.length > 0 &&
                            <Table>
                                <thead>
                                   
                                        <tr>
                                            <th>Date</th>
                                            <th>Event</th>
                                            <th>Type</th>
                                            <th>User</th>
                                            <th>Message</th>
                                        </tr>
                                
                                </thead>
                                <tbody>
                                    {
                                        (
                                            this.state.audits &&
                                            this.state.audits.length > 0 &&
                                            this.state.audits.map((audit, key) => (
                                                <tr key={key}>
                                                    <td>{DisplayUserDateTime(audit.eventDateTime, true)}</td>
                                                    <td>{this.GetEventSource(audit.eventSource)}</td>
                                                    <td>{this.GetEventType(audit.eventType)}</td>
                                                    <td>{audit.userName}</td>
                                                    <td>{audit.message}</td>
                                                </tr>))
                                        )
                                    }
                                </tbody>
                            </Table>
                        }
                    </Modal.Body>
                    <Modal.Footer>

                        {this.state.audits &&
                            this.state.audits.length > 0 &&
                            CreatePagination(this.state.audits[0].totalCount,
                                this.state.pageSize,
                                this.state.pageNumber,
                                null,
                                this.UpdateAudit)}
                        <br />
                        <Button variant='danger' onClick={this.ModalState}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    };
};
import React, { Fragment } from 'react';
//import { SubmitUserTime, CreateStandardDateTimeFormat } from './../';
import { DisplayUserDateTime, ConvertToApiFriendlyDateTime } from './../../Globals/UserDateTime'
import { Modal } from 'react-bootstrap';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//Todo UserdateTime

export default class FilterByDateRange extends React.Component {
    constructor(props) {
        super(props);
        this.ChangeDropDown = this.ChangeDropDown.bind(this);
        this.CalendarOpenStatus = this.CalendarOpenStatus.bind(this);
        this.ChangeValues = this.ChangeValues.bind(this);

        this.state = {
            filterByDateRangeDropDown: false,
            calendarIsOpen: false,
            selectedDateRange: undefined
        };
    }

    CalendarOpenStatus() {
        this.setState(() => ({ calendarIsOpen: !this.state.calendarIsOpen }));
    }

    ChangeValues(calendarValues) {

        const type = this.props.Type;
        const typeName = this.props.TypeName;

        let startDate = calendarValues[0];
        let endDate = calendarValues[1];

        if (calendarValues.start > calendarValues.end) {
            startDate = calendarValues.end;
            endDate = calendarValues.start;
        }

        const name = (DisplayUserDateTime(startDate, false,true) + ' - ' + DisplayUserDateTime(endDate, false, true)).toString();
    
        const newCalendarFilter = {
            type: type,
            typeName: typeName,
            id: -2,
            name: name,
            value: [ConvertToApiFriendlyDateTime(startDate), ConvertToApiFriendlyDateTime(endDate)]
        };

        this.props.AddToFilter(newCalendarFilter, false, type);

        this.CalendarOpenStatus();
    }

    ChangeDropDown() {
        this.setState(() => ({ filterByDateRangeDropDown: !this.state.filterByDateRangeDropDown }));
    }

    render() {
        //const CalendarWithRange = withRange(Calendar);
        return (
            <Fragment>
                <div onClick={this.CalendarOpenStatus} className={'filterDropDownBlock'}>
                    <span>
                        <FontAwesomeIcon className="filterIcon" icon={this.props.iconType} size="lg" /> {this.props
                            .Title}
                    </span>
                </div>

                <Modal show={this.state.calendarIsOpen} onHide={this.CalendarOpenStatus} centered>

                    <Modal.Body><DateRangePicker onChange={(values) => this.ChangeValues(values)} /></Modal.Body>

                </Modal>
                {
                    // this.state.calendarIsOpen &&
                    // (
                    //     <div className="CalendarModal">

                    //         <div className="CalendarBox">

                    //             <InfiniteCalendar
                    //                 Component={CalendarWithRange}
                    //                 selected={{
                    //                     start: new Date(Date.now()),
                    //                     end: new Date(Date.now())
                    //                 }}
                    //                 onSelect={(values) => (this.ChangeValues(values,
                    //                     this.props.Type,
                    //                     this.props.TypeName))}
                    //                 locale={{ headerFormat: 'MMM Do', }}/>
                    //             <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                    //                 <span className="ModalCloseButton" onClick={this.CalendarOpenStatus}>Close</span>
                    //             </div>
                    //         </div>
                    //     </div>
                    // )
                }
            </Fragment>
        );
    }
}
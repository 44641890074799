import React, { useState, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export const DefaultModal = ({ modalTitle, modalBody, modalFooter, isOpen, changeModelState, size = 'lg' }) => {

    return (

        <Modal
            show={isOpen}
            onHide={changeModelState}
            size={size}>
            <Modal.Header closeButton>
                {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {
                    modalBody
                }
            </Modal.Body>
            {modalFooter && <Modal.Footer>
                {modalFooter}
            </Modal.Footer>}
        </Modal>

    )
}

export const DefaultModalIconWrapper = ({ modalTitle, modalBody, modalFooter, size = 'lg', icon = faEye }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Fragment>
            <FontAwesomeIcon icon={icon} onClick={() => setIsOpen(!isOpen)} />
            <DefaultModal
                modalTitle={modalTitle}
                modalBody={modalBody}
                modalFooter={modalFooter}
                size={size}
                changeModelState={() => setIsOpen(!isOpen)}
                isOpen={isOpen}
            />
        </Fragment>)
}


export const ContentBlocks = (props) => {
    return (
        <div className="modalContentBlock">
            <h6>{props.title}</h6>
            <p>{props.content}</p>
        </div>);
};
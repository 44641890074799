import React, { useState, Fragment } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { CloseButton } from './../Common/index';
import { OptionsIcons } from './../DisplayBlocks';

export const XMLModal = ({modalTitle, icon, fetchApi, size = 'lg', extraClassName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [xmlMessage, setXMLMessage] = useState(undefined);

    const onModalChange = () => {
        if (!xmlMessage && !isOpen) fetchApi().then((result) => setXMLMessage(result));
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            {OptionsIcons(icon, modalTitle,onModalChange, extraClassName ? extraClassName : '')}
            <Modal
                show={isOpen}
                onHide={onModalChange}
                size={size}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalScroll'>
                    <div>
                        { xmlMessage ? <pre lang="xml">{xmlMessage}</pre> : <Spinner animation="border"><span className="sr-only">Loading Details...</span></Spinner> }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CloseButton contents='Close' onClickFunction={onModalChange}/>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};
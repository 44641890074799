import React, { Fragment, useState } from 'react';
import { faFileInvoice, faFileInvoiceDollar, faFileAlt, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import { XMLModal } from './../Common/XMLModal';
import { PrintPurchaseOrder } from './PrintPO';
import { SuccessToastR } from './../../Globals/ToastrReponses';
import { AuditById } from './AuditById';
import { DisplayUserDateTime } from './../../Globals/UserDateTime';
import { AddInvoiceToAcceptedOrder } from './AddInvoiceToAcceptedOrder';
import { OptionsIcons } from './../DisplayBlocks';
//import { GetInvoiceStatus } from './GetInvoiceStatus';
import { GetOrderXMLById, GetOrderAckXMLByOrderId, GetInvoiceXMLByInvoiceId, SetOrderAcknowledgementForResend, GetAuditByOrderId } from './../../FetchApi/Order';

export const OrderOptions = (props) => {

    return (
        <Row style={{ height: '62%' }}>
            <Col lg={1} className='orderOptions' style={{marginLeft: 5}}>
                <PrintPurchaseOrder {...props} />
            </Col>
            {props.orderXMLId &&
                <Col lg={1} className='orderOptions'>
                    <XMLModal icon={faFileAlt} modalTitle='Purchase Order Message' fetchApi={() => GetOrderXMLById(props.id)} />
                </Col>}
            {props.orderAcknowledgementXMLId &&
                <Col lg={1} className='orderOptions'>
                    <XMLModal icon={faFileInvoice} modalTitle='Order Ack Message' fetchApi={() => GetOrderAckXMLByOrderId(props.id)} />
                </Col>}
            {props.invoiceId &&
                <Col lg={1} className='orderOptions'>
                    <XMLModal icon={faFileInvoiceDollar} modalTitle='Invoice Message' fetchApi={() => GetInvoiceXMLByInvoiceId(props.id)} />
                </Col>}
            <Col lg={1} className='orderOptions'>
                <AuditById tooltip='Order History' modalTitle='Order Audit' orderId={props.id} updateFetch={GetAuditByOrderId}/>
            </Col>
            {props.orderAcknowledgementXMLId &&
                <Col lg={1} className='orderOptions'>
                    <ResendOrderAck orderId={props.id} />
                </Col>}
            {props.isAccepted &&
                <Col lg={1} className='orderOptions'>
                    <AddInvoiceToAcceptedOrder invoices={props.orderInvoices} orderId={props.id} purchaseOrderNumber={props.purchaseOrderNumber} tradingPartnerId={props.tradingPartnerId} isUrgent={props.isUrgent} totalAmount={props.totalAmount}/>
                </Col>
            }
            {
                
                // props.isAccepted || props.isRejected &&
                
                // <Col lg={1} className='orderOptions'>
                //     <GetInvoiceStatus purchaseOrderNumber={props.purchaseOrderNumber} />
                // </Col>
            }
        </Row>
    )
}

const ResendOrderAck = (props) => {

    const [isOpen, setisOpenModal] = useState(false);

    const changeModal = () => {
        setisOpenModal(!isOpen);
    };

    const resendingOrderAck = () => {
        SetOrderAcknowledgementForResend(props.orderId, props.userId).then((result) => {
            if (result) {
                SuccessToastR(`Order acknowledgement will be resent at ${DisplayUserDateTime(result, true, true)} `);
            } 
        });
        setisOpenModal(!isOpen);
    };

    const PromotModal = () => {
        return (
            <Modal show={isOpen} onHide={changeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Resend Order Acknowledgment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to resend this order acknowledgment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={changeModal}> No </Button>
                    <Button variant="success" onClick={resendingOrderAck}> Yes </Button>
                </Modal.Footer>
            </Modal>);
    };

    return (
        <Fragment>
            {OptionsIcons(faShareSquare, 'Resend order acknowledgment', changeModal)}
            <PromotModal />
        </Fragment>
    );
};

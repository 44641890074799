import BaseApi from './BaseApi';

export async function GetReportConfig() {

    return BaseApi(
        `api/Reports/GetReportConfig`,
        'GET'
    );
}

//Filters
export async function PostReportAPI(fetchAPI, options) {

    let query = '';

    if (options.length !== 0) {
        const queryArray = [];
        options.forEach(option => {
            queryArray.push(`${option.queryName}=${option.queryValue}`);
        });

        query = `?${queryArray.join('&')}`;
    }

    return BaseApi(
        `api/Reports/${fetchAPI}${query}`,
        'GET');
}

const queryOptions = (queryOption) => {

    if (queryOption.length !== 0) {

        const queryArray = [];

        queryOption.forEach(option => {
            const queryItem = {
                QueryName: option.queryName,
                QueryValue: option.queryValue
            };
            queryArray.push(queryItem);
        });

        return queryArray;

    } else {
        return null;
    }

};

export async function InsertReportConfig(props) {

    return BaseApi(
        'api/Reports/InsertReportConfig',
        'POST',
        {
            reportName: props.reportName,
            fetchAPI: props.fetchAPI,
            parameters: queryOptions(props.queryOptions)
        }
    );
}

export async function DeleteFromReportConfig(reportId) {
    return BaseApi(`api/Reports/DeleteFromReportConfig?reportId=${reportId}`, 'POST');
}

export async function UpdateReportConfig(props) {
    return BaseApi(
        'api/Reports/UpdateReportConfig',
        'POST',
        {
            reportId: props.id,
            parameters: queryOptions(props.queryOptions)
        }
    );
}
import React, { useState, useEffect, Fragment } from 'react';
import { Container, Col, Row, Form, OverlayTrigger, Tooltip, Button, Tab, Tabs } from 'react-bootstrap';
import {
    GetTradingPartners,
    GetTradingPartnerTemplate,
    UpdateTradingPartner,
    GetReleaseScheduleType,
    GetReleaseScheduleConfigForTradingPartner,
    UpdateReleaseScheduleConfigForTradingPartner
} from './../FetchApi/Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DateTimePicker from 'react-datetime-picker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { POSpawner } from './../components/TradingPartner/POSpawner';
import { ConvertToJSDate, ConvertToApiFriendlyDateTime } from './../Globals/UserDateTime';
import { SuccessToastR } from './../Globals/ToastrReponses';
import { CustomFTPSettings } from './../components/Settings/CustomFTPS';

const GetAttribute = (props) => {
    if (props.lenght === 0) return null;
    var attributes = {};
    for (let i = 0; i < props.length; i++) {
        attributes[props[i].name] = props[i].attributeValue;
    }
    return attributes;
};

const inputType = (props, key, selectedTradingPartner, onValueChange) => {

    const attributes = GetAttribute(props.attributes);

    switch (props.propertyType) {
        case 'String':
            return <StringInput key={key} onValueChange={onValueChange} {...props} {...attributes} defaultValue={selectedTradingPartner[props.name]} />;
        case 'Boolean':
            return <BooleanInput key={key} onValueChange={onValueChange} {...props} {...attributes} defaultValue={selectedTradingPartner[props.name]} />;
        case 'Int32':
            return <Int32Input key={key} onValueChange={onValueChange} {...props} {...attributes} defaultValue={selectedTradingPartner[props.name]} />;
        case 'DateTime':
            return <DateTimeInput key={key} onValueChange={onValueChange} {...props} {...attributes} defaultValue={selectedTradingPartner[props.name]} />;
        default:
            break;
    };
};

const TradingPartnerSettings = (props) => {

    const [tradingPartners, setTradingPartners] = useState(undefined);

    const [selectedTradingPartner, setSelectedTradingPartner] = useState(undefined);

    const [inputTemplate, setTemplate] = useState(undefined);

    useEffect(() => {
        if (!tradingPartners && inputTemplate) GetTradingPartners().then((result) => setTradingPartners(result));
        if (!inputTemplate) GetTradingPartnerTemplate().then((result) => setTemplate(result));
    });

    const EditTradingPartner = (id) => {
        const newTradingPartner = tradingPartners.filter((partner) => {
            if (id === partner.id) {
                return partner;
            }
            return null;
        })[0];

        setSelectedTradingPartner(newTradingPartner);
    };

    const onValueChange = (name, newValue) => {

        const newSelectedTradingPartner = Object.assign({}, selectedTradingPartner);
        newSelectedTradingPartner[name] = newValue;

        setSelectedTradingPartner(newSelectedTradingPartner);

    };

    const addTradingPartner = () => {

        var createDefaultValues = {};

        for (let i = 0; i < inputTemplate.length; i++) {

            switch (inputTemplate[i].propertyType) {
                case 'Int32':
                    inputTemplate[i].nullable
                        ? createDefaultValues[inputTemplate[i].name] = null
                        : inputTemplate[i].name === 'id'
                            ? createDefaultValues[inputTemplate[i].name] = -2
                            : createDefaultValues[inputTemplate[i].name] = 0;
                    break;
                case 'String':
                    createDefaultValues[inputTemplate[i].name] = null;
                    break;
                case 'DateTime':
                    inputTemplate[i].nullable
                        ? createDefaultValues[inputTemplate[i].name] = null
                        : createDefaultValues[inputTemplate[i].name] = new Date();
                    break;
                case 'Boolean':
                    createDefaultValues[inputTemplate[i].name] = false;
                    break;
                default:
                    break;
            };
        };

        setSelectedTradingPartner(createDefaultValues);
    };

    const saveChanges = (e) => {
        e.preventDefault();
        const tradingPartner = selectedTradingPartner;

        UpdateTradingPartner(tradingPartner).then((result) => {
            if (selectedTradingPartner.id === -2) {

                const newtradingPartner = selectedTradingPartner;
                newtradingPartner.id = result;
                const newtTradingPartnerList = tradingPartners.concat(newtradingPartner);
                setTradingPartners(newtTradingPartnerList);
            } else {

                let newtradingPartnerList = [];
                for (let i = 0; i < tradingPartners.length; i++) {
                    if (tradingPartners[i].id === selectedTradingPartner.id) {
                        newtradingPartnerList = newtradingPartnerList.concat(selectedTradingPartner);
                    } else {
                        newtradingPartnerList = newtradingPartnerList.concat(tradingPartners[i]);
                    }
                }
                setTradingPartners(newtradingPartnerList);
            }
        }).then(SuccessToastR('Update was successful'));
    };

    return (
        <Container fluid>
            <Row>
                <Col md={2} style={{ padding: 10 }}>
                    <div className="tradingPartnerContainer">
                        <h3 className="tradingPartnerTitle">Trading Partners</h3>
                        <div style={{ margin: '0px 3px', textAlign: 'center' }}>
                            <div className="AddTradingPartnerButton">
                                <span onClick={addTradingPartner}>Add Trading Partner</span>
                            </div>
                        </div>
                        {tradingPartners &&
                            tradingPartners.map((partner, key) => (
                                <div key={key} onClick={() => EditTradingPartner(partner.id)
                                } className="tradingPartnerList">{partner.name}</div>
                            ))}
                    </div>
                </Col>
                {selectedTradingPartner &&
                    (
                        <Col md={10} style={{ padding: 10 }}>

                            <Tabs defaultActiveKey="Settings">
                                <Tab eventKey="Settings" title="Settings">

                                    <div className="tradingPartnerEditorContainer">
                                        <h1>{selectedTradingPartner.name}</h1>

                                        <Form onSubmit={saveChanges}>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                <h3>General</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('General') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all', 'marginTop': '50px' }}>
                                                <h3>850 - Orders</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('850') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all', 'marginTop': '50px' }}>
                                                <h3>855 - Order Acknowledgments</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('855') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all', 'marginTop': '50px' }}>
                                                <h3>810 - Invoices</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('810') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all', 'marginTop': '50px' }}>
                                                <h3>824 - Application Advice</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('824') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all', 'marginTop': '50px' }}>
                                                <h3>Reports</h3>
                                            </Form.Row>
                                            <Form.Row style={{ wordBreak: 'break-all' }}>
                                                {
                                                    inputTemplate.filter((item) =>
                                                        item.attributes.filter((atr) =>
                                                            atr.name === 'DescriptionAttribute' &&
                                                            atr.attributeValue.indexOf('Reports') === 0
                                                        ).length >
                                                        0).map((items, key) => inputType(items,
                                                            key,
                                                            selectedTradingPartner,
                                                            onValueChange))}
                                            </Form.Row>
                                            <Button type="submit">Save</Button>
                                        </Form>
                                    </div>

                                    {selectedTradingPartner.id !== -2 &&
                                        (<div>
                                            <div style={{ padding: '0 10' }}>

                                                <ReleaseScheduleConfigForTradingPartner {...selectedTradingPartner} />
                                            </div>

                                            <div style={{padding: '0 10'}}>
                                                <CustomFTPSettings tradingPartner={selectedTradingPartner}/>
                                            </div>

                                        </div>

                                        )
                                    }




                                </Tab>
                                <Tab eventKey="POSpawner" title="Purchase Order Spawner">

                                    <POSpawner {...selectedTradingPartner} />
                                </Tab>

                            </Tabs>
                        </Col>)}
            </Row>
        </Container>
    );
};

export default TradingPartnerSettings;

const StringInput = (props) => {

    const onUpdate = (e) => {
        e.preventDefault();

        if (props.MaxInputAttribute) {
            if (e.currentTarget.value.length <= props.MaxInputAttribute)
                props.onValueChange(props.name, e.currentTarget.value);
        } else {
            props.onValueChange(props.name, e.currentTarget.value);
        };
    };

    const required = props.RequiredInputAttribute ? true : false;
    return (
        <Form.Group as={Col} md="4" controlId={props.name} style={{ padding: '2px 5px' }}>
            <Form.Label>{props.name[0].toUpperCase() + props.name.slice(1)} {props.DescriptionAttribute &&
                <ToolTip {...props} />} {required ? <span style={{ color: '#d24571' }}>*</span> : <span />}</Form.Label>
            <Form.Control required={required} name={props.name} onChange={onUpdate
            } type="text" className="textinput" value={props.defaultValue ? props.defaultValue : ''} />
        </Form.Group>
    );
};

const BooleanInput = (props) => {

    const inputValue = props.defaultValue ? props.defaultValue : false;

    return (
        <Form.Group as={Col} md="4" controlId={props.name} style={{ padding: '5px 5px' }}>
            <Form.Check type="checkbox" name={props.name}>
                <Form.Check.Input onChange={() => props.onValueChange(props.name, !props.defaultValue)} checked={inputValue} name={props.name} type="checkbox" />
                <Form.Check.Label>{props.name[0].toUpperCase() + props.name.slice(1)} {props.DescriptionAttribute && <ToolTip {...props} />}</Form.Check.Label>
            </Form.Check>
        </Form.Group>
    );

};

const Int32Input = (props) => {
    if (props.name === 'id') return <Fragment />;

    const onUpdate = (e) => {
        e.preventDefault();

        if (!e.currentTarget.value !== '') {
            const newValue = parseInt(e.currentTarget.value);
            props.onValueChange(props.name, newValue);

        } else {
            if (props.nullable) props.onValueChange(props.name, null);
            props.onValueChange(props.name, 0);
        };

    };

    const required = props.RequiredInputAttribute ? true : false;
    return (
        <Form.Group as={Col} md="4" controlId={props.name}>
            <Form.Label>{props.name[0].toUpperCase() + props.name.slice(1)} {props.DescriptionAttribute &&
                <ToolTip {...props} />} {required ? <span style={{ color: '#d24571' }}>*</span> : <span />}</Form.Label>
            <Form.Control required={required} onChange={onUpdate} name={props.name
            } className="textinput" type="number" value={props.defaultValue ? props.defaultValue : props.defaultValue === 0 ? 0 : ''
            } />
            <Form.Text className="text-muted">
                Number input only. {props.nullable && ' This can be null'}
            </Form.Text>
        </Form.Group>
    );
};

const DateTimeInput = (props) => {

    const onChange = (newValue) => {
        props.onValueChange(props.name, ConvertToApiFriendlyDateTime(newValue));
    };

    return (
        <Form.Group as={Col} md="4" controlId={props.name}>
            <span>{props.name[0].toUpperCase() + props.name.slice(1)} {props.DescriptionAttribute &&
                <ToolTip {...props} />}</span><br />
            <DateTimePicker
                onChange={onChange}
                value={ConvertToJSDate(props.defaultValue)} />

        </Form.Group>);
};

const ToolTip = (props) => {
    return (<OverlayTrigger
        placement={'top'}
        overlay={
            <Tooltip>
                {props.DescriptionAttribute}
            </Tooltip>
        }>
        <FontAwesomeIcon icon={faInfoCircle} />
    </OverlayTrigger>);
};

const ReleaseScheduleConfigForTradingPartner = (props) => {

    const defaultInput = {
        id: undefined,
        tradingPartnerId: undefined,
        releaseScheduleTypeId: undefined,
        xValue: undefined,
        timeZoneId: undefined
    };
    const [tradingPartnerReleaseSchedule, setTradingPartnerreleaseSchedule] = useState(defaultInput);
    const [isDropDown, setDropDown] = useState({ tpId: props.id, isOpen: false });
    const [releaseScheduleTemplate, setreleaseScheduleTemplate] = useState(undefined);

    if (props.id !== isDropDown.tpId) setDropDown({ tpId: props.id, isOpen: false });

    const SaveValues = (e) => {
        e.preventDefault();
    };

    if (!releaseScheduleTemplate) {
        GetReleaseScheduleType().then((result) => setreleaseScheduleTemplate(result));
    };

    const onMenuChange = () => {
        setDropDown({ ...isDropDown, isOpen: !isDropDown.isOpen });
    };

    return (
        <div className="tradingPartnerEditorContainer" style={{ marginBottom: 50 }}>
            <div>
                <h2>
                    Invoice Release Schedule Config
                    <span style={{ float: 'right', padding: '0px 10px' }} onClick={
                        onMenuChange}>
                        <FontAwesomeIcon icon={isDropDown.isOpen ? faChevronUp : faChevronDown} />
                    </span>
                </h2>
            </div>
            {isDropDown.isOpen &&
                releaseScheduleTemplate &&
                <ReleaseScheduleSettings defaultInput={defaultInput} tradingPartnerReleaseSchedule={
                    tradingPartnerReleaseSchedule} setTradingPartnerreleaseSchedule={setTradingPartnerreleaseSchedule} SaveValues={
                        SaveValues} id={props.id} {...releaseScheduleTemplate} />}
        </div>
    );
};

const ReleaseScheduleSettings = (props) => {

    if (props.id && props.tradingPartnerReleaseSchedule.tradingPartnerId !== props.id) {
        GetReleaseScheduleConfigForTradingPartner(props.id).then((result) => {
            result
                ? props.setTradingPartnerreleaseSchedule(result)
                : props.setTradingPartnerreleaseSchedule({ ...props.defaultInput, tradingPartnerId: props.id });
        });
    };

    const onInputUpdate = (input, location) => {

        const newInput = { ...props.tradingPartnerReleaseSchedule, [location]: input.value };

        props.setTradingPartnerreleaseSchedule(newInput);
    };

    const numberInputUpdate = (input, location) => {

        const newInput = { ...props.tradingPartnerReleaseSchedule, [location]: parseInt(input.target.value, 10) };
        props.setTradingPartnerreleaseSchedule(newInput);
    };

    const timeZoneValue = props.timeZones.filter((timeZone) => {
        if (timeZone.value === props.tradingPartnerReleaseSchedule.timeZoneId) {
            return timeZone;
        } else {
            return null;
        }
    });
    const scheduleType = props.scheduleTypes.filter((st) => {
        if (st.value === props.tradingPartnerReleaseSchedule.releaseScheduleTypeId) {
            return st;
        } else {
            return null;
        }
    });

    const SaveValues = (e) => {
        e.preventDefault();
        UpdateReleaseScheduleConfigForTradingPartner(props.tradingPartnerReleaseSchedule);
    };

    return (
        <Form style={{ paddingBottom: 50 }} onSubmit={SaveValues}>

            <Form.Row>
                <Form.Group as={Col} xs={4}>
                    <label>Schedule Type</label>
                    <Dropdown options={props.scheduleTypes}
                        onChange={(change) => onInputUpdate(change, 'releaseScheduleTypeId')}
                        value={scheduleType.length === 0 ? undefined : scheduleType[0]} />
                </Form.Group>
                {
                    (props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 1 ||
                        props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 2 ||
                        props.tradingPartnerReleaseSchedule.xValue === 6) &&
                    <Form.Group as={Col} xs={4}>
                        <Form.Label>X Amount</Form.Label>
                        <Form.Control className="textinput" onChange={(change) => numberInputUpdate(change, 'xValue')
                        } value={props.tradingPartnerReleaseSchedule.xValue ? props.tradingPartnerReleaseSchedule.xValue : ''
                        } type="number" placeholder="Enter Number Amount" />
                    </Form.Group>}
                {
                    (props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 3 ||
                        props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 4 ||
                        props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 5 ||
                        props.tradingPartnerReleaseSchedule.releaseScheduleTypeId === 6) &&
                    <Form.Group as={Col} xs={4}>
                        <label>Time Zone</label>
                        <Dropdown options={props.timeZones}
                            onChange={(change) => onInputUpdate(change, 'timeZoneId')}
                            value={timeZoneValue.length === 0 ? undefined : timeZoneValue[0]} />
                    </Form.Group>}
            </Form.Row>
            <Button type="submit">Save</Button>
        </Form>
    );
};
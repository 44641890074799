import React, { Component } from 'react';
import { Container, Row, Col,Spinner } from 'react-bootstrap';
import { GetOrderFilterList, GetOrderList } from '../FetchApi/Order';
import { FilterByDateRange, FilterById, FilterBar, Urgent, FilterByIdWithSubIds, QuickSearch } from '../components/FilterBar/index';
import { CreateOrderSqlParams } from '../components/FilterBar/FilterBarMapping';
import { faSuitcase, faCalendarAlt, faHandshake, faFastForward, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';
import { LinkToButton } from '../components/Common/Buttons';
import { OrderPath } from '../Globals/RoutePaths';
import { SortByLogic, SortByDefault, renderPagination, PurchasesTable } from '../components/Table/index';
import { ReturnDollarValueWithCommon } from '../components/Common/MoneyValue';
import { DisplayUserDateTime } from '../Globals/UserDateTime';

//const LocalStorageReference = 'UnprocessedOrders';


export default class OrdersTable extends Component {
    constructor(props) {
        super(props)

        this.OnClickSortOrder = this.OnClickSortOrder.bind(this);
        this.UpdateTableData = this.UpdateTableData.bind(this);
        this.UpdatePageNumber = this.UpdatePageNumber.bind(this);
        this.RefreshFiltersAndSortOrder = this.RefreshFiltersAndSortOrder.bind(this);
        this.GetSortByLocal = this.GetSortByLocal.bind(this);
        this.child = React.createRef(); // remove in the future
        //this.FetchOrders = this.FetchOrders.bind(this);
        // clear notifications for this page

        const SortBy = this.GetSortByLocal();

        this.state = {
            orders: [],
            tradingPartnerFilter: [],
            isLoadingPage: true,
            isLoading: false,
            sortBy: SortBy ? SortBy : SortByDefault,
            pageNumber: 1,
            pageSize: 20
        };
    };

    componentDidMount() {
        GetOrderFilterList(this.props.isAccepted, this.props.isRejected)
            .then(responsedata => this.setState({ tradingPartnerFilter: responsedata }));
    };

    UpdatePageNumber(filters, pageNumber, pageSize) {
        this.setState(() => ({ pageNumber: pageNumber }));
        this.UpdateTableData(filters, this.state.sortBy, pageNumber, pageSize);
    };

    UpdateTableData(filters, sortby, pageNumber, pageSize) {
    this.setState({isLoading: true});

        const activeFilters = CreateOrderSqlParams(filters,
            this.props.isAccepted,
            this.props.isRejected,
            pageNumber ? pageNumber : this.state.pageNumber,
            pageSize ? pageSize : this.state.pageSize);

        if (sortby) {
            activeFilters[sortby.name] = sortby.value;
        };

        this.FetchOrders(activeFilters);
    };

    FetchOrders(params) {
        GetOrderList(params).then((result) => this.setState({ orders: result, isLoading: false, isLoadingPage: false  }));
    };

    GetSortByLocal() {
        const sortBy = localStorage.getItem(this.props.localStorageReference + '-selectedsortBy')
        return JSON.parse(sortBy);
    }

    OnClickSortOrder(sortType) {
        const newSortBy = SortByLogic(sortType, this.state.sortBy);
        this.setState({ sortBy: newSortBy });
        this.child.current.SortBy(newSortBy);

        localStorage.setItem(this.props.localStorageReference + '-selectedsortBy', JSON.stringify(newSortBy));
    };

    RefreshFiltersAndSortOrder() {
        this.child.current.SortBy(this.state.sortBy);
    }

    render() {
        return (
            <Container fluid>
            {
                this.state.isLoading &&
                (<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>)
            }
                <Row>
                    <Col lg={2} >
                        <QuickSearch
                            isLoading={this.state.isLoadingPage}
                            updateFunction={this.UpdateTableData}
                            refreshFiltersAndSortOrder={this.RefreshFiltersAndSortOrder}
                            label='P.O. Number Search'
                            placeholder='P.O. Number'
                            searchValues={[{
                                id: -1,
                                name: '',
                                type: "poSearch",
                                typeName: "PO Search",
                                value: ''
                            }]} 
                        />

                        <FilterBar
                            isLoading={this.props.isLoadingPage}
                            localStorageReference={this.props.localStorageReference}
                            UpdateTableData={this.UpdateTableData}
                            isLoaded={!this.state.isLoadingPage}
                            sortBy={this.state.sortBy}
                            ref={this.child}
                        >

                            <FilterById
                                Title="Trading Partner"
                                Icon="circle-arrow-right"
                                filterByIds={this.state.tradingPartnerFilter}
                                AddToFilter={this.AddToFilter}
                                Type="tradingPartners"
                                TypeName="Trading Partners"
                                iconType={faHandshake} />

                            <FilterByDateRange
                                Title="P.O. Date"
                                AddToFilter={this.AddToFilter}
                                Type="poDate"
                                TypeName="PO Date"
                                iconType={faSuitcase} />

                            <FilterById
                                Title="Urgency"
                                Icon="exclamation-sign"
                                filterByIds={Urgent()}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="isUrgent"
                                TypeName="Urgency"
                                iconType={faFastForward}
                                selectOne={true} />

                            <FilterByDateRange
                                Title="Received Date"
                                AddToFilter={this.AddToFilter}
                                Type="receivedDate"
                                TypeName="Received Date"
                                iconType={faCalendarAlt} />

                            <FilterByIdWithSubIds
                                Title="Location"
                                Icon="circle-arrow-right"
                                filterByIds={this.state.tradingPartnerFilter}
                                AddToFilter={this.AddToFilter}
                                Type="country"
                                SubType="state"
                                TypeName="Country"
                                SubTypeName="State"
                                iconType={faGlobe} />

                            <FilterById
                                Title="Account Number"
                                filterByIds={this.state.tradingPartnerFilter}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="accountNumbers"
                                TypeName="Account Numbers"
                                iconType={faUser}
                                isLastBlock={true} />

                        </FilterBar>

                    </Col>
                    <Col lg={10}>
                        <PurchasesTable
                            data={UnprocessedOrderData(this.state.orders)}
                            headers={unprocessedOrderHeaderList}
                            sortByFunction={this.OnClickSortOrder}
                            activeSortBy={this.state.sortBy}
                            emptyDataMessage={this.props.emptyDataMessage}
                            isLoading={this.state.isLoadingPage}
                            stickyHeading={true}
                        />
                        {

                            this.state.orders && this.state.orders.length > 0 && !this.state.isLoadingPage &&
                            renderPagination(this.state.orders[0].totalCount,
                                this.state.pageSize,
                                this.state.pageNumber,
                                this.props.localStorageReference,
                                this.UpdatePageNumber)

                        }
                    </Col>
                </Row>
            </Container>
        )
    };
};

const UnprocessedOrderData = (orders = []) => {
    if (orders.length === 0) return [];

    let newScheme = [];
    orders.forEach(order => {
        const orderScheme = {
            purchaseOrderNumber: order.purchaseOrderNumber,
            purchaseOrderDate: DisplayUserDateTime(order.purchaseOrderDate), //dateTime
            receivedDate: DisplayUserDateTime(order.receivedDate),           //dateTime
            tradingPartnerName: order.tradingPartnerName,
            country: order.country,
            state: order.state,
            accountNumber: order.accountNumber,
            companyName: order.companyName,
            isUrgent: order.isUrgent,
            totalAmount: order.totalAmount ? ReturnDollarValueWithCommon(order.totalAmount.toFixed(2)) : order.totalAmount,
            action: <LinkToButton contents='View' linkTo={OrderPath(order.id)} />

        };
        newScheme.push(orderScheme);
    });
    return newScheme;
};

const unprocessedOrderHeaderList = [
    {
        headerName: 'P.O. Number',
        sortby: 'poNumberSortDirection',
        style: { width: '9%' }
    },
    {
        headerName: 'P.O. Date',
        sortby: 'poDateSortDirection',
        style: { width: '8%' }
    },
    {
        headerName: 'Received Date',
        sortby: 'receivedDateSortDirection',
        style: { width: '8%' }
    },
    {
        headerName: 'Trading Partner Name',
        sortby: undefined,
        style: { width: '17%' }
    },
    {
        headerName: 'Country',
        sortby: 'countrySortDirection',
        style: { width: '6%' }
    },
    {
        headerName: 'State',
        sortby: 'stateSortDirection',
        style: { width: '6%' }
    },
    {
        headerName: 'Account Number',
        sortby: 'accountNumberSortDirection',
    },
    {
        headerName: 'Company Name',
        sortby: 'companyNameSortDirection',
        style: { width: '12%' }
    },
    {
        headerName: 'Urgency',
        sortby: 'urgent',
        style: { width: '6%' }
    },
    {
        headerName: 'Total($)',
        sortby: undefined
    },
    {
        headerName: 'Action',
        sortby: undefined,
        style: { width: '6%' }
    }
];


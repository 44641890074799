export const Urgent = () => {
    return [
        {
            type: 'isUrgent',
            typeName: 'Urgency',
            id: -1,
            name: 'None',
            value: 0
        }, {
            type: 'isUrgent',
            typeName: 'Urgency',
            id: -1,
            name: 'Urgent',
            value: 1
        }
    ];

};

export const InvoiceType = () => {
    return [
        {
            type: 'invoiceTypeCode',
            typeName: 'Invoice Type',
            id: -1,
            name: 'Invoice',
            value: 'PR'
        }, {
            type: 'invoiceTypeCode',
            typeName: 'Invoice Type',
            id: -1,
            name: 'Credit Note',
            value: 'CR'
        }
    ];

};

export const EventSource = () => {
    return [
        {
            type: 'eventSource',
            typeName: 'Event Source',
            id: -1,
            name: 'Function App',
            value: 1
        }, {
            type: 'eventSource',
            typeName: 'Event Source',
            id: -1,
            name: 'Website',
            value: 2
        }
    ];

};

export const EventType = () => {
    return [
        {
            type: 'eventType',
            typeName: 'Event Type',
            id: -1,
            name: 'None',
            value: 0
        }, {
            type: 'eventType',
            typeName: 'Event Type',
            id: -1,
            name: 'Create',
            value: 1
        }, {
            type: 'eventType',
            typeName: 'Event Type',
            id: -1,
            name: 'Read/View',
            value: 2
        }, {
            type: 'eventType',
            typeName: 'Event Type',
            id: -1,
            name: 'Update',
            value: 3
        }, {
            type: 'eventType',
            typeName: 'Event Type',
            id: -1,
            name: 'Delete',
            value: 4
        }
    ];

};

export const SystemEvent = () => {
    return [
        {
            type: 'includeSystemUserEvents',
            typeName: 'System Events',
            id: -1,
            name: 'Exclude System Events',
            value: 0
        }, {
            type: 'includeSystemUserEvents',
            typeName: 'System Events',
            id: -1,
            name: 'Include System Events',
            value: 1
        }
    ];

};
import React, { Component, useRef, Fragment, useState } from 'react';
import { Container, Form, InputGroup, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loading } from '../components/Loading';
import { GetOutstandingInvoices, RemoveInvoiceFromPurchaseOrder, SetOrderInvoiceAsManuallySubmitted } from './../FetchApi/Order';
import { ManuallyImportInvoice } from './../FetchApi/Administration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faExternalLinkSquareAlt, faTrash,faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { PurchasesTable, renderPagination } from './../components/Table/index';
import { OrderPath, InvoicePath } from './../Globals/RoutePaths';
import { DisplayUserDateTime, ConvertToApiFriendlyDateTime } from './../Globals/UserDateTime';
import { SuccessToastR } from './../Globals/ToastrReponses';
import { CopyToClipboard, InfoHoverOver, LinkToText, DefaultModal, CloseButton, SaveButton } from './../components/Common/index';

export default class OutstandingOrders extends Component {
    constructor(props) {
        super(props)
        this.updateTable = this.updateTable.bind(this);
        this.removeOutstandingOrderFromList = this.removeOutstandingOrderFromList.bind(this);
        this.UpdatePageNumber = this.UpdatePageNumber.bind(this);

        this.state = {
            outstandingOrders: undefined,
            pageNumber: 1,
            pageSize: 20

        }
    }

    componentDidMount() {
        this.updateTable();
    }

    updateTable(input, pageNumber, pageSize) {
        GetOutstandingInvoices(input ? input : '', pageNumber ? pageNumber : 1, pageSize ? pageSize : this.state.pageSize).then((result) => this.setState(() => ({ outstandingOrders: result })));
    }

    removeOutstandingOrderFromList(id) {
        this.setState((prevState) => ({
            outstandingOrders: prevState.outstandingOrders.filter((outOrder) => {
                return outOrder.outstandingOrders.id !== id;
            })
        }));

    }

    UpdatePageNumber(filter = null, pageNumber, pageSize) {

        this.setState(() => ({ pageNumber: pageNumber }));
        this.updateTable('', pageNumber, pageSize);
    };

    render() {
        return (
            this.state.outstandingOrders ?
                <Container fluid>
                    <Row>
                        <Col sm={2} style={{ paddingRight: 0 }}>
                            <SearchForm updateFunction={this.updateTable} clearsearch={this.updateTable} />
                        </Col>
                        <Col sm={10}>
                            <PurchasesTable
                                data={OustandingTableData(this.state.outstandingOrders, this.removeOutstandingOrderFromList)}
                                headers={OustandingHeaders}
                                emptyDataMessage={'No Outstandings orders were found'}
                                isLoading={false}
                            />
                            {

                                this.state.outstandingOrders && this.state.outstandingOrders.length > 0 &&
                                renderPagination(this.state.outstandingOrders[0].outstandingOrders.totalCount,
                                    this.state.pageSize,
                                    this.state.pageNumber,
                                    '',
                                    this.UpdatePageNumber)

                            }

                        </Col>
                    </Row>
                </Container>
                :
                <Loading />

        )
    }
}

const SearchForm = ({ updateFunction, clearsearch }) => {
    const inputValue = useRef(null);

    const onsubmit = (e) => {
        e.preventDefault();

        const input = inputValue.current.value.trim();
        updateFunction(input);
    }

    const onClear = () => {
        inputValue.current.value = '';
        clearsearch();
    }

    return (
        <Form onSubmit={onsubmit} style={{ marginTop: 5 }}>
            <div className='mb-3'>
                <Form.Group as={Col} md='12' controlId='searchInput' style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Form.Label>Search <InfoHoverOver information={'Search for the outstanding Purchase Orders and Invoices.'} /></Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='text'
                            placeholder={'PO Number / Invoice Number'}
                            aria-describedby='inputGroupPrepend'
                            name={'searchInput'}
                            ref={inputValue}
                        />
                        <InputGroup.Prepend type='submit' value='submit' onClick={onsubmit}>
                            <InputGroup.Text id='inputGroupPrepend'><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                        </InputGroup.Prepend>
                    </InputGroup>
                    <Form.Text className='text-muted textOnClickFunction' onClick={onClear}>
                        clear search
                </Form.Text>
                </Form.Group>
            </div>

        </Form>
    )
}

const OustandingTableData = (orders = [], removeOutstandingOrderFromList) => {
    if (orders.length === 0) return [];
    let newScheme = [];
    orders.forEach(order => {
        const orderScheme = {
            purchaseOrderNumber: (<Fragment><LinkToText linkTo={OrderPath(order.outstandingOrders.orderId)} contents={order.outstandingOrders.purchaseOrderNumber} /> <CopyToClipboard textToCopy={order.outstandingOrders.purchaseOrderNumber} /></Fragment>),
            purchaseOrderDate: DisplayUserDateTime(order.outstandingOrders.purchaseOrderDate, true), //dateTime
            invcNo: order.outstandingOrders.invoiceId ? (<Fragment><LinkToText linkTo={InvoicePath(order.outstandingOrders.invoiceId)} contents={order.reason.invcNo} />  <CopyToClipboard textToCopy={order.reason.invcNo} /></Fragment>) : (<Fragment>{order.reason.invcNo} <CopyToClipboard textToCopy={order.reason.invcNo} /></Fragment>),
            reason: reasonOutstanding(order),
            action: <ActionButton order={order} removeOutstandingOrderFromList={removeOutstandingOrderFromList} />
        };
        newScheme.push(orderScheme);
    });
    return newScheme;
};

const reasonOutstanding = (order) => {
    if (order.reason.delInd) return <span style={{ color: '#d24571' }}>Invoice has been deleted in SAM.</span>;
    if (!order.reason.dateSignOff) return <span style={{ color: '#ffbd07' }}>Invoice has not been signed off in SAM</span>;
    if (order.reason.dateSignOff) return <span style={{ color: '#34a80d' }}>Waiting to be imported into EDI</span>;
}

const OustandingHeaders = [
    {
        headerName: 'EDI Purchase Order Number',
        sortby: undefined
    },
    {
        headerName: 'EDI Purchase Order Date',
        sortby: undefined
    },
    {
        headerName: 'SAM Invoice Number',
        sortby: undefined
    },
    {
        headerName: 'Reason',
        sortby: undefined
    },
    {
        headerName: 'Action',
        sortby: undefined
    },
];


const ActionButton = ({ order, removeOutstandingOrderFromList }) => {
    //modalTypes 
    const manuallyEntered = 'Manually Entered';
    const removeInvoice = 'Remove Invoice from PO';
    const forceImportInvoice = 'Force Import';
    const [onModal, setModal] = useState({ modalType: manuallyEntered, isOpen: false });

    //modal hack
    const onModalChange = (type) => {

        if (!type) type = onModal.modalType;
        setModal({ modalType: type, isOpen: !onModal.isOpen })
    };

    const manuallyEnteredContentFunction = () => {

        SetOrderInvoiceAsManuallySubmitted(order.outstandingOrders.orderId, order.outstandingOrders.id).then((result) => {

            if (result === 1) {
                SuccessToastR('Outstanding PO has been removed.');
                removeOutstandingOrderFromList(order.outstandingOrders.id);
                onModalChange();
            }
        });
    }

    const removeInvoiceFunction = () => {
        RemoveInvoiceFromPurchaseOrder(order.outstandingOrders.orderId, order.outstandingOrders.id).then((result) => {
            if (result === 1)
            {
                SuccessToastR('The Invoice was removed.');
                removeOutstandingOrderFromList(order.outstandingOrders.id);
                onModalChange();
            }
        });
    }

    const forceImportContent = () => {
        const params = {
            TradingPartnerId: order.outstandingOrders.tradingPartnerId,
            InvoiceIds: order.outstandingOrders.samInvcKey.toString(),
            ReleaseDate: ConvertToApiFriendlyDateTime(Date.now())
        }

        ManuallyImportInvoice(params).then(result => {

            if(result.Error == false) {
                SuccessToastR("Invoices manual uploaded.");
                removeOutstandingOrderFromList(order.outstandingOrders.id)
                onModalChange();
            }
        });
    }

    const manuallyEnteredContent = () => {
        return {
            modalTitle: 'Uploaded by another method',
            modalBody: <Fragment><p>If the Invoice was uploaded via another method you are able to hide the outstanding order.</p><p>The Invoice may still be sent via EDI</p></Fragment>,
            modalFooter: <div><SaveButton contents='Hide Outstanding Order' onClickFunction={manuallyEnteredContentFunction} /><CloseButton contents='Close' onClickFunction={onModalChange} /></div>
        }
    }

    const removeInvoiceContent = () => {
        return {
            modalTitle: 'Remove Invoice from Purchase Order',
            modalBody: order.outstandingOrders.NumberOfInvoicesOnOrder > 1 ? <span style={{ color: '#d24571' }}>{order.outstandingOrders.PurchaseOrderNumber} will become unprocessed as this is the only Invoice attached to the Purchase Order</span> : 'There are multiple Invoices attached to this Purchase Order, This order will not become unprocessed',
            modalFooter: <div><SaveButton contents='Remove Invoice' onClickFunction={removeInvoiceFunction} /><CloseButton contents='Close' onClickFunction={onModalChange} /></div>
        }
    }

    const forceImportInvoiceContent = () => {
        return {
            modalTitle: 'Force Import Invoice',
            modalBody: <span>This will force the import of the Invoice, Release date will be set to now</span>,
            modalFooter: <div><SaveButton contents='Import Invoice' onClickFunction={forceImportContent} /><CloseButton contents='Close' onClickFunction={onModalChange} /></div>
        }
    }

    
    const GetModalContent = () => {
        switch (onModal.modalType) {
            case manuallyEntered: return manuallyEnteredContent();
            case removeInvoice: return removeInvoiceContent()
            case forceImportInvoice: return forceImportInvoiceContent();
            default:
                return manuallyEnteredContent();

        }
    }

    const ModalContent = GetModalContent();


    return (
        <div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={'tooltip-top'}>Uploaded via another method</Tooltip>}
            >
                <FontAwesomeIcon size='2x' icon={faExternalLinkSquareAlt} className='warningIconFunctionButtons' onClick={() => onModalChange(manuallyEntered)} />
            </OverlayTrigger>
            { order.reason.delInd && <OverlayTrigger placement="bottom" overlay={<Tooltip id={'tooltip-top'}>Remove Invoice from PO</Tooltip>}>
                <FontAwesomeIcon size='2x' icon={faTrash} className='dangerIconFunctionButtons' onClick={() => onModalChange(removeInvoice)} />
            </OverlayTrigger>
            }
            {
                order.reason.dateSignOff && !order.reason.delInd &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={'tooltip-top'}>Force Import</Tooltip>}>
                    <FontAwesomeIcon size='2x' icon={faPaperPlane} className='successIconFunctionButtons' onClick={() => onModalChange(forceImportInvoice)} />
                </OverlayTrigger>
            }
            <DefaultModal isOpen={onModal.isOpen} changeModelState={onModalChange} {...ModalContent} />
        </div>);
}
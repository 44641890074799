import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/chart/pie';
import { GetEChartTheme } from './../../../../Style/ECharts/GetTheme';
import { PostReportAPI } from './../../../../FetchApi/Reports';
import { Spinner } from 'react-bootstrap';

const pieChartData = (data) => {

    const names = Object.keys(data);
    const values = Object.values(data);

    var seriesData = [];

    for (let i = 0; i < names.length; i++) {
        seriesData.push({
            name: names[i],
            value: values[i]
        });

    }



    return {
        names,
        seriesData
    };
};

export const PieChartOptions = (data, props) => {
    return {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 20,
            top: 50,
            bottom: 20,
            data: data.names,
            selected: data.names
        },
        toolbox: {
            feature: {
                saveAsImage: {
                    title: 'Download'
                }
            }
        },
        series: [
            {
                name: props.title,
                type: 'pie',
                radius: '55%',
                center: ['40%', '50%'],
                data: data.seriesData,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
};

export const PieChart = (props) => {
    const [pieData, setPieData] = useState(undefined);
    //const [queryOptions, SetQueryOptions] = useState(props.queryOptions);

    const callfetchAPi =
        () => PostReportAPI(props.fetchAPI, props.queryOptions).then((result) => { setPieData(...result); });

    const didMountRef = useRef(false);

    useEffect(() => {

        if (!didMountRef.current) {
            didMountRef.current = true;
            callfetchAPi();
        };

        // if(JSON.stringify(queryOptions) !== JSON.stringify(props.queryOptions)){
        //     SetQueryOptions(props.queryOptions);
        //     callfetchAPi();
        // };
    });

    if (pieData) {
        const pieresult = pieChartData(pieData);
        const options = PieChartOptions(pieresult, props);

        return (
            <Fragment>
                <ReactEcharts
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={GetEChartTheme()}
                    //onChartReady={callback}




                    //onEvents={EventsDict}




                    //opts={} 
/>
            </Fragment>);
    } else {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }
};
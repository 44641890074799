
import * as jwt from 'jsonwebtoken';
import { isNotEmpty } from './../Globals/FunctionsTools';

const UserHash = 'Userhash';
const EDIUser = 'EDIUser';
//const IsDark = 'IsDark'; //ToDo add to theme.js

const CreateJWTForLocal = (data) => {
    return jwt.sign(JSON.stringify(data), process.env.REACT_APP_JWTToken);
}

const GetJWTData = (token) => {

    if(isNotEmpty(token)) return jwt.verify(token, process.env.REACT_APP_JWTToken);

    return null;
}


export const CreateEDIUserLocal = (user) => {
    if(isNotEmpty(user)) localStorage.setItem(EDIUser, CreateJWTForLocal({...user}))
} 

export const GetEDIUserLocal = () => {
    return GetJWTData(localStorage.getItem(EDIUser));
}

export const GetEDIUserTokenLocal = () => {
    const userProfile = GetEDIUserLocal();
   return userProfile ? userProfile.token : null;
}

export const CreateUserHash = () => {
    localStorage.setItem(UserHash, window.location.hash);
}

export const GetUserHash = () => {
    return localStorage.getItem(UserHash);
}

export const DeleteEDIUserLocal = () => {
    localStorage.removeItem(EDIUser);
    localStorage.removeItem(UserHash);
}

export const ClearAllLocal = () => {
    localStorage.clear();
}
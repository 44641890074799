import React, { Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import { Row, Col, Container, Table } from 'react-bootstrap';
import * as moment from 'moment';
import * as Barcode from 'react-barcode';

import { GetTradingPartnerLogo } from './../../Images/TradingPartnerLogos/TradingPartnerLogoSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

export class PrintPurchaseOrder extends React.Component {
    render() {

        return (

            <Fragment>
                <ReactToPrint
                    trigger={() => 
                        <div className="OptionIcon">
                            <FontAwesomeIcon icon={faPrint} size="3x" style={{ width: '100%' }} />
                        </div>
}
                    content={() => this.componentRef}
                    documentTitle={this.props.purchaseOrderNumber} />
                <div style={{ display: 'none', backgroundColor: '#fff' }}>
                    <ContentToPrint ref={el => (this.componentRef = el)} {...this.props} />
                </div>

            </Fragment>
        );
    };
}

class ContentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.NumberWithCommas = this.NumberWithCommas.bind(this);

    }

    NumberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    render() {
        let billTo = this.props.addresses.filter((address) => {
            if (address.addressTypeCode === 'BY') {
                return address;
            }
            return null;
        });
        if (billTo.length === 0)
            billTo = this.props.addresses.filter((address) => {
                if (address.addressTypeCode === 'BT') {
                    return address;
                }
                return null;
            });
        const isRushOrdercarrierRouting = this.props.carrierInfos.filter((carrierInfos) => {
            if (carrierInfos.carrierRouting === 'EMERGENCYUPSNSU') {
                return carrierInfos;
            }
            return null;
        });
        const isRushOrder = isRushOrdercarrierRouting.length > 0 ? true : false;

        return (
            <Container style={{ backgroundColor: '#fff', height: '100%' }}>
                <Row>
                    <Col style={{ padding: 20 }} xs={5}>
                        {GetTradingPartnerLogo(this.props.tradingPartnerId, this.props.tradingPartnerName, 600, 400)}

                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            {

                                this.props.contacts.length > 0 && this.props.contacts.map((contact, key) => contact.contactTypeCode === 'Buyer Name or Department' ? <BuyerContact key={key} primaryPhone={contact.primaryPhone} contactName={contact.contactName} /> : <Fragment key={key} />)
                            }
                            {
                                this.props.contacts.length > 0 && this.props.contacts.map((contact, key) => contact.contactTypeCode === 'EX' ? <BuyerContactExpeditor key={key} primaryPhone={contact.primaryPhone} contactName={contact.contactName}/> : <Fragment key={key}/>) //ToDo Update EX code in database
                            }

                            <p>Delivery Reference: {DeliveryReference(this.props.references)}</p>
                            <p style={{ marginTop: 5, textDecoration: 'underline' }}>SUPPLIER: {isRushOrder &&
                                <span style={{ float: 'right', textDecoration: 'none', fontWeight: 400 }}>Rush Order</span>}</p>
                            <p>ALS TRIBOLOGY<br />
                                <span>ID: 031677859</span>
                            </p>

                            <p>123 NW FRONT AVE<br /><span>PORTLAND, OR 97210 US</span></p>
                            {this.props.carrierInfos.length > 0 &&
                                this.props.carrierInfos.map((carrier, key) => (
                                    <span key={key} style={{ fontWeight: 400, fontSize: 14 }}>Delivery Carrier:  {carrier.carrierRouting}<br />
                                    </span>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xs={7}>
                        <Row style={{ marginTop: 45, marginBottom: 20 }}>
                            <Col >Purchase Order</Col>
                            <Col >
                                <Barcode value={this.props.purchaseOrderNumber}
                                    height={40}
                                    displayValue={true}
                                    width={2}
                                    format={'CODE128'}
                                    font={'monospace'}
                                    marginTop={5}
                                    marginBottom={5}
                                    marginLeft={30}
                                    marginRight={30}
                                    background={'#ffffff'}
                                    lineColor={'#000000'}
                                />
                            </Col>
                            <Col>{moment(new Date().toString()).format('L')}</Col>

                        </Row>
                        <Row>

                            <Table bordered>
                                <thead style={{ textAlign: 'center', backgroundColor: '#EEEEEE' }}>
                                    <tr>
                                        <th style={{ padding: 6 }}>PO TYPE</th>
                                        <th style={{ padding: 6 }}>PO NUMBER</th>
                                        <th style={{ padding: 6 }}>PO DATE</th>
                                        <th style={{ padding: 6 }}>NET</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td style={{ padding: 4 }}>{this.props.primaryPOTypeCode}</td>
                                        <td style={{ padding: 4 }}>{this.props.purchaseOrderNumber}</td>
                                        <td style={{ padding: 4 }}>{moment(this.props.purchaseOrderDate).format('L')
                                        }</td>
                                        <td style={{ padding: 4 }}>NET 30</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div style={{ paddingLeft: 45, paddingTop: 40, fontWeight: 500 }}>
                                {
                                    this.props.addresses.map((address, key) => {
                                        if (address.addressTypeCode === 'ST') {
                                            return <ShipTo key={key} {...address} />;
                                        } else return <Fragment key={key} />;
                                    })
                                }
                                <br />
                                {
                                    billTo.map((address, key) => { return <BillTo key={key}{...address} />; })
                                }

                            </div>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Table borderless>
                        <thead style={{ border: 'solid 1px #000', textAlign: 'center' }}>
                            <tr>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Item No.</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>QTY</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Unit</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Buyer Part No</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Vendor Part No.</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Unit Price</th>
                                <th style={{ border: 'solid 1px #000', padding: 4 }}>Line Total</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center', fontWeight: 400 }}>
                            {this.props.lineItems.map(
                                (lineItem, key) => <LineItemsTable key={key} NumberWithCommas={this
                                    .NumberWithCommas} {...lineItem} />)}
                        </tbody>
                    </Table>
                </Row>
                <p style={{ bottom: 0, right: 10, position: 'absolute', fontWeight: 500 }}>
                    Purchase Order Total: ${
                        this.NumberWithCommas(parseFloat(this.props.totalAmount).toFixed(2))}
                </p>
            </Container>
        );
    }
}

const DeliveryReference = (references) => {

    if (references.length === 0) return null;

    const referenceArray = references.find(ref => ref.referenceQual === 'Delivery Reference');

    if (!referenceArray) return null;

    return referenceArray['referenceId'];
    
}

const BuyerContact = (props) => {
    return (
        <Fragment>
            <p style={{ paddingTop: 30 }}>Buyer: {props.contactName} <br /> <span>Telephone: {props.primaryPhone}</span></p>
        </Fragment>);
};
const BuyerContactExpeditor = (props) => {
    return (
        <Fragment>
            <p>Expeditor: {props.contactName} <br /> <span>Telephone: {props.primaryPhone}</span></p>
        </Fragment>
        )
}

const ShipTo = (props) => {
    return (
        <Fragment>
            <p>
                <span style={{ marginTop: 5, textDecoration: 'underline' }}>SHIP TO:</span><br />
                <span>{props.addressName}</span><br />
                <span>ID: {props.addressLocationNumber} </span>
            </p>

            <p>
                <span>{props.address1}</span><br />
                <span>{props.city}, {props.state}, {props.country}, {props.postalCode}</span>
            </p>
        </Fragment>
    );
};

const BillTo = (props) => {
    return (
        <p>
            <span style={{ marginTop: 5, textDecoration: 'underline' }}>BILL TO: </span><br />
            <span>{props.addressName}</span><br />
            <span>ID: {props.addressLocationNumber}</span><br />
            <span>C/O: {props.addressAlternateName}</span><br />
            <span>{props.address1}</span><br />
            <span>{props.city}, {props.state}, {props.country}, {props.postalCode}</span>
        </p>
    );
};

const LineItemsTable = (props) => {
    return (
        <tr>
            <td style={{ padding: '7px 7px' }}>{props.lineSequenceNumber}</td>
            <td style={{ padding: '7px 7px' }}>{props.orderQty}</td>
            <td style={{ padding: '7px 7px' }}>{props.orderQtyUOM}</td>
            <td style={{ padding: '7px 7px' }}>{props.buyerPartNumber}</td>
            <td style={{ padding: '7px 7px' }}>{props.partNumber}</td>
            <td style={{ padding: '7px 7px' }}>
                ${props.NumberWithCommas(parseFloat(props.purchasePrice).toFixed(2))
                }
            </td>
            <td style={{ padding: '7px 7px' }}>
                ${props.NumberWithCommas(parseFloat(props.totalAmount).toFixed(2))
                }
            </td>
        </tr>);
};

import React from 'react';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import ReportOptionTemplate from '../ReportOptionTemplate';
import { HealthStatusReport } from '../../Reports/HealthStatusReport';

export const reportName = 'HealthStatusSummary';

export const HealthStatusOption = (props) => {
    const queryOptions = [
        {
            name: 'Include next check date',
            queryName: 'nextCheck',
            queryValue: false,
        }
    ];

    return ReportOptionTemplate(reportName,
        'Health Status',
        'Clearly displaying the system is working as intended',
        'GetStatusDates',
        queryOptions,
        faHeartbeat,
        props,
        '#e60000');
};

export const HealthStatusSummary = (props) => {

    return (<HealthStatusReport {...props} title="Health Status" subtext={
'Clearly displaying the system is working as intended'} titlePosition="left"/>);
};
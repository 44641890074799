import React from 'react';
import { Table } from 'react-bootstrap';
import { SortByIcon } from './SortByFunctions';


export const PurchasesTable = ({ data = [], headers = [{ headerName: 'header', sortByName: 'sortbyNameHere', style: {} }], sortByFunction, activeSortBy, emptyDataMessage = 'data is found.', isLoading = false,stickyHeading = false }) => {

    if (isLoading) return <div />;
    if(data.length === 0) return <div style={{ padding: 10 }}>{emptyDataMessage}</div>;

    const onClickSortByFunction = (sortByName) => {
        if (sortByFunction && sortByName) sortByFunction(sortByName);
    };


    const defaultStyle = {
        width: '10%'
    };

    const bodyColumnSize = [];

    return (
        data.length > 0 ?
            (<div className='tableHeight'>
                <Table responsive>
                    <thead className='theadSticky' style={stickyHeading ? { width: "99.3%"} : null}>
                            <tr>
                                {headers.map((header, key) => {
                                    bodyColumnSize.push(header.style)
                                    return <th style={header.style ? header.style : defaultStyle} className={header.sortby ? 'clickOnFunction' : null} key={key} onClick={() => onClickSortByFunction(header.sortby)}>{header.headerName}{SortByIcon(activeSortBy, header.sortby)}</th>
                                })}

                            </tr>
                       
                    </thead>
                    <tbody className='tbodySticky'>
                        {
                            data.map((rowData, rowKey) => (
                                <tr key={rowKey} className='theadSticky'>
                                    {
                                        Object.values(rowData).map((obj, key) =>
                                            <td style={bodyColumnSize[key] ? bodyColumnSize[key] : defaultStyle} key={key}>
                                                {obj}
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>) : (<div style={{ padding: 10 }}>{emptyDataMessage}</div>)
    );
};
import React from 'react';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import ReportOptionTemplate from '../ReportOptionTemplate';
import { PieChart } from '../../Reports/PieChartReport';

export const reportName = 'OrderSummary';

export const PieChartOrderSummaryOption = (props) => {
    const queryOptions = [
        {
            name: 'Days ago',
            queryName: 'daysAgo',
            queryValue: 7,
            minAmount: 0,
            minAmountComment: '(Today)'
        }
    ];

    return ReportOptionTemplate(reportName,
        'Orders Pie Chart',
        'Accepted, Rejected and Unprocessed Orders in a pie graph',
        'GetOrdersPieChart',
        queryOptions,
        faChartPie,
        props,
        '#77BFC3');
};

export const PieChartOrderSummary = (props) => {

    return (<PieChart {...props} title="Recent Orders" subtext={`Orders from the last ${props.queryOptions[0].queryValue
        } day/s ago`} titlePosition="left"/>);
};
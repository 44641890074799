import React, { useState, useEffect, useRef } from 'react';
import { PostReportAPI } from './../../../../FetchApi/Reports';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Col, Row, Table, Alert } from 'react-bootstrap';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import { ExportCSV } from './../../../../components/Common/CSVExport';
import { DisplayUserDateTime } from '../../../../Globals/UserDateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faEllipsisV, faSync } from '@fortawesome/free-solid-svg-icons';

export const LiveExport = (props) => {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: '#000'
        })
    };

    const [optionIsOpen, setOptionIsOpen] = useState(false);
    const [exportWarning, setExportWarning] = useState(false);
    const [exportOptions, setExportOptions] = useState(
        {
            typeFilter: undefined,
            tradingPartners: undefined,
            dateRange: {
                date: [new Date(new Date().setDate(new Date().getDate() - 3)), new Date()],
                daysAgoValue: 3
            }
        }
    );
    const [exportData, setExportData] = useState(undefined);

    const ChangeSetOptionIsOpen = () => {
        setOptionIsOpen(!optionIsOpen);
    };

    const Types = [
        { value: 'IsUnprocessed', label: 'Unprocessed Orders' },
        { value: 'IsAccepted', label: 'Accepted Orders' },
        { value: 'IsRejected', label: 'Rejected Orders' },
        { value: 'IsPendingInvoice', label: 'Pending Invoices' },
        { value: 'IsSentinvoice', label: 'Invoices' },
    ];

    const daysAgoOptions = [
        { value: 1, label: 'Last 24 Hours' },
        { value: 3, label: 'Last 3 Days' },
        { value: 7, label: 'Last 7 Days' },
    ];

    const callfetchAPi =
        (queryOptions, fetchAPI) => PostReportAPI(fetchAPI, queryOptions).then((result) => { setExportData(result); });

    const didMountRef = useRef(false);

    const CreateFetchRequestParms = () => {
        const typeFilters = [];

        for (let i = 0; i < Types.length; i++) {
            if (exportOptions.typeFilter) {
                typeFilters.push({
                    queryName: Types[i].value,
                    queryValue: exportOptions.typeFilter.includes(Types[i])
                });
            } else {
                typeFilters.push({ queryName: Types[i].value, queryValue: null });
            }
        }

        let tradingPartnerValue = '';

        if (exportOptions.tradingPartners) {
            const tradingPartnerIds = [];
            for (let i = 0; i < exportOptions.tradingPartners.length; i++) {
                tradingPartnerIds.push(exportOptions.tradingPartners[i].value);
            }

            tradingPartnerValue = tradingPartnerIds.join(',');
        }

        const optionsQueryMinusTypes = [
            {
                queryName: 'tradingPartners',
                queryValue: tradingPartnerValue
            }, {
                queryName: 'startDate',
                queryValue: exportOptions.dateRange.date[0].toJSON()
            }, {
                queryName: 'endDate',
                queryValue: exportOptions.dateRange.date[1].toJSON()
            }
        ];

        return optionsQueryMinusTypes.concat(typeFilters);
    };

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            const prams = CreateFetchRequestParms();
            callfetchAPi(prams, props.fetchAPI);
        }
    });

    const animatedComponents = makeAnimated();

    const tradingPartnerInput = (input) => {
        var exportValues = exportOptions;
        exportValues.tradingPartners = input;
        setExportOptions(exportValues);
    };

    const typeFilter = (input) => {
        const exportValues = exportOptions;
        exportValues.typeFilter = input;
        setExportOptions(exportValues);
    };

    const daysAgo = (daysAgoValue) => {

        const dateRangeValue = [new Date(new Date().setDate(new Date().getDate() - daysAgoValue)), new Date()];

        const exportValues = {
            ...exportOptions,
            dateRange: {
                date: dateRangeValue,
                daysAgoValue: daysAgoValue
            }
        };

        setExportOptions(exportValues);
    };

    const ChangeDateRange = (dateRange) => {

        const exportValues = {
            ...exportOptions,
            dateRange: {
                date: dateRange,
                daysAgoValue: -1
            }
        };

        setExportOptions(exportValues);
    };

    const OnExport = () => {
        const headers =
            'Date, Order Type, Order Number, TradingPartner Name, Currency, Reference Number, Department, Order Date, Number of Line Items, Total Amount, Urgent ';
        const optionsQuery = CreateFetchRequestParms();
        PostReportAPI('GetLiveExportCSV', optionsQuery)
            .then((result) => (ExportCSV(result,
                `LiveExportSummary-${moment(new Date()).format('DDMMYYYYhhmmss')}`,
                headers))).then(() => setExportWarning(true));
    };

    const RefreshSummary = () => {

        const prams = CreateFetchRequestParms();

        callfetchAPi(prams, props.fetchAPI);
    };

    const tradingPartners = () => {

        const partners = [];
        props.tradingPartners.forEach(partner => {
            partners.push({
                value: partner.Id,
                label: partner.TradingPartnerName
            });
        });
        return partners;
    };

    return (
        <div className="LiveExportBase">
            <h3 className="LiveExportBaseHeader">
                <b>Export into CSV</b>
                <span onClick={OnExport
                } className="LiveExportHeadingButtons">
                    <FontAwesomeIcon icon={faFileDownload}/>
                </span>
                <span onClick={
                    ChangeSetOptionIsOpen} className="LiveExportHeadingButtons">
                    <FontAwesomeIcon icon={faEllipsisV}/>
                </span>
                <span onClick={
                    RefreshSummary} className="LiveExportHeadingButtons">
                    <FontAwesomeIcon icon={faSync}/>
                </span>
            </h3>
            {
                // old buttons <span onClick={OnExport} className='LiveExportHeadingButtons'>Export</span><span onClick={ChangeSetOptionIsOpen} className='LiveExportHeadingButtons'>Options</span><span onClick={RefreshSummary} className='LiveExportHeadingButtons'>Refresh</span>
                optionIsOpen &&
                    <div style={{ borderBottom: 'soild 1px #FFF' }}>
                        <Row>
                            <Col xl="6">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    onChange={tradingPartnerInput}
                                    // defaultValue={TradingPartnerdata}


                                    placeholder="Trading Partner"
                                    isMulti
                                    options={tradingPartners()}
                                    styles={customStyles}/>
                            </Col>
                            <Col xl="6">
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    onChange={typeFilter}
                                    //defaultValue={Types}


                                    placeholder="Type"
                                    isMulti
                                    options={Types}
                                    styles={customStyles}/>
                            </Col>

                            <Col xl="6" style={{ marginTop: 10 }}>
                                <div style={{ margin: '3%', textAlign: 'center' }}>
                                    <DateRangePicker
                                        onChange={ChangeDateRange}
                                        value={exportOptions.dateRange.date}
                                        clearIcon={null}
                                        showLeadingZeros={true}/>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    {
                                        daysAgoOptions.map(
                                            (daysagoOption, key) => (<span key={key} onClick={() =>
                                                daysAgo(daysagoOption.value)} className={
                                                exportOptions.dateRange.daysAgoValue === daysagoOption.value
                                                    ? 'LiveExportRadioOptionsActive'
                                                    : 'LiveExportRadioOptions'
                                            }>{daysagoOption.label}</span>))
                                    }
                                </div>
                            </Col>

                        </Row>
                    </div>

            }
            <div style={{ padding: '0 10px' }}>
                <Row>
                    {exportWarning &&
                        <Col sm={12}>
                            <Alert variant="warning" onClose={() => setExportWarning(false)} dismissible>
                                Date Time is as stored, It is not converted to users date time
                            </Alert>
                        </Col>
                    }
                    {exportData &&
                        exportData.length >= 1 &&
                        <Table responsive>
                            <thead style={{ textAlign: 'center' }}>
                            <tr>
                                <th>Date</th>
                                <th>Id</th>
                                <th>Trading Partner</th>
                                <th>Type</th>
                            </tr>
                            </thead>
                            <tbody style={{ textAlign: 'leftcenter' }}>
                            {
                                exportData.map((item, key) => (
                                    <tr key={key}>
                                        <td>{DisplayUserDateTime(item.DataDate)}</td>
                                        <td>
                                            <Link to={item.Link}>{item.DataNumber}</Link>
                                        </td>
                                        <td>{item.TradingPartnerName}</td>
                                        <td style={{ textAlign: 'right' }}>{item.DataType}</td>
                                    </tr>))
                            }
                            </tbody>
                        </Table>}
                    {
                        exportData &&
                            exportData.length === 0 &&
                            <div style={{ textAlign: 'center' }}>No data to export.</div>
                    }
                </Row>
            </div>
        </div>
    );
};
import React from 'react';
//import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
//import { swap, move, bind } from 'react-grid-dnd';
import { Container, Row, Col } from 'react-bootstrap';
import { ReportGridList } from './GridOptions/ReportGridList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ReportGrid = (props) => {

    // const [gridSettings, SetGridSettings] = useState({
    //   boxesPerRow: 2,
    //   rowHeight: 100,
    //   disabledDrag: 'false',
    //   disableDrop: 'false',
    // });

    // const onChange = (id, input1, input2, input3, input4) => {

    //   // this meant to do nothing
    //   if(id === input4){
    //     SetGridSettings({
    //       boxesPerRow: 2,
    //       rowHeight: 100,
    //       disabledDrag: 'false',
    //       disableDrop: 'false',
    //     })
    //   }
    // }

    //GridDropZone
    // boxesPerRow: number;
    // rowHeight: number;
    // id: string;
    // children: React.ReactNodeArray;
    // disableDrag?: boolean;
    // disableDrop?: boolean;
    // style?: React.CSSProperties;

    const reportList = ReportGridList(props);

    return (
        <Container>
            <Row>
                {reportList &&
                    reportList.map((report, key) => (
                        <ReportsLayout report={report} key={key} updateGrid={props.updateGrid} />
                    ))}
            </Row>
        </Container>
    );
};

const ReportsLayout = (props) => {
    //const [isRemove, setIsRemove] = useState(false);

    return (<Col lg={6} style={{padding: 5}}>
        <div
           //onMouseEnter={() => setIsRemove(true)}
            //onMouseLeave={() => setIsRemove(false)}
            className="grid-item">
            <div className="grid-item-content">
                {
                    <div style={{padding: 5}}>
                        <span><FontAwesomeIcon onClick={() => props.updateGrid('delete', props.report.props.id)} className="removeReport" icon={faTimes} /><h4>{props.report.props.title}</h4></span>
                        <p>{props.report.props.subtext}</p>
                        
                    </div>
                }
                {props.report}
            </div>
        </div>
    </Col>);
};

// return (
//   <GridContextProvider onChange={onChange}>
//     <Container>

//           <GridDropZone
//             className='dropzone left'
//             id='mainReportPage'
//             boxesPerRow={gridSettings.boxesPerRow}
//             rowHeight={gridSettings.rowHeight}
//             disableDrag={gridSettings.disabledDrag}
//             disableDrop={gridSettings.disableDrop}
//           >
//             {
//               reportList && reportList.map((report, key) => (
//                 <GridItem key={key}>
//                 <div className='grid-item'>
//                   <div className='grid-item-content'>{report}</div>
//                 </div>
//               </GridItem>
//               ))
//             }

//           </GridDropZone>
//     </Container>
//   </GridContextProvider>
// );
// }

export default ReportGrid;
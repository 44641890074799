/* global fetch */
//import { adalInstance, getAdalUser, newToken } from './../Security';
import { GetCachedToken, LoginAdalUser, GetAdalCachedUser, newToken } from './../UserAuthentication/AdalAuthentication';
import { toast } from 'react-toastify';
import { GetEDIUserTokenLocal } from './../Globals/LocalStorage';

async function BaseApi(url, method, body) {

    let bearer = GetCachedToken();

    /// remove all of this and replace it with something nicer
    if (!bearer) {

        const validUser = GetAdalCachedUser();
        if (validUser) {
            //bearer = refreshToken();
            newToken();

            //authenticateToken();
            bearer = GetCachedToken();
        }
    }


    if (bearer) {

        const init = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`,
                'Token': GetEDIUserTokenLocal()
            }
        };

        if (body) {
            init.body = JSON.stringify(body, (key, value) => {
                if (value !== null) return value});
            }

        //console.log(init);
        //console.log(url);

        let result = await fetch(url, init);

        if (result.ok) {

            result = await result.text();

            if (result === '') {
                return undefined;
            }

            return JSON.parse(result);

        };

        const error = result.headers.get('ErrorMessage');

        if (error) {
            toast.error(error,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
        }
    } else {
        LoginAdalUser();
    }

}

export default BaseApi;
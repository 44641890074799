import React from 'react';
import { Container } from 'react-bootstrap';
import GridBase from './DashGrid/GridBase';

const Dashboard = (props) => {
    return (
        <Container>
            
            <GridBase EDIProfile={props.EDIProfile} />
            
        </Container>)
}

export default Dashboard;
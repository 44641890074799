import React from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';

const ActiveUser = (props) => {

    return (
        <Row>
            <Col xs="4">
                <span>{props.firstName} {props.lastName}</span><br/>
                <span>{props.email}</span>
            </Col>
            <Col xs="4" style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '0.9vw' }}>{props.roleName}</span>
                <Dropdown>
                    <Dropdown.Toggle as="button" className="EditButton" disabled={props.currentUserId === props.id
}>Edit</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {!props.Roles && props.GetSetRoles()}
                        {props.Roles &&
                            props.Roles.map(
                                (role, key) => (<Dropdown.Item key={key} onClick={() => props.SetRole(props.id, role.id)
}>{role.roleName}</Dropdown.Item>))}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs="4" style={{ margin: 'auto', textAlign: 'center' }}>
                <button
                    disabled={props.currentUserId === props.id}
                    onClick={() => props.ChangeUserIsActive(props.id, props.isActive ? false : true)}
                    className={props.isActive ? 'DeleteButton' : 'CreateButton'}>
                    {props.isActive ? 'Deactivate' : 'Activate'}
                </button>

                {props.currentUserId === props.id &&
                    (<div>
                         <span style={{ fontSize: 10 }}>You can not disable/enable your own account.</span>
                     </div>)}
            </Col>
        </Row>
    );
};

export default ActiveUser;
import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Glyphicon from '@strongdm/glyphicon';
import * as momenttz from 'moment-timezone';

const TimeZoneModal = (props) => {

    const timeZoneNames = momenttz.tz.names();

    const timeZoneList = [];
    timeZoneNames.forEach(timeZoneName => {
        const offset = momenttz.tz(timeZoneName).format('Z');
        const newtimeZone = {
            TimeZoneName: `${offset} ${timeZoneName}`,
            TimeZone: timeZoneName,
            Offset: offset
        };
        timeZoneList.push(newtimeZone);

    });

    function compare(a, b) {
        const OffsetA = parseFloat(a.Offset.replace(':', '.'));
        const OffsetB = parseFloat(b.Offset.replace(':', '.'));

        let comparison = 0;
        if (OffsetA > OffsetB) {
            comparison = 1;
        } else if (OffsetA < OffsetB) {
            comparison = -1;
        }
        return comparison;
    }

    timeZoneList.sort(compare);

    const [modalState, setModalState] = useState(false);
    const handleModal = () => setModalState(!modalState);

    const [timeZoneSelected, setTimeZone] = useState(undefined);
    const handleSelected = (zone) => { setTimeZone(zone); };

    const [timeZones, setTimeZones] = useState(timeZoneList);

    const handleTimeZones = (newtimezones) => setTimeZones(newtimezones);

    const searchTimeZone = (e) => {
        e.preventDefault();

        const input = e.target.elements.searchBar.value.trim().toLowerCase();

        if (input) {
            const newTimesZones = timeZoneList;

            const newTimesZonesList = newTimesZones.filter((zone) => {
                const result = zone.TimeZoneName.toLowerCase().includes(input);
                if (result) return zone;
                return null;
            });

            handleTimeZones(newTimesZonesList);
        } else {
            handleTimeZones(timeZoneList);
        }
    };

    const SaveTz = () => {

        if (timeZoneSelected) {
            props.ProfileChange({
                TimeZone: timeZoneSelected.TimeZone,
                TimeZoneOffSet: momenttz.tz(timeZoneSelected.TimeZone).format('Z')
            });
            handleModal();
        }
    };

    return (
        <div>
            <span className="PSDButton" style={{ marginLeft: 10 }} onClick={handleModal}>{props.buttonName}</span>

            <Modal
                show={modalState}
                onHide={handleModal}
                animation={true}
                scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Time Zones</Modal.Title>
                </Modal.Header>
                <Form onSubmit={searchTimeZone}>
                    <input type="text" placeholder="Search" name="searchBar" style={{ width: '93.5%', paddingLeft: 10 }
}/>
                    <button type="submit" name="submit">
                        <Glyphicon glyph="search"/>
                    </button>
                </Form>
                <Modal.Body className="modalScroll">
                    <div>
                        {timeZones.map((zone, key) => (
                            <Row onClick={() => handleSelected(zone)} key={key} className={zone === timeZoneSelected
                                ? 'settingTimeZoneBlock settingTimeZoneBlockActive'
                                : 'settingTimeZoneBlock'}>
                                <Col sm={12}>
                                    <div>{zone.TimeZoneName}</div>
                                </Col>
                            </Row>))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleModal}>Close</Button>
                    <Button onClick={SaveTz}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TimeZoneModal;
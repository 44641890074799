import React from 'react';
import { Col } from 'react-bootstrap';
import { ToggleSwitch } from './index';

const TradingPartnerOptions = (props) => {

    return (
        <Col md={3}>
            <p>{props.tradingPartnerName}</p>
            <ToggleSwitch defaultChecked={props.isActive} onChange={() => props.SaveUserReports(props.tradingPartnerId,
                !props.isActive)}/>
        </Col>
    );
};

export default TradingPartnerOptions;
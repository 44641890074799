import React, { Fragment, useState } from 'react';
import {  faFileInvoice, faFileInvoiceDollar, faFileAlt, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { Col, Row,  Modal, Button } from 'react-bootstrap';
import { XMLModal } from './../Common/XMLModal';
import { SuccessToastR } from './../../Globals/ToastrReponses';
import { AuditById } from './../Order/AuditById';
import { DisplayUserDateTime } from './../../Globals/UserDateTime';
import { OptionsIcons } from './../DisplayBlocks';


import { GetOrderXMLById, GetOrderAckXMLByOrderId, GetInvoiceXMLByInvoiceId } from './../../FetchApi/Order';
import { GetAuditByInvoiceId, SetInvoiceForResend } from './../../FetchApi/Invoice';

export const InvoiceOptions = (props) => {
    const style = { padding: 10 };
    return (
        <Row style={{height: '100%'}}>
            {props.orderId &&
                <Col lg={2} style={style}>
                    <XMLModal extraClassName='OptionInvoice invoiceOptions' icon={faFileAlt} modalTitle='Purchase Order Message' fetchApi={() => GetOrderXMLById(props.orderId)} />
                </Col>}
            {props.orderId &&
                <Col lg={2} style={style}>
                    <XMLModal extraClassName='OptionInvoice invoiceOptions' icon={faFileInvoice} modalTitle='Order Ack Message' fetchApi={() => GetOrderAckXMLByOrderId(props.orderId)} />
                </Col>}
            {props.messageXMLId &&
                <Col lg={2} style={style}>
                    <XMLModal extraClassName='OptionInvoice invoiceOptions' icon={faFileInvoiceDollar} modalTitle='Invoice Message' fetchApi={() => GetInvoiceXMLByInvoiceId(props.id)} />
                </Col>}
            <Col lg={2} style={style}>
                <AuditById orderId={props.id} tooltip='Invoice History' modalTitle='Invoice Audit' updateFetch={GetAuditByInvoiceId} extraClassName='OptionInvoice invoiceOptions'/>
            </Col>

            <Col lg={2} style={style}>
                <ResendInvoice invoiceId={props.id} extraClassName='OptionInvoice invoiceOptions' />
            </Col>

        </Row>
    )
}

const ResendInvoice = ({invoiceId, extraClassName}) => {

    const [isOpen, setisOpenModal] = useState(false);

    const changeModal = () => {
        setisOpenModal(!isOpen);
    };

    const resendingInvoice = () => {
        SetInvoiceForResend(invoiceId).then((result) => {
            if (result) {
                SuccessToastR(`Invoice will be resent at ${DisplayUserDateTime(result, true, true)} `);
            }
        });
        setisOpenModal(!isOpen);
    };

    const PromotModal = () => {
        return (
            <Modal show={isOpen} onHide={changeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Resend Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to resend this Invoice?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={changeModal}> No </Button>
                    <Button variant="success" onClick={resendingInvoice}> Yes </Button>
                </Modal.Footer>
            </Modal>);
    };

    return (
        <Fragment>
            {OptionsIcons(faFileImport, 'Resend Invoice', changeModal, extraClassName)}
            <PromotModal />
        </Fragment>
    );
};

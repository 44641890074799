import React from 'react';
import { Table } from 'react-bootstrap';

export const BasicTable = ({ data = [], headers = [{ headerName: 'header', sortByName: 'sortbyNameHere', style: {} }], emptyDataMessage = 'data is found.', isLoading = false,stickyHeading = false }) => {

    if (isLoading) return <div />;
    if(data.length === 0) return <div style={{ padding: 10 }}>{emptyDataMessage}</div>;

    return (
        data.length > 0 ?
            (<div className='table-wrapper'>
                <Table responsive>
                    <thead>
                            <tr>
                                {headers.map((header, key) => {
                                    return <th key={key} >{header.headerName}</th>
                                })}

                            </tr>
                       
                    </thead>
                    <tbody>
                        {
                            data.map((rowData, rowKey) => (
                                <tr key={rowKey}>
                                    {
                                        Object.values(rowData).map((obj, key) =>
                                            <td key={key}>
                                                {obj}
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>) : (<div style={{ padding: 10 }}>{emptyDataMessage}</div>)
    );
};
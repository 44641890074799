import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { GetInvoiceFilterList, GetInvoiceList } from '../FetchApi/Invoice';
import { FilterByDateRange, FilterByTextInput, FilterById, FilterBar, FilterByIdWithSubIds, QuickSearch } from '../components/FilterBar/index';
import { CreateInvoiceSqlParams } from '../components/FilterBar/FilterBarMapping';
import { faFileInvoiceDollar, faFile, faCalendarAlt, faHandshake, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons';
import { LinkToButton } from '../components/Common/Buttons';
import { OrderPath, InvoicePath } from '../Globals/RoutePaths';
import { SortByLogic, SortByDefault, renderPagination, PurchasesTable } from '../components/Table/index';
import { ReturnDollarValueWithCommon } from '../components/Common/MoneyValue';
import { DisplayUserDateTime } from '../Globals/UserDateTime';


export default class InvoicesTable extends Component {
    constructor(props) {
        super(props)

        this.OnClickSortOrder = this.OnClickSortOrder.bind(this);
        this.UpdateTableData = this.UpdateTableData.bind(this);
        this.UpdatePageNumber = this.UpdatePageNumber.bind(this);
        this.RefreshFiltersAndSortOrder = this.RefreshFiltersAndSortOrder.bind(this);
        this.child = React.createRef(); // remove in the future
        //this.FetchOrders = this.FetchOrders.bind(this);
        // clear notifications for this page

        this.state = {
            invoices: [],
            tradingPartnerFilter: [],
            isLoading: false,
            isLoadingPage: true,
            sortBy: SortByDefault,
            pageNumber: 1,
            pageSize: 20
        };
    };

    componentDidMount() {
        GetInvoiceFilterList()
            .then(responsedata => this.setState({ tradingPartnerFilter: responsedata }));
    };

    UpdatePageNumber(filters, pageNumber, pageSize) {
        this.setState(() => ({ pageNumber: pageNumber }));
        this.UpdateTableData(filters, this.state.sortBy, pageNumber, pageSize);
    };

    UpdateTableData(filters, sortby, pageNumber, pageSize) {
        this.setState({isLoading: true});

        const activeFilters = CreateInvoiceSqlParams(filters,
            pageNumber ? pageNumber : this.state.pageNumber,
            pageSize ? pageSize : this.state.pageSize);

        if (sortby) {
            activeFilters[sortby.name] = sortby.value;
        };
        this.FetchOrders(activeFilters);
    };

    FetchOrders(params) {
        GetInvoiceList(params).then((result) => this.setState({ invoices: result, isLoadingPage: false, isLoading: false }));
    };

    OnClickSortOrder(sortType) {
        const newSortBy = SortByLogic(sortType, this.state.sortBy);
        this.setState({ sortBy: newSortBy });
        this.child.current.SortBy(newSortBy);
    };

    RefreshFiltersAndSortOrder() {
        this.child.current.SortBy(this.state.sortBy);
    }

    render() {
        return (
            <Container fluid>
            {
                this.state.isLoading &&
                (<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>)
            }
                <Row>
                    <Col lg={2} >
                    <QuickSearch
                    isLoading={this.state.isLoadingPage}
                    updateFunction={this.UpdateTableData}
                    refreshFiltersAndSortOrder={this.RefreshFiltersAndSortOrder}
                    label='Invoice Number Search'
                    placeholder='Invoice Number'
                    searchValues={[{
                        id: -1,
                        name: '',
                        type: "invoiceNumber",
                        typeName: "Invoice Number",
                        value: ''

                    }]} />
                        <FilterBar
                            isLoading={this.props.isLoadingPage}
                            localStorageReference={this.props.localStorageReference}
                            UpdateTableData={this.UpdateTableData}
                            isLoaded={!this.state.isLoadingPage}
                            sortBy={this.state.sortBy}
                            ref={this.child}>

                            <FilterById
                                Title="Trading Partner"
                                Icon="circle-arrow-right"
                                filterByIds={this.state.tradingPartnerFilter}
                                Type="tradingPartners"
                                TypeName="Trading Partners"
                                iconType={faHandshake} />

                            <FilterByDateRange
                                Title="Invoice Date"
                                Type="invoiceDate"
                                TypeName="Invoice Date"
                                iconType={faCalendarAlt} />

                            <FilterByTextInput
                                Title="P.O. Number"
                                Icon="list-alt"
                                Type="poNumber"
                                TypeName="poNumber"
                                iconType={faFile} />

                            <FilterById
                                Title="Invoice Type"
                                filterByIds={InvoiceType()}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="invoiceTypeCode"
                                TypeName="Invoice Type"
                                iconType={faFileInvoiceDollar}
                                selectOne={true} />

                            <FilterByIdWithSubIds
                                Title="Location"
                                Icon="circle-arrow-right"
                                filterByIds={this.state.tradingPartnerFilter}
                                AddToFilter={this.AddToFilter}
                                Type="country"
                                SubType="state"
                                TypeName="Country"
                                SubTypeName="State"
                                iconType={faGlobe} />


                            <FilterById
                                Title="Account Number"
                                filterByIds={this.state.tradingPartnerFilter}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="accountNumbers"
                                TypeName="Account Numbers"
                                iconType={faUser}
                                isLastBlock={true} />

                        </FilterBar>
                        
                    </Col>
                    <Col lg={10}>
                        <PurchasesTable
                            data={UnprocessedInvoiceData(this.state.invoices)}
                            headers={unprocessedOrderHeaderList}
                            sortByFunction={this.OnClickSortOrder}
                            activeSortBy={this.state.sortBy}
                            emptyDataMessage={this.props.emptyDataMessage}
                            isLoading={this.state.isLoadingPage}
                            stickyHeading={true}
                        />
                        {

                            this.state.invoices && this.state.invoices.length > 0 &&
                            renderPagination(this.state.invoices[0].totalCount,
                                this.state.pageSize,
                                this.state.pageNumber,
                                this.props.localStorageReference,
                                this.UpdatePageNumber)

                        }
                    </Col>
                </Row>
            </Container>
        )
    };
};

const UnprocessedInvoiceData = (invoices = []) => {
    if (invoices.length === 0) return [];

    let newScheme = [];
    invoices.forEach(inv => {
        const orderScheme = {
            invoiceNumber: inv.invoiceNumber,
            invoiceDate: DisplayUserDateTime(inv.invoiceDate),
            orderNumber: inv.orderId ? (<a href={OrderPath(inv.orderId)}>{inv.purchaseOrderNumber}</a>) : inv.purchaseOrderNumber,
            tradingPartnerName: inv.tradingPartnerName,
            country: inv.country,
            state: inv.state,
            accountNumber: inv.accountNumber,
            companyName: inv.companyName,
            invoiceTypeCode: inv.invoiceTypeCode,
            totalAmount: inv.totalAmount ? ReturnDollarValueWithCommon(inv.totalAmount.toFixed(2)) : inv.totalAmount,
            action: <LinkToButton contents='View' linkTo={InvoicePath(inv.id)} />

        };
        newScheme.push(orderScheme);
    });
    return newScheme;
};

const unprocessedOrderHeaderList = [

    {
        headerName: 'Invoice Number',
        sortby: 'invoiceNumberSortDirection',
        style: { width: '8%' }
    },
    {
        headerName: 'Invoice Date',
        sortby: 'invoiceDateSortDirection',
        style: { width: '9%' }
    },
    {
        headerName: 'P.O Number',
        sortby: 'poNumberSortDirection',
        style: { width: '8%' }
    },
    {
        headerName: 'Trading Partner',
        style: { width: '17%' }
    },
    {
        headerName: 'Country',
        sortby: 'countrySortDirection',
        style: { width: '6%' }
    },
    {
        headerName: 'State',
        sortby: 'stateSortDirection',
        style: { width: '6%' }
    },
    {
        headerName: 'Account Number',
        sortby: 'accountNumberSortDirection',
        style: { width: '6%' }
    },
    {
        headerName: 'Company Name',
        sortby: 'companyNameSortDirection',
    },
    {
        headerName: 'Invoice Type',
        sortby: 'invoiceTypeSortDirection',
        style: { width: '12%' }
    },
    {
        headerName: 'Total($)',
        sortby: undefined
    },
    {
        headerName: 'Action',
        sortby: undefined,
        style: { width: '6%' }
    }
];



const InvoiceType = () => {
    return [
        {
            type: 'invoiceTypeCode',
            typeName: 'Invoice Type',
            id: -1,
            name: 'Invoice',
            value: 'PR'
        }, {
            type: 'invoiceTypeCode',
            typeName: 'Invoice Type',
            id: -1,
            name: 'Credit Note',
            value: 'CR'
        }
    ];

};
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSortUp, faSortDown, faChartArea, faFolderPlus } from
    '@fortawesome/free-solid-svg-icons';
import ReportGridOptionsList from './GridOptions/ReportGridOptionsList';
import { ToggleSwitch } from './../../../components/UserSettings/index'

const AddGrindItem = (props) => {
    const [isOpen, SetisOpen] = useState(false);

    const OpenAddGrindItem = () => {
        SetisOpen(!isOpen);
    };

    // no more then 6 can be added
    // if it is added it must have a remove button
    // options depend on the report

    // FetchAPI get current settings on open
    let reportsList = undefined;

    if (isOpen) {
        reportsList = ReportGridOptionsList(props.grid);
    }

    return (
        <Container >
            <Row style={{ marginTop: 3, borderBottom: 'solid 1px rgba(255,255,255,0.3)' }}>
                <div onClick={OpenAddGrindItem} style={{ margin: 5, padding: 4, textAlign: 'center' }
} className="OptionItem">
                    <FontAwesomeIcon icon={faFolderPlus} size="lg" style={{ marginRight: 3 }}/>Add Report
                </div>

            </Row>
            {isOpen &&
                (
                    <Row style={{ marginTop: 10, borderBottom: 'solid 1px rgba(255,255,255,0.3)' }}>
                        {reportsList.map(
                            (details, key) => <GrindOptionDetails key={key} {...details} updateGrid={props.updateGrid
                            } {...props.grid}/>)}
                    </Row>
                    //<GrindOptions activeReports={props.activeReports} updateGrind={props.updateGrind} /> reminder what is also required
                )
            }
        </Container>
    );
};

const NumberInput = (props) => {

    const minAmount = !isNaN(props.minAmount) ? props.minAmount : 1;
    const maxAmount = props.maxAmount ? props.maxAmount : 89;

    const decreaseNumber = () => {
        if (props.queryValue > minAmount) props.UpdateValueChange(props.queryValue - 1, props.queryName);
    };

    const increaseNumber = () => {
        if (props.queryValue <= maxAmount) props.UpdateValueChange(props.queryValue + 1, props.queryName);
    };

    const onInputChange = (e) => {
        e.preventDefault();

        if (e.target.validity.valid) {

            let inputValue = parseInt(e.target.value, 10);
            if (e.target.value === '') inputValue = minAmount;
            if (inputValue > maxAmount) inputValue = maxAmount;

            if (inputValue < minAmount) inputValue = minAmount;

            props.UpdateValueChange(inputValue, props.queryName);
        }
    };

    return (
        <Col sm={6}>
            <span style={{ display: 'inline-block' }}>
                {props.name}: <input onChange={onInputChange} style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    width: '15%'
                }} type="text" pattern="[0-9]*" value={props.queryValue}/>{props.minAmount === props.queryValue &&
                    <span>{props.minAmountComment}</span>}
                <div className="GridOptionNumberButton" onClick={increaseNumber}>
                    <FontAwesomeIcon icon={faSortUp}/>
                </div>
                <div className="GridOptionNumberButton" onClick={decreaseNumber}>
                    <FontAwesomeIcon icon={faSortDown}/>
                </div>
            </span>
        </Col>
    );
};

const TextInput = (props) => {

    const textChange = (onChangeValue) => {

        const newTextValue = onChangeValue.currentTarget.value;
        props.UpdateValueChange(newTextValue, props.queryName);
    };

    return (
        <div>
            {props.name}:
            <input type="text" onChange={textChange} name={props.queryName} value={props.queryValue}/>
        </div>
    );
};

const BooleanInput = (props) => {

    const boolChange = () => {

        const newTextValue = !props.queryValue;
        props.UpdateValueChange(newTextValue, props.queryName);

    };

    return (
        <Col sm={6} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ToggleSwitch defaultChecked={props.queryValue} inLineLabel label={props.name} value={props.queryValue
            } isSmall onChange={boolChange}/>
        </Col>
    );
};

const CheckOptionValueType = (props, key, UpdateValueChange) => {
    const type = typeof (props.queryValue);

    switch (type) {
    case 'string':
        return <TextInput key={key} {...props} UpdateValueChange={(value, queryName) => UpdateValueChange(value,
            queryName)}/>;
    case 'number':
        return <NumberInput key={key} {...props} UpdateValueChange={(value, queryName) => UpdateValueChange(value,
            queryName)}/>;
    case 'boolean':
        return <BooleanInput key={key} {...props} UpdateValueChange={(value, queryName) => UpdateValueChange(value,
            queryName)}/>;

    default:
        break;
    };
};

const GrindOptionDetails = props => {

    const [isOpen, SetisOpen] = useState(false);
    const [queryOptions, SetQueryOptions] = useState(props.queryOptions);

    const ChangeisOpen = () => {
        SetisOpen(!isOpen);
    };

    const UpdateValueChange = (queryValue, queryName) => {
        SetQueryOptions(queryOptions.filter((option) => {
            if (option.queryName === queryName) {
                option.queryValue = queryValue;
            }
            return option;
        }));
    };

    const AddAction = () => {

        //send to grid
        const reportDetails = {
            reportName: props.reportName,
            queryOptions: queryOptions,
            fetchAPI: props.fetchAPI
        };

        props.updateGrid('add', reportDetails);
    };

    // const DeleteAction = () => {
    //     props.updateGrid('delete', props.reportName);
    // };

    // const UpdateAction = () => {

    //     //send to grid
    //     const reportDetails = {
    //         reportName: props.reportName,
    //         queryOptions: queryOptions,
    //         fetchAPI: props.fetchAPI
    //     };

    //     props.updateGrid('edit', reportDetails);

    // };

    return (
        <Col xs={4}>
            <Row>
                <Col xs={2}>
                    <FontAwesomeIcon size="4x" style={props.iconColor ? { color: props.iconColor } : {}} icon={
                        props.icon ? props.icon : faChartArea}/>
                </Col>
                <Col xs={10}>
                    <h3>{props.title} {props.queryOptions &&
                        props.queryOptions.length !== 0 &&
                        <span onClick={ChangeisOpen} className="addReportOptionButton">
                            <FontAwesomeIcon icon={
                            faEllipsisV}/>
                        </span>}</h3>
                    <p>{props.summary}{!isOpen &&
                        <button onClick={AddAction} style={{ width: '100%', marginBottom: 5 }}>Add</button>}</p>
                </Col>

            </Row>
            {isOpen &&
                <Row>
                    <Col>
                        <div style={{ marginTop: 10 }}>
                            <Row>
                                <Col style={{ textAlign: 'center' }} sm={12}>
                                    <h5>Options</h5>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    queryOptions.map((item, key) => (
                                        CheckOptionValueType(item, key, UpdateValueChange)
                                    ))
                                }
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'center' }} sm={12}>
                                    <button onClick={AddAction} style={{ width: '100%', marginBottom: 5 }}>Add</button>
                                </Col>
                            </Row>
                        </div>

                    </Col>
                </Row>
            }
        </Col>
    );
};

export default AddGrindItem;
import React, { Component, Fragment } from 'react';
import { Col, Table, Row } from 'react-bootstrap';
import { GetAuditsList } from './../FetchApi/Administration';
import { GetAudits } from './../FetchApi/Administration';
import { CreateAuditSqlParms } from './../components/FilterBar/FilterBarMapping';
import { faCalendarAlt, faClipboardCheck, faClipboard, faUser, faFileAlt, faFileInvoiceDollar } from
    '@fortawesome/free-solid-svg-icons';
import { FilterByDateRange, FilterByTextInput, FilterById, FilterBar } from './../components/FilterBar/index';
import { DisplayUserDateTime } from './../Globals/UserDateTime';
import { CreatePagination } from './../components/Table/Pagination';
import { EventSource, EventType, SystemEvent } from './../components/FilterBar/ManualFilterIdOptions';
import { Link } from 'react-router-dom';

export default class Audit extends Component {
    constructor(props) {
        super(props);
        this.UpdateTableData = this.UpdateTableData.bind(this);
        this.UpdatePageNumber = this.UpdatePageNumber.bind(this);
        this.GetEventType = this.GetEventType.bind(this);
        this.GetEventSource = this.GetEventSource.bind(this);
        this.CreateLink = this.CreateLink.bind(this);

        this.state = {
            audits: [],
            auditfilter: [],
            isLoaded: false,
            pageNumber: 1,
            pageSize: 20
        };
    }

    componentDidMount() {
        GetAuditsList().then(responsedata => {
            this.setState(() => ({ auditfilter: responsedata }));
        });
    }

    UpdateTableData(filters, sortby, pageNumber, pageSize) {

        const activeFilters = CreateAuditSqlParms(filters,
            pageNumber ? pageNumber : this.state.pageNumber,
            pageSize ? pageSize : this.state.pageSize);

        GetAudits(activeFilters).then((result) => this.setState(() => ({ audits: result, isLoaded: true })));
    }

    UpdatePageNumber(filters, pageNumber, pageSize) {
        this.setState(() => ({ pageNumber: pageNumber }));
        this.UpdateTableData(filters, null, pageNumber, pageSize);
    }

    // eslint-disable-next-line class-methods-use-this
    GetEventSource(number) {
        switch (number) {
            case 1:
                return 'Function App';
            case 2:
                return 'Website';
            default:
                return 'None';
        }
    }

    // eslint-disable-next-line class-methods-use-this
    GetEventType(number) {
        switch (number) {
            case 1:
                return 'Create';
            case 2:
                return 'Read/View';
            case 3:
                return 'Update';
            case 4:
                return 'Delete';
            default:
                return 'None';
        }
    }

    // eslint-disable-next-line class-methods-use-this
    CheckIfHasNumber(type, id, number) {
        const url = id ? `${type}/${id}` : null;

        if (url) {
            return number ? <Link to={url}>{number}</Link> : <Link to={url}>{id}</Link>;
        }

        return null;
    }

    CreateLink(type, audit) {

        switch (type) {
            case 'Order': return this.CheckIfHasNumber('Order', audit.orderId, audit.purchaseOrderNumber);
            case 'Invoice': return this.CheckIfHasNumber('Invoice', audit.invoiceId, audit.invoiceNumber);
            case 'Order Ack': return audit.acknowledgmentNumber ? audit.acknowledgmentNumber : audit.orderAckId;
            default:  return null;

        }

    }

    // eslint-disable-next-line class-methods-use-this
    GetType(audit) {

        if (audit.orderId) return 'Order';
        if (audit.invoiceId) return 'Invoice';
        if (audit.orderAckId) return 'Order Ack';
        return null;
    }

    render() {
        const localStorageReference = 'audit';

        return (
            <Fragment>

                <Row>

                    <Col className="filterBarContainer" sm="2">

                        <FilterBar
                            isLoading={this.state.isLoading}
                            localStorageReference={localStorageReference}
                            UpdateTableData={this.UpdateTableData}
                            isLoaded={this.state.isLoaded}
                            ref={this.child}>

                            <FilterByDateRange
                                Title="Event Date"
                                AddToFilter={this.AddToFilter}
                                Type="Event"
                                TypeName="Event Date"
                                iconType={faCalendarAlt} />

                            <FilterById
                                Title="Event Source"
                                filterByIds={EventSource()}
                                AddToFilter={this.AddToFilter}
                                Type="eventSource"
                                TypeName="Event Source"
                                iconType={faClipboard}
                                selectOne={true} />

                            <FilterById
                                Title="Event Type"
                                filterByIds={EventType()}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="eventType"
                                TypeName="Event Type"
                                iconType={faClipboardCheck}
                                selectOne={true} />

                            <FilterById
                                Title="System Event"
                                filterByIds={SystemEvent()}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="includeSystemUserEvents"
                                TypeName="System Event"
                                iconType={faClipboardCheck}
                                selectOne={true} />

                            <FilterById
                                Title="Users"
                                filterByIds={this.state.auditfilter}
                                SelectedFilters={this.state.selectedFilters}
                                AddToFilter={this.AddToFilter}
                                Type="username"
                                TypeName="Users"
                                iconType={faUser} />

                            <FilterByTextInput
                                Title="Purchase Order Numbers"
                                AddToFilter={this.AddToFilter}
                                Type="purchaseOrderNumbers"
                                TypeName="Purchase Order Numbers"
                                iconType={faFileAlt} />

                            {
                                // <FilterByTextInput
                                //     Title='Order Acknowledgment Numbers'
                                //     AddToFilter={this.AddToFilter}
                                //     Type='orderAcknowledgmentNumbers'
                                //     TypeName='Order Acknowledgment Numbers'
                                //     iconType={faClipboardList}
                                // />
                            }

                            <FilterByTextInput
                                Title="Invoice Numbers"
                                AddToFilter={this.AddToFilter}
                                Type="invoicenumber"
                                TypeName="Invoice Numbers"
                                iconType={faFileInvoiceDollar} />

                        </FilterBar>

                    </Col>

                    <Col sm="10" style={{ padding: '10px 10px 10px 0px' }}>
                        {
                            this.state.isLoaded === true &&
                            this.state.audits &&
                            this.state.audits.length === 0 &&
                            <div>There are no audits to display</div>
                        }
                        {
                            this.state.audits &&
                            this.state.audits.length > 0 &&
                            <div className="table-divborder">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>Event Date (UTC)</th>
                                            <th>Event Source</th>
                                            <th>Event Type</th>
                                            <th>Message</th>
                                            <th>User</th>
                                            <th>Input Type</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.audits.map((audit, i) => {
                                            const referenceType = this.GetType(audit);
                                            return (
                                                [
                                                    <tr key={audit.id}>
                                                        <td>{DisplayUserDateTime(audit.eventDateTime, true)}</td>
                                                        <td>{this.GetEventSource(audit.eventSource)}</td>
                                                        <td>{this.GetEventType(audit.eventType)}</td>
                                                        <td>{audit.message}</td>
                                                        <td>{audit.userName}</td>
                                                        <td>
                                                            {
                                                                referenceType
                                                            }
                                                        </td>
                                                        <td>{this.CreateLink(referenceType, audit)}</td>
                                                    </tr>
                                                ]
                                            );
                                        }
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            this.state.audits &&
                            this.state.audits.length > 0 &&
                            CreatePagination(this.state.audits[0].totalCount,
                                this.state.pageSize,
                                this.state.pageNumber,
                                localStorageReference,
                                this.UpdatePageNumber)
                        }
                    </Col>
                </Row>

            </Fragment>
        );
    }
}
/* global localStorage */

import React, { Fragment } from 'react';
import { Pagination } from 'react-bootstrap';

const getFilters = (localStorageReference) => {
    if (!localStorageReference) return null;
    const json = localStorage.getItem(localStorageReference + '-selectedFilters');
    if (json === '' || json === undefined) return [];

    const loadFilters = JSON.parse(json);
    const selectedFilters = loadFilters ? loadFilters : [];

    return selectedFilters;
};

export const CreatePagination = (totalCount, pageSize, pageNumber, localStorageReference, updatePageNumber) => {
    const TotalAmountOfPages = Math.ceil(totalCount / pageSize);

    pageNumber = pageNumber ? pageNumber : 1;
    const PagPrev = pageNumber > 1;
    const PagNext = pageNumber === TotalAmountOfPages;

    const PagNumbers = [];

    const AddPagNumbers = (startNumber, EndNumber) => {
        EndNumber = EndNumber + 1;
        for (let i = startNumber; i < EndNumber; i++) {
            PagNumbers.push(i)
        }
    };

    if (TotalAmountOfPages <= 5) {

        AddPagNumbers(1, TotalAmountOfPages);
    } else {

        switch (pageNumber) {
            case 1: AddPagNumbers(1, 5); break;
            case 2: AddPagNumbers(1, 5); break;
            case 3: AddPagNumbers(1, 5); break;
            case TotalAmountOfPages: AddPagNumbers(TotalAmountOfPages - 4, TotalAmountOfPages); break;
            case TotalAmountOfPages - 1: AddPagNumbers(TotalAmountOfPages - 4, TotalAmountOfPages); break;
            case TotalAmountOfPages - 2: AddPagNumbers(TotalAmountOfPages - 4, TotalAmountOfPages); break;
            default: AddPagNumbers(pageNumber - 2, pageNumber + 2); break;

        };
    };

    return (
        <Fragment>
            <Pagination size="lg" style={{ marginTop: 10, float: 'left' }}>
                <Pagination.First disabled={!PagPrev} onClick={() => updatePageNumber(getFilters(localStorageReference), 1)} />
                <Pagination.Prev disabled={!PagPrev} onClick={() => updatePageNumber(getFilters(localStorageReference), pageNumber - 1)} />
                {PagNumbers.map((number) => <Pagination.Item onClick={() => updatePageNumber(getFilters(localStorageReference), number)} key={number} active={number === pageNumber}>{number}</Pagination.Item>)}
                <Pagination.Next disabled={PagNext} onClick={() => updatePageNumber(getFilters(localStorageReference), pageNumber + 1)} />
                <Pagination.Last disabled={PagNext} onClick={() => updatePageNumber(getFilters(localStorageReference), TotalAmountOfPages)} />
            </Pagination>
            <div>
                <div style={{ float: 'left', margin: 20}}>
                    <h4>Page: {pageNumber} / {TotalAmountOfPages}</h4>
                </div>

                <div style={{ float: 'right',  margin: 20 }}>
                    <h4>Total: {totalCount}</h4>
                </div>
            </div>
        </Fragment>
    );
};

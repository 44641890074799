/* global localStorage, document, window */
import React, { Fragment } from 'react';

const Theme = (localStorageValue, toggledChange) => {

    const trans = () => {
        document.documentElement.classList.add('transition');
        window.setTimeout(() => {
                document.documentElement.classList.remove('transition');
            },
            1000);
    };

    if (localStorageValue) {
        if (toggledChange) trans();

        document.documentElement.setAttribute('data-theme', 'dark');
    } else {
        if (toggledChange) trans();
        document.documentElement.setAttribute('data-theme', 'light');
    }
};

export const SetTheme = (props) => {

    let localStorageValue = localStorage.getItem('isDark');

    if (localStorageValue) {
        localStorageValue = (localStorageValue === 'true');
    } else {
        localStorageValue = false;
    }

    if (props === true) {
        localStorageValue = !localStorageValue;
    }

    const toggledChange = props === true;
    Theme(localStorageValue, toggledChange);

    localStorage.setItem('isDark', localStorageValue);

    return (<Fragment/>);
};

export const SetLogginTheme = (props) => {

    localStorage.setItem('isDark', props);
    Theme(props, true);
};
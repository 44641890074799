import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DashboardPath } from '../../Globals/RoutePaths';
import { Loading } from '../../components/Loading';
//import { IsAuthCalledBack } from './../../UserAuthentication/AdalAuthentication';

const AuthenticationValidationRoute = ({ component: Component, isAuthorised, isLoading,  ...rest }) => {

    if(isLoading) return <Route {...rest} render={props => (<Loading {...props}/> )} />;
    if(isAuthorised) return <Route {...rest} render={props => (<Redirect to={DashboardPath} /> )} />;

    return  <Route {...rest} render={props => (<Component {...props} /> )} />;
};

export default AuthenticationValidationRoute;
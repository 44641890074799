import { GetEDIUserLocal, DeleteEDIUserLocal } from '../Globals/LocalStorage';
import { GetAdalCachedUser } from './AdalAuthentication';
import { isNotEmpty } from './../Globals/FunctionsTools';
import { IsAuthCalledBack } from './../UserAuthentication/AdalAuthentication'

export const InitialUserState = {
    EDIProfile: {},
    ADALProfile: {},
    IsAuthenicated: false,
    IsLoading: false,
    IsCallback: false
}

export const GetInitialUserState = () => {
    const EDIUser = GetEDIUserLocal();
    let ADALUser = GetAdalCachedUser();
    ADALUser = ADALUser ? ADALUser.profile : ADALUser

    const ADALIsAuthenicated = isNotEmpty(ADALUser);
    const EDIIsAuthenicated = isNotEmpty(EDIUser);
    const IsCallback = IsAuthCalledBack();

    if(!ADALIsAuthenicated) DeleteEDIUserLocal();

    const initialUserState = {
        EDIProfile: EDIUser,
        ADALProfile: ADALUser,
        IsAuthenicated: EDIIsAuthenicated && ADALIsAuthenicated,
        IsLoading: ADALIsAuthenicated && !EDIIsAuthenicated,
        IsCallback: IsCallback
    }
    
    return initialUserState;
}
